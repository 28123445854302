import styled, {css} from 'styled-components'


export const InfoEditScreenContainer = styled.div`
    color: ${props => props.accentColor};
    margin: 0 auto;
    display: ${props => props.standard? "flex" : "block"};
    flex-direction: ${props => props.standard? "column" : "row"};
    align-items: ${props => props.standard? "column" : "row"};
    
    *{
        font-size: 0.8rem;
    }
    
`

export const InfoEditCard = styled.div`
    /* border: 2px solid ${props => props.accentColor? props.accentColor : "black"}; */
    border-radius: 10px;
    margin-bottom: ${props => props.standard? "1rem" : "0rem"};
`

export const ContactInfoTitle = styled.div`
    padding: 0.5rem;
    /* display: flex;
    justify-content: space-between; */
    /* border-bottom: 2px solid ${props => props.expanded? `2px solid ${props.accentColor}` : "none"}; */
    text-align: center;
    margin-bottom: 6vh;
    
`

export const PreviousTitle = styled.div`
        padding: 0.5rem 1rem;
        /* color: ${props => props.bottomColor};
        background: ${props => props.accentColor}; */\
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        /* text-align: ${props => props.standard? "left" : "center"}; */
        text-align: center;

`

export const CurrentInfoTitle = styled.div`
 
    /* display: flex;
    justify-content: space-between; */
    padding: 0.5rem 1rem;

    /* color: ${props => props.bottomColor};
    background: ${props => props.accentColor}; */
    text-align: center;
    i{
        color: ${props => props.bottomColor};
    }
`

export const InfoEditBody = styled.div`
    /* max-height: 20vh; */
    p{
        font-size: 0.9rem;
    }
    
    /* height:${props => props.expanded? "auto" : "0px"}; */
    overflow:${props => props.expanded? "visible" : "hidden"};
    transition: all 1s ease;
`

export const FormSection = styled.div`
    display : ${props => props.standard? "block" : "none"};
`

export const PreviousContact = styled.div`
    padding: 1rem;
`

export const PreviousContactRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid ${props => props.accentColor};
    margin-bottom: 1rem;
`


export const InfoEditCurrentContact = styled.div`
`

export const InfoChangeMesage = styled.p`
    margin-bottom: 0.5rem;
    text-align: left;
`

export const FormHolder = styled.div`

`


export const InfoEditButton = styled.button`
    border: 2px solid ${props => props.accentColor? props.accentColor : "black"};
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    display: block;
    background: none;
    color: ${props => props.accentColor};
    opacity: ${props => props.vis? "1" : "0"};
    &:disabled{
        color: ${props => props.bottomColor && props.bottomColor};
    }
    transition: all 0.25s ease;
`