import {ImageConfirm, CollapseUpload, ImagePair} from '../styles/confirmationStyles'
import {useState, useContext, useEffect} from 'react'
import { MenuContext } from '../utils/menuContext'
import { Link } from 'react-router-dom'
import ImgInput from './ImgInput'

const ImageConComponent = ({ sFormData_, sButtonImageLock_}) =>{
    const [imageCollapsed, setImageCollapsed] = useState(false)
    const {contact, setContact, previousPath, setPreviousPath} = useContext(MenuContext)
    const [endpoint, setEndPoint] = useState(null)
    
    useEffect(()=>{
       if(previousPath !== "") {
        setEndPoint(previousPath)
       }
    },[previousPath])


    return(
        <ImageConfirm>
            {
                previousPath === ""?

                <h2>Upload Gameboard</h2>

                :

                <h2>Your Current Gameboard</h2>
            }
                {
                    endpoint ?
                    <ImagePair>            
                        <img src={`https://competency-images.herokuapp.com/${endpoint}`} width="300" alt="client's uploaded gameboard"/>
                    
                        {/* <div>
                            <Link onClick={()=>{prev()}} to="/assess">
                                <i className="fa-solid fa-arrow-left"></i>
                                <span style={{marginLeft: "1rem", fontWeight:"400"}}>return to replace image</span>
                            </Link>
                        </div> */}
                        <ImgInput sFormData_={sFormData_} sButtonImageLock_={sButtonImageLock_}/>
                    </ImagePair>
                    :
                    <>
                        <p>no image found...</p>
                        <ImgInput sFormData_={sFormData_} sButtonImageLock_={sButtonImageLock_}/>
                    </>
                }
        </ImageConfirm>
    )
}

export default ImageConComponent