import styled, {css} from 'styled-components'

export const MoveContainer = styled.div`

    display: flex;
    visibility: ${props => props.vis? "visible" : "hidden"};
    opacity: ${props => props.vis? "1" : "0"};
    text-shadow: 0px 0px 1px black;
    flex-direction: ${props => props.order};
    justify-content: center;    
    align-items: ${props => props.ai? props.ai : "stretch"};
    margin-top: ${props => `${props.mt}vh`};
    
    transition: all ${props => `${props.aTime}s` } ease;
    
    h3{
        display: inline;
        margin-right: ${props => props.mr? `${props.mr}rem` : "initial"};
        color: ${props => props.titleColor};
        font-size: ${props => props.promptFs? `${props.promptFs}rem` : "intial"};
        font-weight: 400;
    }
    
    i{
        font-size: ${props => props.iFs? `${props.iFs}rem`: "1.5rem"};
    }
    
`