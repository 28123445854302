import {
    CompetencyContainer, 
    CardContainer, 
    GameChangerList, 
    CompetencyList,
    ScrollPrompt
} from "./competencyBoardStyles"
import {useState, useEffect, useContext} from 'react'
import {MenuContext} from '../../utils/menuContext'
import CardSet from "../CardSet/CardSet"
import {Link} from 'react-router-dom'

function CompetencyBoard({btn_active, setLCard, setACard, setLock}){
    // const [cardCheck, setCardCheck] = useState(false)
    const {tenArray, company, tallyUp, imageLock, setCardOptionsList} = useContext(MenuContext)


    return(
        <CompetencyContainer
            accentColor={company !== undefined && company.accentColor}
            titleColor={company !== undefined && company.titleColor}
        >
        
        
        <CardContainer >
           

                <GameChangerList>
                    <h3 >Game Changer Cards</h3>
                    {
                        tenArray.map(entry =>{
                            if(tenArray.indexOf(entry) < 2){
                                
                                return(
                                    <CardSet 
                                        key={tenArray.indexOf(entry)} 
                                        entry={entry}
                                        bg_Color={entry.learningPath} 
                                        skill={entry.skill} 
                                        devStage={entry.devStage} 
                                        val_={entry.value}
                                        ind_={tenArray.indexOf(entry)}
                                    />
                                    )
                                    }
                                })
                            }
                </GameChangerList>
                
                <CompetencyList>
                    <h3 >Competency Cards</h3>
                    {
                        tenArray.map(entry =>{
                            if(tenArray.indexOf(entry) > 1){
                                return(
                                    <CardSet 
                                        key={tenArray.indexOf(entry)} 
                                        entry={entry}
                                        bg_Color={entry.learningPath} 
                                        skill={entry.skill} 
                                        devStage={entry.devStage} 
                                        dev_={entry.value} 
                                        val_={entry.value}
                                        ind_={tenArray.indexOf(entry)}
                                    />
                                    )
                                }
                            })
                        }
                </CompetencyList>
                {/* <p style={{textAlign: "center", fontWeight: "400"}}>Click to generate your Learning Path!</p>
                {
         
            <>
                <CardSubmitButton className='button-class'
                    disabled={imageLock}
                    onClick={()=>{
                    tallyUp()
                    // setResultsActive(true)
                    setTimeout(()=>{
                        setLCard(true)
                    }, 250)
                    setTimeout(()=>{
                        setACard(true)
                    }, 3000)
                    setLock(false)
                    }}
                >
                    <h4>confirm</h4>
                </CardSubmitButton>
            </>
                
            } */}
            </CardContainer>

        </CompetencyContainer>
    )
}

export default CompetencyBoard