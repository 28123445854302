
import Move from '../Move/Move'
import Greeting from '../Greeting/Greeting'
import Instructions from '../Instructions/Instructions'
import Content from '../Content/Content'
import PopUp from '../PopUp/PopUp'
import { useContext } from 'react'
import { MenuContext } from '../../utils/menuContext'
import {FrameContainer, FrameNavigation, FrameBody} from './FrameStyles'
import Body from '../Body/Body'

//a styled div
//This is the component moves when the carousel advances.
//Must container a Greeting, Instructions, Content, Move components.
//The move is intended to be unique to each frame for specific advancing logic.
//Frames are the elements of the "frameElements" array from MenuContext.

function Frame({info, vis}){
    const {currentFrame} = useContext(MenuContext)
    let {frame, greeting, instructions, body, content, moveBack, moveForward, returningUser, popUp} = info

    const {bodyMt, bodyHt, navMt, navJc, padding, navigationOn} = frame 


    return(

        <FrameContainer vis={vis} padding={padding}>
            <Greeting utils={greeting}/>
            <FrameBody bodyHt={bodyHt} bodyMt={bodyMt}>
                <Instructions utils={instructions}/>
                    <Body utils={body}>
                        <Content utils={content}>
                            {content.children}
                        </Content>
                        {/* <PopUp utils={popUp}/> */}
                        <FrameNavigation navMt={navMt} navJc={navJc}>
                            <Move utils={moveBack}/>
                            <Move utils={moveForward}/>
                        </FrameNavigation>
                    </Body>
            </FrameBody>
            
        </FrameContainer>
    )
}

export default Frame