import { useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import {PopUpInner} from '../PopUp/popUpStyles'
import CardOptions from '../CardOptions/CardOptions'


const SkillModal = () =>{
    const {tenArray, skillPopUp, setSkillPopUp, currentPopUp} = useContext(MenuContext)
    return(
        <PopUpInner vis={skillPopUp}>
            <div style={{height: "10vh"}} onClick={()=>{setSkillPopUp(false)}}/>

                {
                        tenArray.map(entry =>{
                        
                                
                                if(tenArray.indexOf(entry) === currentPopUp){
                                    return(
                                        <CardOptions
                                            key={tenArray.indexOf(entry)} 
                                            val={entry.value}
                                            indx={tenArray.indexOf(entry)}
                                            vis={true}
                                        />
                                    )
                                }
                        
                        })
                }
            <div style={{height: "100%"}} onClick={()=>{setSkillPopUp(false)}}/>
        </PopUpInner>
    )
}

export default SkillModal