import {useState, useContext, useEffect} from 'react'
import { MenuContext } from '../utils/menuContext'
import { CardConcealableOption, CardDevSelect} from '../styles/assessStyles'
import {CardSkillSelect} from '../styles/cardPageStyles'


const CardOptions = ({indx, vis, val, side}) =>{
    const {cardList, setCardList, tenArray, setTenArray} = useContext(MenuContext)
    const [current, setCurrent] = useState("")
    const [development, setDevelopment] = useState("")


    useEffect(()=>{
        if(localStorage.getItem("list")){
            let savedCards = JSON.parse(localStorage.getItem("list")).cards
            setCardList(savedCards)
            savedCards.forEach(entry =>{
                if(tenArray[indx].skill === entry.skill){
                    setCurrent(entry.skill)
                }
            })

        }
    },[])
    

    const designateValue = (developmentPath) =>{
        let val
        switch(developmentPath){
            case 'developing' :
                val = 4
                break
            case 'intermediate' :
                val = 3
                break
            case 'advanced' :
                val = 2
                break
            case 'master':
                val = 1
        }

        return val
}

    const updateSelections = (target) =>{
        let modified =[]
        if(current!==""){
            const newList = cardList.map(obj =>{
                if(obj.skill === current){
                    return {...obj, selected: false}
                }
                return obj
            })
            
            modified = newList
            

        }

        cardList.forEach(obj =>{
            if(obj.skill === target){
                setCurrent(obj.skill)
                let placeholder = tenArray
                if(development !== ""){
                    placeholder.splice(indx, 1, {...obj, devStage: development, value: designateValue(development), selected: true})
                }
                else if(tenArray[indx].devStage !== ""){
                    placeholder.splice(indx, 1, {...obj, devStage: tenArray[indx].devStage , value: designateValue(tenArray[indx].devStage), selected: true})
                }
                else{
                    placeholder.splice(indx, 1, {...obj, selected: true})
                }
               
                setTenArray(placeholder)
                localStorage.setItem( "list", JSON.stringify({array: placeholder, cards: cardList}))
            }
        })

        if(current === ""){
            const newList = cardList.map(obj =>{
                if(obj.skill === target){
                    return {...obj, selected: true}
                }
                return obj
            })
            setCardList(newList)
            localStorage.setItem( "list", JSON.stringify({array: tenArray, cards: newList}))
        }

        else{
            const newList = modified.map(obj =>{
                if(obj.skill === target){
                    return {...obj, selected: true}
                }
                return obj
            })
            setCardList(newList)
            localStorage.setItem( "list", JSON.stringify({array: tenArray, cards: newList}))

        }
    }


    return(

            <CardSkillSelect vis={vis} val={val} side={side} onChange={(e)=>{
                 updateSelections(e.target.value)
                }}>
                {

                    <option hidden>Select a skill</option>

                }
                {

                    cardList && 

                    cardList.map(card =>
                        {       

                                return <CardConcealableOption key={card._id} vis={card.selected}>{card.skill}</CardConcealableOption>                     
                        }
                    )
                    
                }
            </CardSkillSelect>

    )
}

export default CardOptions