import { useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import { PopUpInner} from "../PopUp/popUpStyles"
// import WarningWindow from "./WarningWindow"
import { WarningContainer } from "./warningModalStyles"

const WarningModal = () =>{
    const {warningPopUp, setWarningPopUp, warning} = useContext(MenuContext)

    return(
          <PopUpInner vis={warningPopUp}>
                <WarningContainer>
                    <p>{warning}</p>
                </WarningContainer>
                <i className="fa-regular fa-circle-xmark"
                    onClick={()=>{setWarningPopUp(false)}}
                ></i>
            
        </PopUpInner>
    )
}

export default WarningModal