import { useContext } from 'react'
import {
    CardContainer,
    CardTop, 
    CardTopContent, 
    CardDivider, 
    CardBottom,
    LearningPathColor,
    ManualSelect,
    ManualOptionContainer
} from './graphicCardStyles'
import { MenuContext } from '../../utils/menuContext.js'




const GraphicCard = ({move, company}) =>{
    const {learningPath, setLearningPath, choiceSelection, clientLogo} = useContext(MenuContext)
    const colors = ["blue", "red", "green", "yellow", "orange"]


    return(
        <CardContainer move={move}>
            <CardTop
                companyCardColor={company !== undefined && company.companyCardColor}
                companyCardFontColor={company !== undefined && company.companyCardFontColor}
            >
                <CardTopContent
                    companyCardFontColor={company !== undefined && company.companyCardFontColor}
                    highlight={company !== undefined && company.highlight}
                >
                    {
                        choiceSelection !== "manual" ?
                        <img
                            src={clientLogo !== undefined ? clientLogo : ""} 
                            alt="the company logo on the top half of the card"
                        />
                        :
                        <ManualSelect onChange={(e)=>{
  
                            setLearningPath(e.target.value)}}>
                            <option hidden>choose path</option>
                            {
                                colors.map(entry =>{
                                    return(
                                        <option key={colors.indexOf(entry)} value={entry}>
                                            {entry} path
                                        </option>
                                    )
                                })
                            }
                        </ManualSelect>
                    }
                </CardTopContent>
            </CardTop>
            <CardDivider>

            </CardDivider>
            <CardBottom bg={learningPath !== undefined && learningPath}>
                    <LearningPathColor bg={learningPath !== undefined && learningPath}>
                        {
                            learningPath !== undefined && learningPath[0].toUpperCase() + learningPath.substring(1)
                        }

                    </LearningPathColor>
                    {
                        learningPath !== undefined &&
                        <p>Learning Path</p>
                    }
            </CardBottom>
            
        </CardContainer>
    )
}

export default GraphicCard