import { ClientLogoContainer } from "./clientLogoStyles"

const ClientLogo = ({vis, aTime, logo, logoHeight, logoWidth}) =>{
    return(
        <ClientLogoContainer 
            vis={vis} 
            logoHeight={logoHeight} 
            logoWidth={logoWidth} 
            aTime={aTime} 
            src={logo}
            alt="your company logo"
        />
    )
}

export default ClientLogo