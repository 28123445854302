import {useState, useEffect, useContext} from 'react'
import ImageMod from '../../components/ImageMod/ImageMod'
import { MenuContext } from '../../utils/menuContext'
import 
{ 
    AdminContainer, 
    InnerWindow,
    StatusDisplay,
    VerticalAligner,
    ScrollPrompt
} 
from './adminPageStyles'
import LogoUploader from './LogoUploader'
import PersonnelManager from '../../components/PersonnelModal/PersonnelManager'
import ReportManager from './ReportManager'
import PopUp from '../../components/PopUp/PopUp'
import CSVReader from '../../components/CSVReader/CSVReader'
import { 
    listCompanies,
    getAdmins,
    reviseAdmin,
    getCompletedStatus
} from '../../services/dataServices'
import { useNavigate } from 'react-router-dom'

const AdminPage = () =>{
    const {admin, setAdmin, adminList, setAdminList} = useContext(MenuContext)
    const [file, setFile] = useState(undefined)
    const [companyList, setCompanyList] =  useState([])
    const [dataForm, setDataForm] =  useState(undefined)
    const [currentCompany, setCurrentCompany] = useState(undefined)
    const [completeCount, setCompleteCount] = useState("")
    const [filtered, setFiltered] = useState(false)
    const redirect = useNavigate()

    useEffect(()=>{
       const generateToken = async()=>{
        if(!localStorage.getItem("token")){
            redirect("/login")
            return
        }

        else{
            const token = localStorage.getItem("token")
            await reviseAdmin(token)
            .then(async(res) => {
                const result = res.data
                setAdmin(res.data)
                await getCompletedStatus(result.company_id)
                .then(res => {
                    setCompleteCount(res.data.quantity)
                })
                 .catch(err => console.log(`AdminPage line 50`, err))
                
            })
            .catch(err => alert("adminpage line 35: ",err))

            
        }
       }
       
       generateToken()
        
    },[])

      
    useEffect(()=>{
        const verification = async() =>{
            const token = localStorage.getItem("token")
            localStorage.removeItem("token")
            if(token){
                if(admin !== undefined){
                if(admin.level > 2){
                    listCompanies("*")
                    .then(res => {
                        setCompanyList(res.data)
                    })
                    .catch(err => console.log(err))
                    getAdmins("*")
                    .then(res => {
                        setAdminList(res.data)
                    })
                    .catch(err => console.log(err))
                    return
                }
                if(admin.level > 1){
                    listCompanies(admin.company_id)
                    .then(res => {
                        setCompanyList(res.data[0])
                    })
                    .catch(err => console.log(err))
                    getAdmins(admin.company_id)
                    .then(res => {
                        setAdminList(res.data)
                    })
                    .catch(err => console.log(err))
                    return
                }
                else{
                    listCompanies(admin.company_id)
                    .then(async(res) =>{
                        const placeholder = res.data[0]
                        setCurrentCompany(placeholder._id)
                        await listCompanies(placeholder._id)
                        .then(res => {
                            setCompanyList(res.data)
                        })
                        .catch(err => console.log(err))
                        })
                        .catch(err => console.log(err))
                    return
                }
            }
            
            }
        }

        verification()
        
    },[admin])

    const caseFormatterUpper = (str) => {

        let modified = str.charAt(0).toUpperCase() + str.slice(1);
        return modified
    
    }
    

    return(
        <AdminContainer>
            <VerticalAligner vis={admin !== ""}>
                <InnerWindow>
                    <ReportManager 
                        lvl={admin !== undefined && admin.level}
                        filtered = {filtered}
                        setFiltered = {setFiltered}
                    />
                    {/* {
                        adminList !== undefined && (
                            admin.level === 1 && 
                            <StatusDisplay>
                                <h3>Completed Asssessments</h3>
                                <p>{completeCount}</p>
                            </StatusDisplay>
                        )
                    } */}
                    
                        {
                            admin !== undefined &&

                            <LogoUploader
                                vis={admin.level > 2} 
                                companyList={companyList}
                                currentCompany={currentCompany}
                                setCurrentCompany={setCurrentCompany}
                                caseFormatterUpper={caseFormatterUpper}
                                dataForm={dataForm}
                                setDataForm={setDataForm}
                            />
                        }


                        {admin.level > 1 && <PersonnelManager lvl={admin.level}/>}
                        

                        <CSVReader />
        
                        {/* {
                        adminList !== undefined && (
                            admin.level === 2 && 
                            <StatusDisplay>
                                <h3>Completed Asssessments</h3>
                                <p>{completeCount}</p>
                            </StatusDisplay>
                        )
                    } */}
                </InnerWindow>
                
            </VerticalAligner>
            {/* <ScrollPrompt vis={filtered}>
                scroll up or down
            </ScrollPrompt> */}
            <PopUp/>
        </AdminContainer>
    )
}

export default AdminPage