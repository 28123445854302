import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GlobalStyle, ResultScreen} from './styles/bodyStyles'
import {MenuContext, MenuContextProvider} from './utils/menuContext'
import {useContext} from 'react'





function ModifiedGlobalStyle(){
  const {company} = useContext(MenuContext)

  useEffect(()=>{
    setTimeout(function () {
      let viewheight = window.visualViewport.height;
      let viewwidth = window.visualViewport.width;
      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
  }, 300);
  },[])

    return(
      <GlobalStyle
       bottomColor={company !== undefined && company.bottomColor} 
       topColor={company !== undefined && company.topColor} 
       titleColor={company !== undefined && company.titleColor}
       accentColor={company !== undefined && company.accentColor}
       auxColor={company !== undefined && company.auxColor}
       />
    )
}

ReactDOM.render(
  <React.StrictMode>
    <MenuContextProvider>
      <ModifiedGlobalStyle/>
        <App />
    </MenuContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
