import { useState, useContext } from "react"
import { EmployeeEntry, EmployeeList, HeaderEntry } from "./globalEmployeeListStyles"
import {MenuContext} from '../../utils/menuContext'



const GlobalEmployeeList = () =>{
    const {globalEmployeeList, setGlobalEmployeeList} = useContext(MenuContext)
    
    return(
        <EmployeeList>
            <HeaderEntry>
                <p>
                    Full Legal Name
                </p>
                <p style={{width:"250px"}}>
                    Primary Work Email
                </p>
                <p>
                    Dist Code
                </p>
                <p>
                    Business Name
                </p>
            </HeaderEntry>
            {
                globalEmployeeList !== undefined &&
                globalEmployeeList.map(entry =>{
                    return(
                        <EmployeeEntry key={entry._id}>
                            <p>
                                {entry.full_legal_name}
                            </p>
                            <p style={{width:"250px"}}>
                                {entry.primary_work_email}
                            </p>
                            <p>
                                {entry.dist_code}
                            </p>
                            <p>
                                {entry.business_name}
                            </p>
                        </EmployeeEntry>
                    )
                })
            }
        </EmployeeList>
    )
}

export default GlobalEmployeeList