import { useState, useContext, useEffect } from "react"
import { getDBContact } from "../services/dataServices"
import { MenuContext } from "../utils/menuContext"
import{removeImage ,imageUpload} from "../services/dataServices"

function ImgInput({sFormData_, sButtonImageLock_, eraser}){
    const {contact, setContact, tenArray, previousPath, setPreviousPath, imageLock, setImageLock} = useContext(MenuContext)
    const [formData, setFormData] = useState("")
    const [comparison, setComparison] =useState("")
    const [cmp, setCmp] =useState("")

    console.log("previous path: "+previousPath)
    console.log("comparison: "+comparison)

    useEffect(()=>{
        if(previousPath !== ""){
            localStorage.setItem("contact", JSON.stringify({...contact, assessmentImage: previousPath}))
            setImageLock(false)
        }
       
    },[previousPath])

    // useEffect(()=>{
    //     if(comparison !== ""){
    //         if(previousPath === comparison){
    //             localStorage.setItem("contact", JSON.stringify({...contact, assessmentImage: comparison}))
    //         }
    //     }

    //     return null
    // },[previousPath])

    const erasePhoto = () =>{
        if(previousPath !== ""){
            removeImage({previous: previousPath})
        }
   }

    return(
        
        <input type="file" onChange ={async(e) => {
    
            if(e.target.files[0] !== undefined){
                setImageLock(true)
                
                if(e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpeg"){
                    alert('only .jpg .png files accepted')
                    return
                }
                if(e.target.files[0].size >= 16777216){
                    alert("uploads must be 16mb or less")
                    return
                }
                // if(typeof eraser === "function"){
                //     eraser()
                // }
                setContact({
                    ...contact, 
                    cardSet: tenArray.map(entry =>{
                        if(tenArray.indexOf(entry) < 2){
                            return {...entry, gameChanger: true}
                        }
                        else return entry
                    })
                })
                const placeholder = new FormData()
                const fContact = {...contact, 
                    cardSet: JSON.stringify(tenArray.map(entry =>{
                        if(tenArray.indexOf(entry) < 2){
                            return {...entry, gameChanger: true}
                        }
                        else return entry
                    }))
                }
                
    
                for(let i in fContact){
    
                    placeholder.append(i, fContact[i])
                }
    
               
                placeholder.append("assessmentImage", e.target.files[0])
       
                
                setFormData(placeholder)
                await imageUpload(placeholder)
                .then(res => {
                    erasePhoto()
                    setPreviousPath(res.data.assessmentImage)
                    setImageLock(false)
                })
                .catch(err => alert(err))
                               
            }
            
        }}/>
          
       
    )
}

export default ImgInput