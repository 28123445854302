import {useState, useContext} from 'react'
import { MenuContext } from '../utils/menuContext'
import { ConfirmationForm, ConcealableOption, DevSelect, SkillSelect} from '../styles/assessStyles'


const CardOption = ({indx}) =>{
    const {cardList, setCardList, tenArray, setTenArray} = useContext(MenuContext)
    const [current, setCurrent] = useState("")
    const [development, setDevelopment] = useState("")
    

    const designateValue = (developmentPath) =>{
                let val
                switch(developmentPath){
                    case 'developing' :
                        val = 4
                        break
                    case 'intermediate' :
                        val = 3
                        break
                    case 'advanced' :
                        val = 2
                        break
                    case 'master':
                        val = 1
                }

                return val
    }

    const updateSelections = (target) =>{
        let modified =[]
        if(current!==""){
            const newList = cardList.map(obj =>{
                if(obj.skill === current){
                    return {...obj, selected: false}
                }
                return obj
            })
            
            modified = newList
            

        }

        cardList.forEach(obj =>{
            if(obj.skill === target){
                setCurrent(obj.skill)
                let placeholder = tenArray
                if(development !== ""){
                    placeholder.splice(indx, 1, {...obj, devStage: development, value: designateValue(development), selected: true})
                }
                else if(tenArray[indx].devStage !== ""){
                    placeholder.splice(indx, 1, {...obj, devStage: tenArray[indx].devStage , value: designateValue(tenArray[indx].devStage), selected: true})
                }
                else{
                    placeholder.splice(indx, 1, {...obj, selected: true})
                }
               
                setTenArray(placeholder)
            }
        })

        if(current === ""){
            const newList = cardList.map(obj =>{
                if(obj.skill === target){
                    return {...obj, selected: true}
                }
                return obj
            })
            setCardList(newList)
        }

        else{
            const newList = modified.map(obj =>{
                if(obj.skill === target){
                    return {...obj, selected: true}
                }
                return obj
            })
            setCardList(newList)
        }
    }



    const updateDevStage = (selection) =>{
        const newArray = tenArray.map(obj =>{
            if(tenArray.indexOf(obj) === indx){
                
                setDevelopment(selection)
                return {...obj, devStage: selection, value: designateValue(selection)}
                
            }
            return obj
        })

        setTenArray(newArray)
    }


    return(
        <ConfirmationForm>
            <SkillSelect 
                onChange={(e)=>{
                 updateSelections(e.target.value)
                }}
            >
                {
                    cardList.filter(entry =>{
                        if(tenArray[indx].skill === entry.skill){
                            return entry
                        }
                    }).length> 0?

                    cardList.filter(entry =>{
                        if(tenArray[indx].skill === entry.skill){
                            return entry
                        }
                    }).map(entry =>{
                        return <option key={entry._id} hidden>{entry.skill}</option>
                    })
                    :
                    <option hidden>Select a skill</option>

                }
                {

                    cardList.map(card =>
                        {                            
                            return <ConcealableOption key={card._id} vis={card.selected}>{card.skill}</ConcealableOption>                                              
   
                        }
                    )
                    
                }
            </SkillSelect>
            
                
                <DevSelect init={current !== "" || tenArray[indx].devStage !== ""} onChange={(e)=>{
                    updateDevStage(e.target.value)
                 }}>
                   {
                    tenArray[indx].devStage !== ""?
                    <option hidden>{tenArray[indx].devStage}</option>
                    :
                    <option hidden>Development</option>
                   }
                   <option>developing</option>
                   <option>intermediate</option>
                   <option>advanced</option>
                   <option>master</option>
               </DevSelect>
            
        </ConfirmationForm>
    )
}

export default CardOption