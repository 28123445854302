import 
{ 
    UploadContainer,
    ListHolder,
    ListItem,
    Uploader
} 
from './adminPageStyles'
import {logoUpload} from '../../services/dataServices'


const LogoUploader = ({vis, companyList, currentCompany, setCurrentCompany, caseFormatterUpper, dataForm, setDataForm}) =>{

    
    return(
        <UploadContainer vis={vis}>
            <h2>Logo Upload</h2>
                {
                    companyList.length > 2 && 
                    <ListHolder>
                        <h2>Select a Company</h2>
                            {
                                companyList.length > 0 &&
                                companyList.map(entry =>{
                                    return(
                                        <ListItem key={entry._id} onClick={
                                            ()=>{
                                                if(entry !== currentCompany){
                                                    setCurrentCompany(entry)
                                                }
                                                else{
                                                    setCurrentCompany(undefined)
                                                }
                                            }
                                        }>
                                            <p>{caseFormatterUpper(entry.name)}</p>
                                            {
                                                entry === currentCompany &&
                                                <i className="fa-regular fa-circle-check"></i>
                                            }
                                        </ListItem>
                                    )
                                })
                            }
                    </ListHolder>
                }
                

                <Uploader>
                    
                    <input type="file" onChange={(e)=>{
                        const image = e.target.files[0]

                        const placeholder = new FormData()
                        placeholder.append("logo", e.target.files[0] )
                        placeholder.append("company_id" , currentCompany._id)
                        setDataForm(placeholder)
                    }}/>
                   {
                    (dataForm !== undefined && currentCompany !== undefined) &&
                    <button onClick={async()=>{
                        await logoUpload(dataForm)
                        .then(res => {
                            setDataForm(undefined)
                        })
                        .catch(err => alert(err))
                    }}>Upload</button>
                   }
                </Uploader>
        </UploadContainer>
    )
}

export default LogoUploader