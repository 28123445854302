import styled, {css} from 'styled-components'

export const CardArrayContainer = styled.div`
    position: relative;
    height: 35vh;
    margin-bottom: 2rem;

`

const shifting =(index) =>{
    
    switch(index){
        case 1 : return `-25vw`
        break
        case 2 : return `-75vw`
        break
        case 3 : return `-125vw`
        break
        case 4 : return `-175vw`
        break
        case 5 : return `-225vw`
        break
        case 6 : return `-275vw`
        break
        case 7 : return `-325vw`
        break
        case 8 : return `-375vw`
        break
        case 9 : return `-425vw`
        break
        default: return "25vw";
    }
}


export const CardDisplayer = styled.div`

    position: absolute;
    display: flex;
    left: ${props => shifting(props.isOnScreen)};
    z-index: 3;
    transition: all 0.5s ease;
`

