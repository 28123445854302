import { useState, useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import { PopUpInner} from '../PopUp/popUpStyles'
import { PersonnelContainer, SubmitAllButton } from "./personnelModalStyles"
import ModularRow from "./ModularRow"


const PersonnelModal = () =>{
    const {personnelPopUp, setPersonnelPopUp, addManagersList, setAddManagersList} = useContext(MenuContext)

    const submitMany = () =>{
        //some axios call to some endpoint

        setPersonnelPopUp(false)
        setAddManagersList([])
    }

    return(
          <PopUpInner vis={personnelPopUp}>
            <PersonnelContainer>
                {
                   addManagersList !== undefined &&
                   addManagersList.map(entry =>{
                        return <ModularRow key={addManagersList.indexOf(entry)} indx={addManagersList.indexOf(entry)}
                                    singleMarker={addManagersList !== undefined && addManagersList.length < 2}
                                />
                   })
                }
            </PersonnelContainer >
            <SubmitAllButton vis={addManagersList.length > 1} width={'150px'} 
                onClick={submitMany}
            >
                submit all
            </SubmitAllButton>
            <i style={{cursor: "pointer"}}className="fa-regular fa-circle-xmark"
                    onClick={()=>{setPersonnelPopUp(false)}}
                ></i>
            
        </PopUpInner>
    )
}

export default PersonnelModal