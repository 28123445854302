import styled from 'styled-components'
import { useState } from 'react'


const LoaderContainer = styled.div`
      display: flex;
      justify-content: center;
      margin-bottom: ${props => props.mb};
      margin-top: ${props => props.mt};
      
      i{
        color: ${props => props.accentColor? props.accentColor : "black"};
         font-size: ${props => props.iconSize};
         animation: spin 1s infinite linear; 
         @keyframes spin{
            0%{
                transform: rotateZ(0deg); 
            }
            100%{
                transform: rotateZ(359deg);
            }

        }

      }
`

const Loader = ({accentColor, iconSize, mb, mt}) =>{
    return(
        <LoaderContainer 
            accentColor={accentColor} 
            iconSize={iconSize} 
            mb={mb}
            mt={mt}
        >
        <i className="fa-solid fa-circle-notch"></i>
        </LoaderContainer>
    )
}

export default Loader