import {useContext} from 'react'
import {AboutContainer, InnerContainer, FormContainer, AboutFormField, PhoneNumberPair, PhoneNumberTickPair, PhoneNumberInputPair} from './aboutPageStyles'
import { MenuContext } from '../../utils/menuContext'

function AboutForm({vis, standard}){
    const {
        company,
        territories,
        employeeTerritory,
        setEmployeeTerritory,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        managerEmail,
        setManagerEmail,
        industryYears,
        setIndustryYears,
        contact,
        setContact,
        listPhone,
        setListPhone,
        phoneNumber,
        setPhoneNumber
    } = useContext(MenuContext)

    const yearsOfSales = ["0-2", "3-5", "6-10", "11-15", "16+"]

    const phoneFormatter = (val) =>{
        if(!val) return val
        const phoneNumber = val.replace(/[^\d]/g, "")
        const phoneNumberLength = phoneNumber.length
        if(phoneNumberLength <4) return phoneNumber
        if(phoneNumberLength < 7){
            return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`
        }
        return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(
            3,
            6
            )}-${phoneNumber.slice(6,10)}`
    }


    return(
        <AboutContainer>
            <InnerContainer vis={vis} standard={standard}>
            <h2>About You</h2>
            <p>Fill out all fields to create your competency cards profile</p>
           <FormContainer 
            >
           
                        <AboutFormField>
                            <input  type="text" value={firstName} onChange={e => setFirstName(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>first name</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="text"  value={lastName} onChange={e => setLastName(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>last name</p>
                        </AboutFormField>
                        <AboutFormField>
                            {standard && <input type="email"  value={email} onChange={e => setEmail(e.target.value)} 
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />}
                            <p>email</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="email"  value={managerEmail} onChange={e => setManagerEmail(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>manager's email</p>
                        </AboutFormField>
                        {/* <AboutFormField>
                            <input type="number"  value={industryYears} onChange={e => setIndustryYears(e.target.value)}/>
                            <p>years in industry</p>
                        </AboutFormField> */}


                        <select onChange={(e)=>{setIndustryYears(e.target.value)}}>
                            <option  hidden>years of sales</option>
                            
                            {
                                yearsOfSales.map(entry =>{
                                    if(entry === industryYears){
                                        return(
                                            <option key={entry} value={entry} selected>
                                            {entry}
                                            </option>
                                        )
                                    }
                                    else{
                                        return(
                                            <option key={entry} value={entry}>
                                            {entry}
                                         </option>
                                        )
                                    }
                                })
                            }
                        </select>
                        <select style={{marginBottom: "1rem"}} onChange={(e)=>{setEmployeeTerritory(e.target.value)}}>
                            <option  hidden>{territories !== undefined && territories.designation}</option>
                
                            {territories !== undefined && territories.sectors.map(entry =>{
                
                                    if(entry === employeeTerritory){
                                        return(
                                            <option key={entry} value={entry} selected>
                                            {entry}
                                            </option>
                                        )
                                    }
                                    else{
                                        return(
                                            <option key={entry} value={entry}>
                                            {entry}
                                         </option>
                                        )
                                    }
                                }

                            )}
                        </select>
                        <PhoneNumberPair>
                            <PhoneNumberTickPair>
                                <div>
                                    <input type="checkbox" value={listPhone} 
                                            checked={listPhone}
                                            onChange={(e)=>{
                                            setListPhone(prev => !prev)
                                            if(contact.phoneNumber !== undefined){
                                                let placeholder = contact
                                                delete placeholder.phoneNumber
                                                setContact(placeholder)
                                            }
                                            else{
                                                let placeholder = contact
                                                placeholder = {...placeholder, phoneNumber: e.target.value}
                                                setContact(placeholder)
                                            }
                                    }}/>
                                </div>
                                <p>Opt me in for text messages</p>
                            </PhoneNumberTickPair>
                            <PhoneNumberInputPair opc={listPhone}>
                                <input  
                                    value={phoneNumber} 
                                    onChange={(e)=>{
                                        let modifiedNumber = phoneFormatter(e.target.value)
                                        setPhoneNumber(modifiedNumber)
                                    }}
                                    onBlur={()=>{
                                        window.scrollTo(0,0);
                                    }}
                                    disabled={!listPhone}
                                />                 
                                <p>phone number</p>
                            </PhoneNumberInputPair>
                        </PhoneNumberPair>
                        {/*                         
                        <input type="text"  value={jobTitle} onChange={e => setJobTitle(e.target.value)}/>
                        <p style={{paddingLeft: "0.25rem", paddingTop: "0.25rem"}}>job title</p> */}
                
                        {/* } */}
                
           </FormContainer>
            </InnerContainer>
        </AboutContainer>
    )
}

export default AboutForm

