import styled, {css} from 'styled-components'

export const RetakeContainer = styled.div`
 
    padding: 2rem;
    h4,p{
        margin: 0;
        padding: 0;
    }
    ul{
        padding: 0;
    }
    li{
        margin-bottom: 1rem;
    }

    button{
        display: block;
        background: transparent;
        border: 1px solid black;
        border-radius: 3px;
        width: 75px;
        margin: 0 auto;
        padding: 0.5rem;
    }
`