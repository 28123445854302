import styled from 'styled-components'

export const WelcomeContainer = styled.div`
    display: none;
    position: relative;
    height: 100vh;

    // this is for the desktop disclaimer to be centered;
    @media (min-width: 1000px){
       display: flex;
       justify-content: center;
       align-items: center;
    }
   
`

export const InnerContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 40%;
    min-height: 45vh;
    padding: 1rem;
    *{
        text-align: center;

    }
    h2{
        font-size:  3rem;
        margin-bottom: 2rem;
    }
    p{
        margin-bottom: 1rem;
        font-size: 1.25rem;
        
    }

    input{
        font-size: 1.25rem;
        text-align: left;
        padding: 0.25rem 0.5rem;
    }


`

export const WelcomeButton = styled.button`
    display: ${props => props.vis? "initial" : "none"};
`
