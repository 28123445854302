import {useState, useContext} from 'react'
import { MenuContext } from '../../utils/menuContext'
import { ManagerRow } from './personnelModalStyles'
import { getAdminEmployees } from '../../services/dataServices'


const ManagerSearchList = ({value, list, preset}) =>{
    const {company, setSkillPopUp, 
        firstTransferManager,
        setFirstTransferManager,
        secondTransferManager,
        setSecondTransferManager,
        setFirstTransferList,
        setSecondTransferList
    } = useContext(MenuContext)
    

    if(value){
        const filteredList = list.filter(entry => entry.email?.toString().toLowerCase().startsWith(value.toLowerCase()))
    
        if(filteredList.length){
            if(preset === "initiator"){
                return(
  
                    (
                        <div
                        >
                            {
                                filteredList.map(entry =>{
                                    if(entry.email !== firstTransferManager && entry.email !== secondTransferManager){
                                        return <ManagerRow 
                                                    key={entry._id} 
                                                    onClick={async()=>{
                                                        setFirstTransferManager(entry.email)
                                                        await getAdminEmployees(entry.email)
                                                        .then(res => setFirstTransferList(res.data))
                                                        .catch(err => console.log('ManagerSearchList 35: ', err))
                                                    }}
                                                >
                                                    {entry.email}
                                            </ManagerRow>
                                    } 
                                })
                            }
                        </div>
                    )   
                )
            }
            if(preset === "target"){
                return(
  
                    (
                        <div
                        >
                            {
                                filteredList.map(entry =>{
                                    if(entry.email !== firstTransferManager && entry.email !== secondTransferManager){
                                        return <ManagerRow 
                                                    key={entry._id} 
                                                    onClick={async()=>{
                                                        setSecondTransferManager(entry.email)
                                                        await getAdminEmployees(entry.email)
                                                        .then(res => setSecondTransferList(res.data))
                                                        .catch(err => console.log('ManagerSearchList 62: ', err))
                                                    }}
                                                >
                                                    {entry.email}
                                            </ManagerRow>
                                    } 
                                })
                            }
                        </div>
                    )   
                )
            }
        }

        return(
            <div>
                <p>
                    Not found
                </p>
            </div>
        )
    }

    
    
    return null
    
}


export default ManagerSearchList