import {
    Card, 
    CardDesc,
    CardPair,
    SkillReselect
} from '../styles/cardPageStyles'
import { MenuContext } from '../utils/menuContext'
import { useContext, useState } from 'react'
import CardOptions from './CardOptions'

const CardComponent = ({bg_Color, val_, skill, devStage, indx_}) =>{
    const {cardList, } = useContext(MenuContext)
    const [skillOn, setSKillOn] = useState(false)

    return(
        <CardPair val={val_}>

            {/*This generic div's purpose is to pad the left side of the card, if removed
                The card slide will be misaligned*/}
            <div style={{width: "33%"}}/>


            <Card  bg_Color={bg_Color} >
                <h4 onClick={()=>setSKillOn(prev => !prev)}>{skill.length > 17? skill.slice(0, 17)+"...": skill}</h4>
                <CardDesc>{<p>Generic description text. Not final</p>}</CardDesc>
                <h4>{devStage}</h4>
            </Card>
            
            <SkillReselect vis={skillOn} >
                    {
                        cardList &&
                        <CardOptions
                            indx={indx_} 
                            vis={skillOn}
                            val={val_}
                            
                        />       
                      
                    }
            </SkillReselect>          
            
        </CardPair>
    )
}

export default CardComponent