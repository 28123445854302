import { useContext } from 'react'
import { MenuContext } from '../../utils/menuContext'
import {EditConfirmPageContainer} from './editConfirmPageStyles'

export default function EditConfirmPage(){
    const {contact, company} = useContext(MenuContext)
    return(
        <>
            <EditConfirmPageContainer 
                  titleColor= {company !== undefined && company.titleColor} 
                  accentColor={company !== undefined && company.accentColor} 
            >
                <h2>your contact information was updated sucessfully</h2>
                <h2>your new information is listed below</h2>
                
                <div>
                    <p>first name: {contact && contact.firstName}</p>
                    <p>last name: {contact && contact.lastName}</p>
                    <p>email: {contact && contact.email}</p>
                    <p>manager's email: {contact && contact.managerEmail}</p>
                    <p>territory code: {contact && contact.territoryCode}</p>
                    <p>years in industry: {contact && contact.industryYears}</p>
                    {/* <div style={{display: "flex", justifyContent: "space-between", marginTop: "0.5rem"}}>
                        <p>expand to view current session information</p>
                        <i className="fa-solid fa-angle-down" ></i>
                    </div> */}
                </div>

                <h3>you may close this window at any time</h3>
            </EditConfirmPageContainer>
        </>
    )
}