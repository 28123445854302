import styled, {css} from 'styled-components'


const windowStyles = css`
    border-radius: 10px;
    background: white;
    box-shadow: -5px 5px 5px #4f4f4f;
    margin-bottom: 2rem;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    padding: 15vh 0;
    height: 100vh;
    
`
export const InputPair = styled.div`
    margin: 2vh auto;
    
    p{
        margin-bottom: 0.5rem;
    }

    select{
        width: 50vw;
        font-size: 1rem;
    }

    @media (min-width: 750px){
        width: 65%;
        select{
            width: 35vw;
        }
    }
    
    @media (min-width: 1000px){
        height: initial;
        padding: 2rem;
        width: 30%;
    
       
    }

    @media (min-width: 1300px){
        height: initial;
        padding: 2rem;
        width: 40%;
        select{
            width: 40vw;
            fontSize: 1.2rem;
        }
    }
`

export const TopicOption = styled.option`
    font-size: 1rem;
`

