import styled from 'styled-components'

export const ForgotContainer = styled.div`
    height: 100vh;
  
    @media (min-width: 1000px){
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: ${props => props.vis? "flex" : "none"};
    margin-top: 12rem;
    padding: 1rem;

    @media (min-width: 1000px){
        margin-top: 0;
    }
`

export const NewInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top: 12rem;  
    text-align: center;
    width: 80%;

    input{
        margin-bottom: 1rem;
    }

    @media (min-width: 1000px){
        margin-top: 0;
    }
`


export const PageTitle = styled.h2`
    color: black;
    font-size: 1.5rem;
    margin-bottom: 2rem;
`

export const InstructionMessage = styled.p`
    display: ${props => props.vis? "block" : "none"};
`

export const SubmitButton = styled.button`
    margin-bottom: 1rem;
    display: ${props => props.vis? "inline-block" : "none"};
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 0.5px solid black;
    background-image: linear-gradient(white, lightgrey);
`

export const PagePrompt = styled.p`
    color: black;
    max-width: 80%;
    text-align: center;
`

export const StyledInput = styled.input`
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 0.5px solid black;

`

export const PasswordInput = styled.div`
    position: relative;
    i{
        position: absolute;
        right: 0;
        transform: translate(-5px, 5px);
        color: grey;
    }
 `  

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: ${props => props.vis? "initial" : "0px"};
    input{
        margin-bottom: 1rem;
    }
`

export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: ${props => props.vis? "flex" : "none"};
    input{
        margin-bottom: 1rem;
    }

`

export const CodeContainer = styled.div`
    display: ${props => props.vis? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`