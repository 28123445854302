import { useContext, useState } from 'react'
import {AssessmentBottom, AssessmentContainer, AssessmentTop, AssessmentList, ChevronContainer, EntryContainer, RadialButton, CardListItem} from './previousAssessmentModalStyles.js'
import { MenuContext } from '../../utils/menuContext.js'


const AssessmentEntry = ({date, index}) =>{
    const {pastAssessments, currentAssessment, setCurrentAssessment, setExpanded} = useContext(MenuContext)
    const [selected, setSelected]= useState(false)

    const clickEffect = () =>{
        if(currentAssessment === undefined){
            setCurrentAssessment(pastAssessments[index]); 
            // setSelected(true)
            setExpanded(true)
        }
        if(currentAssessment === pastAssessments[index]){
            setCurrentAssessment(undefined)
            setSelected(prev => !prev)
            setExpanded(false)
        } else{
            setCurrentAssessment(pastAssessments[index])
            setSelected(prev => !prev)
        }
    }
    return(
        <EntryContainer onClick={clickEffect}>
            <p>{date}</p>
            <RadialButton selected={selected}/>
        </EntryContainer>
    )
}


const ExpandingChevron = () =>{
    const {expanded, setExpanded} = useContext(MenuContext)
    return(
        <ChevronContainer>
           
           {
            expanded? <i className="fa-solid fa-chevron-up" onClick={()=>{setExpanded(!expanded)}}/>:
            <i className="fa-solid fa-chevron-down" onClick={()=>{setExpanded(!expanded)}}/>

           }

        </ChevronContainer>
    )
}

const AssessmentWindow = () =>{
    const {pastAssessments, currentAssessment, company, expanded, setExpanded} = useContext(MenuContext)
    return(
        <AssessmentContainer>
            <AssessmentTop>
                <h1>Previous Assessments</h1>
                {
                pastAssessments.map(entry =>{
                    const completedDate = new Date(entry.completionDate).toLocaleString("en-US").slice(0, 9)
                    return <AssessmentEntry key={pastAssessments.indexOf(entry)} index={pastAssessments.indexOf(entry)} date={completedDate} />
                })
            }
            </AssessmentTop>
            <AssessmentBottom vis={currentAssessment !== undefined} expanded={expanded}>
                {
                    currentAssessment && <ExpandingChevron bool={expanded} method={setExpanded}/>
                } 
                {
                    currentAssessment && 
                    <AssessmentList titleColor={company.titleColor}>
                        <h2>Gamechangers</h2>
                        {
                            currentAssessment.cards.map(entry =>{
                                if(currentAssessment.cards.indexOf(entry) < 2){
                                    return <CardListItem key={currentAssessment.cards.indexOf(entry)}>
                                        <span>{currentAssessment.cards.indexOf(entry)+1}</span>
                                        <p >{entry.skill}</p>
                                    </CardListItem>
                                }
                                else return null
                            })
                        }
                        <h2>Competencies</h2>
                        {
                            currentAssessment.cards.map(entry =>{
                                if(currentAssessment.cards.indexOf(entry) > 1){
                                    return <CardListItem key={currentAssessment.cards.indexOf(entry)}>
                                    <span>{currentAssessment.cards.indexOf(entry)+1}</span>
                                    <p>{entry.skill}</p>
                                </CardListItem>
                                }
                                else return null
                            })
                        }
                    </AssessmentList>
                }           
            </AssessmentBottom>
            
        </AssessmentContainer>
    )
}

export default AssessmentWindow