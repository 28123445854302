import { useState, useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import 
{ 
    ForgotContainer,
    InputContainer,
    PageTitle,
    StyledInput,
    PasswordContainer,
    PagePrompt,
    SubmitButton,
    InstructionMessage,
    InnerContainer
 } 
 from "./adminForgotPageStyles"
 import 
 {
    adminCheck,
    setPass,
    setupReset
} 
from '../../services/dataServices'
import { sendForgotEmail } from "../../services/mailServices"
import { useNavigate, Link } from "react-router-dom"

const ForgotPasswordPage = () =>{
    const [adminEmail, setAdmitEmail] = useState("")
    const [emailButtonVis, setEmailButtonVis] = useState(true)
    const [promptMessage, setPromptMessage] = useState("")
    const [passwordRequired, setPasswordRequired] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const {fullStringFormatter} = useContext(MenuContext)
    const redirect = useNavigate()

    const code6 = (Math.random()).toString().slice(2,8)
    
    const passwordChecker = (password) =>{
        if(password?.includes(" ")){
            return(`No spaces allowed in password \n Check email entry: ${password}`)
        }

        if(password?.length < 8){
            return(`Password must be longer than 7 characters \n Check entry: ${password}`)
        }

        // if(checkIfOnlyLetters(password)){
        //     return('Password must include at least 1 special character eg: !$@%^*#&+')
        // }

        if(checkIfNoNumbers(password)){
            return('Password must include at least 1 number character [0-9]')
        }

        if(checkIfNoSpecial(password)){
            return("Password must include at least 1 special character eg: !$@%^*#&+")
        }
 
        return true
    }

    const checkIfOnlyLetters = (field) =>{
        if(/^[a-zA-Z ]+$/.test(field)){
            return true
        }
        else return false
    }

    const checkIfNoNumbers = (field) =>{
        if(!/\d/.test(field)){
            return true
        }
        else return false
        
    }

    const checkIfNoSpecial = (field) =>{
        if(!/[ !$@%^*#&+]/.test(field)){
            return true
        }
        else return false
        
    }

 
    
    
    return(
        <>
            <ForgotContainer >
            <InnerContainer vis={!submitted}>
            <PageTitle>Forgot Password?</PageTitle>
            <InputContainer vis={!passwordRequired}>
                <div>
                <StyledInput placeholder="enter your email" value={adminEmail} 
                    onChange={(e)=>{
                        setAdmitEmail(fullStringFormatter(e.target.value))
                    }}
                />
                <span>{}</span>
                </div>
                <SubmitButton vis={emailButtonVis} onClick={async()=>{
                    if(adminEmail !== ""){
                        
                        await setupReset({email: adminEmail, code: code6})
                        .then(res => {
                            console.log(res)
                            if(res){
                                sendForgotEmail({email: adminEmail, code: code6})
                                .then(res =>{
                                    if(res){
                                        setSubmitted(true)
                                    }
                                })
                                .catch(err =>{
                                    console.log("ForgotPasswordPage line 113: ", err)
                                    alert(err)
                                })
                            }
                        })
                        .catch(err =>{
                            console.log("ForgotPasswordPage line 106: ", err)
                            alert(err)
                        })
                        
                    }
                    else{
                        alert("email cannot be blank")
                    }
                }}>
                    submit email
                </SubmitButton>
                <InstructionMessage vis={promptMessage === ""}>Enter your email to receive a reset form</InstructionMessage>

            </InputContainer>
            
            </InnerContainer>
            <InnerContainer vis={submitted}>
                <InstructionMessage vis={true}>
                    We've sent an email to {adminEmail}, please check your inbox and follow the instructions to reset your password.
                    <br/>
                    <br/>
                    <span>Back to log in <Link to="/login">page</Link></span>
                </InstructionMessage>
            </InnerContainer>
            
        </ForgotContainer>
        
        </>
    )
}

export default ForgotPasswordPage