import { useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import CardOptions from '../CardOptions/CardOptions'
import { PopUpInner} from "../PopUp/popUpStyles"
import { CompleteImageWindow } from "./imageModalStyles"


const ImageModal = () =>{
    const {tenArray, imagePopUp, setImagePopUp, imageEndpoint} = useContext(MenuContext)
    
    return(
          <PopUpInner vis={imagePopUp}>
           
                {
                    imageEndpoint && 
                    
                    <CompleteImageWindow
                    imageEndpoint={imageEndpoint}
                    
                    />
                }

                <i className="fa-regular fa-circle-xmark"
                    onClick={()=>{setImagePopUp(false)}}
                ></i>
            
        </PopUpInner>
    )
}

export default ImageModal