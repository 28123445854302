import {
    AssessmentContainer,
    AssessRowPair
} 
from './assessStyles'
import { MenuContext } from "../../utils/menuContext"
import { useContext, useState, useEffect } from "react"
import {useNavigate} from 'react-router-dom'
import {assessUpload} from '../../services/dataServices'
import ConfirmOptions from '../ConfirmOptions'
import AssessSelectPair from '../AssessSelectPair/AssessSelectPair'

function Assessment(){
    const {cardList, setCardList, tenArray, setTenArray, contact, setContact, previousPath,
       setPreviousPath, imageLock, company, pseudoCardList
    } = useContext(MenuContext)
    const [buttonSelectLock, setButtonSelectLock] = useState(true)
    const [buttonImageLock, setButtonImageLock] = useState(true)
    const [formData, setFormData] = useState(null)
    const placementArray = [0,1,2,3,4,5,6,7,8,9]
    const redirect = useNavigate()

    return(
        <AssessmentContainer>    
                        {
                            placementArray.length > 0 &&
                            placementArray.map(entry =>{
                                
                                return(
                                    <AssessSelectPair 
                                        key={placementArray.indexOf(entry)} 
                                        indx={placementArray.indexOf(entry)} 
                                        entry={entry}
                                        titleColor={company !== undefined && company.titleColor}
                                        accentColor={company !== undefined && company.accentColor}
                                        bottomColor={company !== undefined && company.bottomColor}
                                        placementArray = {placementArray}
                                        cardList={cardList}
                                        setCardList={setCardList}
                                        pseudoCardList={pseudoCardList}
                                        tenArray = {tenArray}
                                        setTenArray = {setTenArray}
                                    />       
                            )
                        })
                    }
        </AssessmentContainer>
    )
}

export default Assessment