import { useState, useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import 
{ 
    SignUpContainer,
    InputContainer,
    PageTitle,
    StyledInput,
    PasswordInput,
    PasswordContainer,
    PagePrompt,
    SubmitButton,
    LoginTitle,
    SignUpInner
 } from "./adminSignUpPageStyles"
 import {
    adminCheck,
    getCompany,
    setNewAdminPass,
    setPass
} from '../../services/dataServices'
 import { useNavigate, Link } from "react-router-dom"

const AdminSignUpPage = () =>{
    const [adminEmail, setAdmitEmail] = useState("")
    const [emailButtonVis, setEmailButtonVis] = useState(true)
    const [promptMessage, setPromptMessage] = useState("")
    const [passwordRequired, setPasswordRequired] = useState(false)
    const [password1, setPassword1] = useState("")
    const [passwordType1, setPasswordType1] = useState("password")
    const [passwordType2, setPasswordType2] = useState("password")
    const [password2, setPassword2] = useState("")
    const [adminCompany, setAdminCompany] = useState("")
    const [originalAdmin, setOriginalAdmin] = useState("")
    const {fullStringFormatter} = useContext(MenuContext)
    const redirect = useNavigate()
    
    const passwordChecker = (password) =>{
        if(password?.includes(" ")){
            return(`No spaces allowed in password \n Check email entry: ${password}`)
        }

        if(password?.length < 8){
            return(`Password must be longer than 7 characters \n Check entry: ${password}`)
        }

        // if(checkIfOnlyLetters(password)){
        //     return('Password must include at least 1 special character eg: !$@%^*#&+')
        // }

        if(checkIfNoNumbers(password)){
            return('Password must include at least 1 number character [0-9]')
        }

        if(checkIfNoSpecial(password)){
            return("Password must include at least 1 special character eg: !$@%^*#&+")
        }
 
        return true
    }

    const checkIfOnlyLetters = (field) =>{
        if(/^[a-zA-Z ]+$/.test(field)){
            return true
        }
        else return false
    }

    const checkIfNoNumbers = (field) =>{
        if(!/\d/.test(field)){
            return true
        }
        else return false
        
    }

    const checkIfNoSpecial = (field) =>{
        if(!/[ !$@%^*#&+]/.test(field)){
            return true
        }
        else return false
        
    }

    console.log("database admin: ", originalAdmin)
    
    return(
        <SignUpContainer>
            <SignUpInner>
                <PageTitle>Sign Up</PageTitle>
                <InputContainer vis={!passwordRequired}>
                    <div>
                    <StyledInput placeholder="enter your email" value={adminEmail} 
                        onChange={(e)=>{
                            setAdmitEmail(fullStringFormatter(e.target.value))
                        }}
                    />
                    <span>{}</span>
                    </div>
                    <SubmitButton vis={emailButtonVis} onClick={async()=>{
                        if(adminEmail !== ""){
                            await getCompany(adminEmail)
                            .then(async(res) =>{
                                if(res){
                                    const result = res.data
                                    setAdminCompany(res.data)
                                    await adminCheck(adminEmail)
                                    .then(res =>{
                                        // if(res.data === false){
                                        //    alert("unauthorized email")
                                        //    return
                                        // }
                                        if(res.data === "registered"){
                                            setEmailButtonVis(false)
                                            setPromptMessage("admin account exists, redirecting you to log in page...")
                                            setTimeout(()=>{redirect("/login")}, 2000)
                                            return
                                        }
                                        else{
                                            setOriginalAdmin(res.data)
                                            setEmailButtonVis(false)
                                            setPasswordRequired(true)
                                        }
                                    
                                    })
                                    .catch(err => alert(err))
                                        }
                            })
                        }
                        else{
                            alert("email cannot be blank")
                        }
                    }}>
                        submit email
                    </SubmitButton>
                    <LoginTitle vis={promptMessage === ""}>Already registered? Log in <Link to="/login">here</Link></LoginTitle>
                </InputContainer>
                
                <PasswordContainer vis={passwordRequired}>
                    
                    {/* <StyledInput  placeholder="enter new password" 
                        onChange={(e)=>{
                            setPassword1(e.target.value)
                        }}
                    />
                    <StyledInput  placeholder="re-confirm password" 
                        onChange={(e)=>{
                            setPassword2(e.target.value)
                        }}                    
                    /> */}


                    <PasswordInput vis={true}>
                        <StyledInput type={passwordType1} placeholder='enter new password' value={password1} onChange={(e)=>{
                            setPassword1(e.target.value)
                        }}/>
                        {
                            passwordType1 === "password" && 
                            <i className="fa-solid fa-eye" onClick={()=>{
                                setPasswordType1("text")
                            }}></i>
                        }
                        {
                            passwordType1 === "text" && 
                            <i className="fa-solid fa-eye-slash" onClick={()=>{
                                setPasswordType1("password")
                            }}></i>
                        }
                    </PasswordInput>    

                    <PasswordInput vis={true}>
                        <StyledInput  type={passwordType2} placeholder='confirm password' value={password2} onChange={(e)=>{
                            setPassword2(e.target.value)
                        }}/>
                        {
                            passwordType2 === "password" && 
                            <i className="fa-solid fa-eye" onClick={()=>{
                                setPasswordType2("text")
                            }}></i>
                        }
                        {
                            passwordType2 === "text" && 
                            <i className="fa-solid fa-eye-slash" onClick={()=>{
                                setPasswordType2("password")
                            }}></i>
                        }
                    </PasswordInput> 
                    <SubmitButton vis={promptMessage === ""} 
                        onClick={async()=>{
                            // if(password1 === "" || password2 === ""){
                            //     alert("passwords cannot be blank")
                            //     return
                            // }
                            if(passwordChecker(password1) !== true){
                                alert(passwordChecker(password1))
                                return
                            }
                            if(passwordChecker(password2) !== true){
                                alert(passwordChecker(password2))
                                return
                            }
                            
                            if(password1 !== password2){
                                alert("passwords do not match")
                                return
                            }
                            else{
                                setPromptMessage("password creation successful, redirecting to login...")

                                if(originalAdmin === false){
                                    await setPass({email: adminEmail, password: password1, company_id: adminCompany._id})
                                    .then(res => {
                                        if(res){
                                            setTimeout(()=>{redirect("/login")}, 2000)
                                        }
                                    })
                                    .catch(err => alert(err))
                                }
                                else{
                                    await setNewAdminPass({email: adminEmail, password: password1})
                                    .then(async(res) => {
                                        if(res){
                                            setTimeout(()=>{redirect("/login")}, 2000)
                                        }
                                    })
                                    .catch(err => alert(err))
                                }
                            }
                        }}
                    >
                        submit password
                    </SubmitButton>

                    <LoginTitle vis={promptMessage === ""}>
                        Password must be longer than 7 characters, include at least 1 number, and 1 special character (eg: !$@%^*#&)
                    </LoginTitle>
                </PasswordContainer>
                
                
                {
                    promptMessage !== "" &&
                    <PagePrompt style={{}}>
                        {promptMessage}
                    </PagePrompt>
                }
            </SignUpInner>
            
        </SignUpContainer>
    )
}

export default AdminSignUpPage