import {
    CardTray, 
    CardSlot1,
    CardSlot2,
    CardSlot3,
    CardSlot4
} from "./cardSetStyles"
import CardComponent from '../CardComponent/CardComponent'
import {useContext, useState} from 'react'
import { MenuContext } from "../../utils/menuContext"

const CardSet = ({bg_Color, skill, devStage, val_, ind_, entry}) =>{
    const {cardList, tenArray, setTenArray, company}=useContext(MenuContext)
    const [rightLock, setRightLock] =  useState(false)
    const [leftLock, setLeftLock] =  useState(false)


    const lockTimer = (lockFunction, timeInterval) =>{
        setTimeout(()=>{
            lockFunction(false)
        }, timeInterval)
    }

    const determinePath = (value, gameChangerStat=false) =>{
        let val
        if(gameChangerStat){
            switch(value){
                case 3: val = "mastery"
                break
                case 6: val = "advanced"
                break
                case 9: val = "intermediate"
                break
                case 12: val = "developing"
                break
                default: val ="developing"
                break
            }
            return val
        }
        else{
            switch(value){
                case 1: val = "mastery"
                break
                case 2: val = "advanced"
                break
                case 3: val = "intermediate"
                break
                case 4: val = "developing"
                break
                default: val ="developing"
                break
            }
            return val
        }
    }

    const decreaseVal = () =>{
        if(!rightLock){
            setRightLock(true)
            let tempArray = tenArray.map(item =>{
                if(tenArray.indexOf(item) === ind_){
                    if(tenArray.indexOf(item) < 2){
                        if(item.value > 3){
                            return {...item, value: item.value - 3,  devStage: determinePath(item.value-3, item.gameChanger) }
                        }
                    }
                    
                    if(item.value > 1){
                        return {...item, value: item.value - 1, devStage: determinePath(item.value-1)}
                    }
                    return item
                }
                else return item
            })
            // localStorage.setItem( "list", JSON.stringify({array: tempArray, cards: cardList}))
            setTenArray(tempArray)
            lockTimer(setRightLock, 500)
        }
    }

    const increaseVal = () =>{
        if(!leftLock){
            setLeftLock(true)
            let tempArray = tenArray.map(item =>{
                if(tenArray.indexOf(item) === ind_){
                    if(tenArray.indexOf(item) < 2){
                        if(item.value < 12){
                            return {...item, value: item.value + 3,  devStage: determinePath(item.value+3, item.gameChanger) }
                        }
                    }
                    
                    if(item.value < 4){
                        return {...item, value: item.value + 1, devStage: determinePath(item.value+1)}
                    }
                    return item
                }
                else return item
            })
            // localStorage.setItem( "list", JSON.stringify({array: tempArray, cards: cardList}))
            setTenArray(tempArray)
            lockTimer(setLeftLock, 500)
        }
    }

    return(
        <CardTray
            bottomColor = {company !== undefined && company.bottomColor}

        >
            <CardComponent entry={entry} bg_Color={bg_Color} val_={val_} skill={skill} devStage={devStage} indx_={ind_}/>
            <CardSlot1
                accentColor = {company !== undefined && company.accentColor}
                bottomColor = {company !== undefined && company.bottomColor}
                onClick={increaseVal}
            >
                <p>developing</p>
                <i className="fa-solid fa-chevron-left" ></i>
            </CardSlot1>
            <CardSlot2
                accentColor = {company !== undefined && company.accentColor}
                bottomColor = {company !== undefined && company.bottomColor}
            >
                <p>intermediate</p>
            </CardSlot2>
            <CardSlot3
                accentColor = {company !== undefined && company.accentColor}
                bottomColor = {company !== undefined && company.bottomColor}
            >
                <p>advanced</p>
            </CardSlot3>
            <CardSlot4
            accentColor = {company !== undefined && company.accentColor}
            bottomColor = {company !== undefined && company.bottomColor}
            onClick={decreaseVal}
            >   
                <p>mastery</p>
                <i className="fa-solid fa-chevron-right" ></i>
            </CardSlot4>
        </CardTray>
    )
}

export default CardSet