import styled, {css} from 'styled-components'


export const CSVReaderConatiner = styled.div`
    border-radius: 5px;
    padding: 1rem;
    border-radius: 10px;
    background: white;
    box-shadow: -5px 5px 5px #4f4f4f;
    margin-bottom: 2rem;
    
`

export const CSVInner = styled.div`

    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   h2{
    margin-bottom: 1.5rem;
   }

   input{
    border: 0.5px solid black;
    width: 90%;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    padding: 0.25rem;
    cursor: pointer;
   }

   button{
    cursor: pointer;
    border: ${props=> props.buttonVis? "0.5px solid black" : "none"};
    border-radius: 5px;
    height: ${props=> props.buttonVis? "25px" : "0"};
    padding: ${props=> props.buttonVis? "0.25rem 0.5rem" : "0rem"};
    background: "white";
    overflow: hidden;
    margin-bottom: 1.5rem;
    transition: all 0.5s ease;
   }


    
`

export const SubmitMessage = styled.p`
   text-align: center;
    opacity: ${props => props.vis? "1" : "0"};
    transition: opacity 0.75s ease;
    margin-bottom: 1.5rem;   
`

