import styled, {css} from 'styled-components'

const fadeStyle = css`
        opacity: ${props => props.on_off? "1" : "0"};
        transition: opacity 3s ease;
`

export const ContactContainer = styled.div`
    position: relative;
    padding: 0 2rem;
    height: 100vh;
    overflow: hidden;
    @media (min-width: 700px){
        width: 70%;
    }

    @media (min-width: 1200px){
        width: 60%;
    }
   
`

export const ContactHeader = styled.div`
    /* background-color: #11898A; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    border: 2px solid #11898A;
    margin-bottom: 2rem;
    
    h1{
        @media (max-width: 300px){
            font-size: 1.5rem;
        }
        padding: 0;
        margin: 0;
        font-size: 1.9rem;
        color: #11898A;
        text-align: right;
        font-weight: 700;
        width: 70%;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;

        @media (min-width: 460px){
            font-size: 2.2rem;
        }

        @media (min-width: 600px){
            font-size: 2.45rem;
        }

        @media (min-width: 800px){
            font-size: 3.25rem;
        }

        @media (min-width: 800px){
            font-size: 3.5rem;
        }

        @media (min-width: 1100px){
            font-size: 3.7rem;
        }

        @media (min-width: 1600px){
            font-size: 4rem;
        }
    }

    @media (min-width: 800px){
        margin-bottom: 3rem;
    }

`

export const RotatingContainer = styled.div`
    @media (min-width: 700px){
        display: flex;
        flex-direction: row-reverse;
        margin-top: 15%;
    }

    @media(min-width: 1000px){
        justify-content: center;
    }

`

export const DynamicInstructions = styled.div`
    margin-bottom: 1rem;
    
    p{
        margin: 0;
        padding: 0;

        font-size: 0.8rem;
        font-weight: 400;
        @media (min-width: 540px){
            margin-bottom: 2rem;
            font-size: 1rem;
        }

        @media (min-width: 800px){
            font-size: 1.2rem;
          
        }

        @media (min-width: 1200px){
            font-size: 1.4rem;
        }
    }
    
    @media (min-width: 700px){
        border: 2px solid #11898A;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        max-width: 50%;
        margin: 0;
        color: black;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

    }

    @media (min-width: 1200px){
        padding: 2rem;
    }
`


export const ContactForm = styled.div`
    padding: 1rem;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    @media (max-width: 300px){
        width: 80%;
    }

    @media (min-width: 530px){
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (min-width: 700px){
        margin: 0;
        width: 50%;
        background: #11898A;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    @media (min-width: 1000px){
        width: 35%;

        justify-content: center;
    }
`

export const ContactFieldPair = styled.div`
    p{
        font-weight: 400;
        margin: 0;
        padding: 0;
        
    }

    input{
        max-width: 100%;
        border-radius: 2px;
        border: 1px solid black;
        background-color: transparent;
        padding: 0.25rem 0.5rem;
        @media (min-width: 700px){
            border: none;
            background-color: white;
        }
    }
    font-size: 0.8rem;
    
    margin-bottom: 1rem;

    @media (min-width: 530px){
        *{font-size: 1rem;}
        width: 45%;
    }

    @media (min-width: 700px){
        width: 70%;
        color: white;
    }

    @media (min-width: 900px){
       
    }

    @media (min-width: 1200px){
        *{font-size: 1.3rem;}
    }
`


const submitButtonStyle = css`
    background: none;
    display: block;
    border: ${props => props.disabled? "1px solid darkslategrey" : "1px solid black"};
    border-radius: 3px;
    color: ${props => props.disabled? "darkslategrey": "black"};
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1rem;
    cursor: pointer;

    &:disabled{
        cursor: initial;
        background-color: rgba(0,0,0,0.5);

    }

    @media (min-width: 540px){
        margin-top: 1.2rem;
        font-size: 1rem;
    }

    @media (min-width: 800px){
        font-size: 1.3rem;
        padding: 0.5rem 1rem;

    }

    @media (min-width: 1200px){
        font-size: 1.5rem
    }
`

export const MobileSubmitButton =  styled.button`
    ${submitButtonStyle};
    display: none;
    margin: 1rem auto;

    @media (min-width: 530px){
        margin: 3rem auto;
    }

    @media (max-width: 699px){
        display: block;
        
    }
`

export const LargeSubmitButton =  styled.button`
    ${submitButtonStyle};
    display: none;
    @media (min-width: 700px){
        border: none;
        display: block;
        margin: 0;
        color: rgba(0, 0, 0, 1);
        font-weight: 700;
        transition: all 0.85s ease;
        i{
            margin-left: 0.5rem;
        }

        &:disabled{
        background: none;
        color: rgba(0, 0, 0, 0);
        margin-right: 40%;
        }

    }
`

///////////////

export const LogoPair = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: ${props => props.shift? "2rem" : "-5rem"};
    left: 50%;
    transform: translateX(-50%);
    transition: all 2s ease;
`

export const LogoMessage = styled.h4`
    font-size: 0.7rem;
    font-weight: 400;
`

export const Logo = styled.div`
    background-image: url('./images/tero-logo.png');
    background-position: center;
    background-size: cover;
    height:20px;
    width: 20px;
    border-radius: 100%;
    margin-left: 0.25rem;
    @media (min-width: 800px){
        width:150px;
        height: 150px;
    }
    box-shadow: 0px 0px 1px black;
`
const greetingStyles = css`
    color: #37A89A;
    font-size: ${props => `${props.fs}rem`};
    text-align: right;
    margin-top: ${props => `${props.mt}vh`};
    transition: margin 1s ease;
`

// export const InitialGreeting = styled.h1`
//     ${greetingStyles};
//     margin-bottom: ${props => props.lift? ""}
// `
export const Greeting = styled.h1`
    ${greetingStyles};
    ${fadeStyle};

`

const instructionStyles = css`
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
    @media (min-width: 540px){
        margin-bottom: 2rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 800px){
        font-size: 1.2rem;
    }
    ${fadeStyle};
`



export const Instructions = styled.p`
    ${instructionStyles};
`

export const InitialInstructions = styled.p`
    ${instructionStyles};
    margin-bottom: 6rem;
    margin-top: ${props => props.on_off? `${props.mt}vh` : "0vh"};
    transition:  all 3s ease;

`

const bodyStyles = css`
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    ${bodyStyles};
`

export const InitialContent = styled.div`
    ${bodyStyles};

    .contact-select{
        display: block;
        border: none;
        padding: 0.5rem;
        padding-right: 1rem;
        border-radius: 5px;
        margin-left: ${props => `${props.shift? "0rem" : "50rem"}`};
        transition: margin 2s ease;
        box-shadow: 0px 0px 5px 1px grey;
    }

 
`