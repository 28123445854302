import styled, {css} from 'styled-components'

export const EmployeeList = styled.div`
   width: 90%;
   border: 0.5px solid black;
   border-radius: 5px;
   padding: 0.5rem;
   overflow: scroll;
   height: 300px;
`
const listEntryStyles = css`
    display: flex;
    width: fit-content;
    padding: 0.5rem 0;

    p{
        width: 200px;
        padding: 0.5rem 1rem;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        overflow: hidden;
        text-align: center;
        font-size: 0.8rem;
    }

    p:last-of-type{
        border-right: none;
    }

`
export const HeaderEntry = styled.div`
    ${listEntryStyles};
    p{
        font-weight: bold;
        border-right: 1.5px solid black;
        border-bottom: 1.5px solid black;
        border-right: none;
    }
`

export const EmployeeEntry = styled.div`
    ${listEntryStyles};
`