import { 
        AdditionalManagerButton,
        RemoveManagerButton, 
        ModularContainer,
        ModularInput,
        SubmitSingleButton,
        IncrementTray
} from "./personnelModalStyles"
import { useState, useContext } from "react"
import { MenuContext } from "../../utils/menuContext"

const ModularRow = ({indx, singleMarker}) =>{
    const [localMangerEmail, setLocalManagerEmail] = useState("")
    const {addManagersList, setAddManagersList, setPersonnelPopUp} = useContext(MenuContext)

    const submitSingle = () =>{
        //some axios call to some endpoint

        setPersonnelPopUp(false)
        setAddManagersList([])
    }    

    return(
        <ModularContainer>
           <IncrementTray>
                <AdditionalManagerButton
                        onClick={()=>{
                            setAddManagersList(prev => [...prev, {}])
                        }}
                >+</AdditionalManagerButton>
                <RemoveManagerButton vis={indx > 0}
                        onClick={()=>{ 
                            let placeholder = addManagersList
                            placeholder.pop()
                            console.log(placeholder)
                            setAddManagersList([...placeholder])

                        }}
                >-</RemoveManagerButton>
           </IncrementTray>
           <ModularInput value={localMangerEmail} placeholder="enter email"
                onChange = {(e)=>{
                    setLocalManagerEmail(e.target.value)
                }}
           />
           <SubmitSingleButton vis={addManagersList.length === 1} onClick={submitSingle}>
                submit
           </SubmitSingleButton>
        </ModularContainer>
    )
}

export default ModularRow