import styled, {css} from 'styled-components'

export const LoginContainer = styled.div`

    height: 100vh;
    @media (min-width: 1000px){
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const LoginInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 13rem;
    h1{
        font-size: 1.25rem;
        color: black;
        margin-bottom: 1rem;
    }

    @media (min-width: 1000px){
        *{
            font-size: 1.5rem;
        }

        h1{
            font-size: 2rem;
        }

        margin-top: 0;
    }
`

const styledInputStyles = css`
    border: 0.5px solid black;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    display: ${props => props.vis? "initial" : "none"};
`

export const StyledInput = styled.input`
    ${styledInputStyles};
`
export const PasswordInput = styled.div`
    position: relative;
    i{
        position: absolute;
        right: 0;
        transform: translate(-5px, 5px);
        color: grey;
    }
    display: ${props => props.vis? "initial" : "none"};

   
`

export const SubmitButton = styled.button`
    margin-bottom: 1rem;
    display: ${props => props.vis? "inline-block" : "none"};
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 0.5px solid black;
    background-image: linear-gradient(white, lightgrey);
`

export const RedirectMessage = styled.p`
    margin-bottom: 1rem;
    display: ${props => props.vis? "initial" : "none"};
`
