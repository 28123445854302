import styled, {css} from 'styled-components'


export const ConfirmationContainer = styled.div`
    width: 95%;
    margin: 0 auto;
    padding-top: 1rem;
    @media (min-width: 700px){
        width: 70%;
    }

    @media (min-width: 1200px){
        width: 60%;
    }

`

export const ConCompetencyContainer = styled.div`
    padding-top: 1rem;

    @media (min-width: 700px){
        width: 70%;
    }

    @media (min-width: 1200px){
        width: 60%;
    }
`


export const ConCardContainer = styled.div`
    border: 2px solid black;
    border-radius: 10px;
`

export const ContainerTitle = styled.div`
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
    padding: 1rem;
    h2{
        margin: 0;
        padding: 0;
        text-align: center;
    }
`

const colorSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "blue": val = `linear-gradient(to right, dodgerblue 90%, #1769bd)`
        break
        case "red": val = `linear-gradient(to right, crimson 90%, #a10b26)`
        break
        default: val = "white"
    }
    return val
}

export const ConCard = styled.div`
   background: ${props => props.bg_Color && colorSwapper(props.bg_Color)};
    color: white;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0px 0px 10px grey;
    min-height: 100px;
    margin-bottom: 1rem;
    width: ${props => `${props.width_}%`};
    p, h4{
        margin: 0;
        padding: 0;
        font-size: 0.7rem;
    }
    p{
        font-weight: 400;
    }
    h4{
        padding: 0.5rem 0;
        text-align: center;
        cursor: pointer;
    }
    h4:first-of-type{
        font-size: 0.6rem;
        text-transform: uppercase;
    }
`

export const ImageConfirm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    
    img{
        max-width: 100%;
    }


`

export const ImagePair = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        margin-bottom: 1rem;
    }
    a{
        color: black;
    }
`

export const CollapseUpload = styled.div`
    width: 80%;
    *{
        margin: 0;
        padding: 0;
    }

    margin: 0 auto;
    span{
        font-weight: 400;
        margin-right: 0.5rem;
    }


    margin-bottom: 2rem;

    input{
        max-width: 80%;
    }

    div:first-of-type{
       
        border: 1px solid black;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
    }

    div:last-of-type{
        height: ${props => props.clicked? "40px" : "0px"};
        border: ${props => props.clicked? "1px solid black" : "0px solid black"};
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top: none;
        display: flex;
        justify-content: center;
        padding: ${props => props.clicked? "0.5rem" : "0rem"};
        transition: all 0.5s ease;
        
    }
`

export const ConfirmCardPair = styled.div`
    display: flex;
    justify-content: space-between;
    width: 75%;
`

export const ConGameChangerList = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    border-bottom: 2px solid black;
`
export const ConCompetencyList = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   padding: 1rem;
`
