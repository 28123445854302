import styled, {css} from 'styled-components'

const shifting =(index) =>{
    let modified = index * 100

    switch(index){
        case modified/100 : return `-${modified}%`
        break
        // case 1: return "-100%"
        // break
        // case 2: return "-200%"
        // break
        // case 3: return "-300%"
        // break
        // case 4: return "-400%"
        // break
        // case 5: return "-500%"
        // break
        default: return "0%";
    }
}


export const CarouselContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0%; 
    left: ${props => shifting(props.isOnScreen)};
    /* transform: ${props => `translateX(${shifting(props.isOnScreen)})`}; */
    transition: all 1s ease;
    z-index: 1;
`