import styled from 'styled-components'

export const CompleteImageWindow = styled.div`
    height: 80%;
    width: 90%;
    background: ${props => `url(${props.imageEndpoint})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 0.5rem;
`
