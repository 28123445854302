import { useState, useEffect, useContext} from "react"
import { getAssessmentSort } from "../../services/dataServices"
import { MenuContext } from "../../utils/menuContext"
import 
{
    PersonnelItem,
    PersonnelHolderAdvanced,
    StatHolder,
    TransferHolder,
    StatWindow,
    StatRow,
    SelectionWindow,
    SelectionColumn,
    SkillRow,
    SkillMonth,
    SkillTitleGroup,
    SkillNumberGroup,
    SkillTitle,
    SkillNumber
} from "./personnelModalStyles"

const PersonnelManager = ({lvl}) =>{
    const {setPersonnelPopUp, addManagersList, setAddManagersList, admin,
        transferPopUp, setTransferPopUp, adminList
    } = useContext(MenuContext)
    const [singleManager, setSingleManager] = useState("")
    const [pseudoList, setPseudoList] = useState([
        { _id: 1, email: 'jim@coretva.com', company_id: '62d47c7a36aeee14652966cd'},
        { _id: 2, email: 'cindy@coretva.com', company_id: '62d47c7a36aeee14652966cd'},
        { _id: 3, email: 'alex@coretva.com', company_id: '62d47c7a36aeee14652966cd'},
        { _id: 4, email: 'Kim@tero.com', company_id: '6352d9a0c5c689ea93285bdc'},
        { _id: 5, email: 'Robert@tero.com', company_id: '6352d9a0c5c689ea93285bdc'},
        { _id: 6, email: 'Cid@tero.com', company_id: '6352d9a0c5c689ea93285bdc'},
    ])
    const [assessments, setAssessments] = useState([])
    const [stats, setStats] = useState([])


     //Original function before skills addition
    // const determineStats = (arr=[]) =>{
    //     let bluePath = 0
    //     let redPath = 0
    //     let greenPath = 0
    //     let yellowPath = 0
    //     let orangePath = 0
    //     let manualSelect = 0
    //     let firstSelect = 0
    //     let secondSelect = 0

    //     arr.forEach(entry =>{
    //         switch(entry.learningPath){
    //             case "blue" : bluePath++
    //             break
    //             case "red" : redPath++
    //             break
    //             case "green" : greenPath++
    //             break
    //             case "yellow" : yellowPath++
    //             break
    //             case "orange" : orangePath++
    //             break
    //             default: return null
    //         }

    //         switch(entry.assessmentChoice){
    //             case "manual" : manualSelect++
    //             break
    //             case "1st" : firstSelect++
    //             break
    //             case "2nd" : secondSelect++
    //             break
    //             default: return null
    //         }
    //     })

    //     setStats({
    //      bluePath: bluePath,
    //      redPath: redPath,
    //      greenPath: greenPath,
    //      yellowPath: yellowPath,
    //      orangePath: orangePath,
    //      manualSelect: manualSelect,
    //      firstSelect: firstSelect,
    //      secondSelect: secondSelect
    //     })
    // }
    let timeManagement = 0
    let handlingQuestions = 0
    let whyChange = 0
    let modelingTheWay = 0
    let levelsOfCustomerInfluence = 0
    let beUpstanding = 0
    let prioritization = 0
    let successPlanning = 0
    let meetingEffectiveness = 0
    let nonVerbalCommunication = 0
    let healthyCustomerRelationships = 0
    let receivingFeedback = 0
    let thrivingThroughChange = 0
    let effectiveListening = 0
    let engagement = 0


   //new function verison based on skills 
    const determineStats = (arr=[]) =>{
        

        arr.forEach(entry =>{
            const innerArray = entry.currentSkillsChallenges

                innerArray !== undefined && innerArray.forEach(entry =>{
                    switch(entry.title){
                        case 'Time Management': timeManagement++
                        break
                        case 'Handling Questions': handlingQuestions++
                        break
                        case 'Why Change?' : whyChange++
                        break
                        case 'Modeling the Way' : modelingTheWay++
                        break
                        case '5 Levels of Customer Influence' : levelsOfCustomerInfluence++
                        break
                        case 'Be Upstanding' : beUpstanding++
                        break
                        case 'Prioritization' : prioritization++
                        break
                        case 'Success Planning' : successPlanning++
                        break
                        case 'Meeting Effectiveness' : meetingEffectiveness++
                        break
                        case 'Non-verbal Communication': nonVerbalCommunication++
                        break
                        case 'Healthy Customer Relationships' : healthyCustomerRelationships++
                        break
                        case 'Receiving Feedback' : receivingFeedback++
                        break
                        case 'Thriving Through Change' : thrivingThroughChange++
                        break
                        case 'Effective Listening' : effectiveListening++
                        break
                        case 'Engagement' : engagement++
                        break
                        default: return null
                        }
                    }
                )
            }

        )

        setStats({
            timeManagement: timeManagement,
            handlingQuestions: handlingQuestions,
            whyChange : whyChange,
            modelingTheWay : modelingTheWay,
            levelsOfCustomerInfluence : levelsOfCustomerInfluence,
            beUpstanding : beUpstanding,
            prioritization : prioritization,
            successPlanning : successPlanning,
            meetingEffectiveness : meetingEffectiveness,
            nonVerbalCommunication: nonVerbalCommunication,
            healthyCustomerRelationships : healthyCustomerRelationships,
            receivingFeedback : receivingFeedback,
            thrivingThroughChange : thrivingThroughChange,
            effectiveListening : effectiveListening,
            engagement : engagement
        })
    }

    useEffect(()=>{
        getAssessmentSort(admin.company_id)
        .then(res => setAssessments(res.data))
        .catch(err => console.log("PersonnelManager line 28: ", err))        
    }
    ,[])

    useEffect(()=>{
        if(assessments.length > 0){
            determineStats(assessments)
        }
    },[assessments])

    return(
        <>
        
           
            {/* {
                adminList !== undefined && 
                <ListHolder>
                    {
                        adminList.length > 0 &&
                        adminList.map(entry =>{
                            if(entry.email === admin.email){
                                return
                            }
                            else{
                                return(
                                    <ListItem key={entry._id}>
                                        <p>{entry.email}</p>
                                        
                                    </ListItem>
                                )
                            }
                        })
                    }
                </ListHolder>
            } */}
          
                {/* Original component before skills addition*/}

                {/* <PersonnelHolderAdvanced vis={lvl === 2}>
                    <StatHolder>
                        <h2>Stats</h2>
                        <StatWindow>
                            <StatRow><p>Blue Path</p><p>{stats.bluePath}</p></StatRow>
                            <StatRow><p>Red Path</p><p>{stats.redPath}</p></StatRow>
                            <StatRow><p>Green Path</p><p>{stats.greenPath}</p></StatRow>
                            <StatRow><p>Yellow Path</p><p>{stats.yellowPath}</p></StatRow>
                            <StatRow><p>Orange Path</p><p>{stats.orangePath}</p></StatRow>
                        </StatWindow>
                        <SelectionWindow>
                            <SelectionColumn>
                                <p>1st Suggested</p>
                                <p>{stats.firstSelect}</p>
                            </SelectionColumn>
                            <SelectionColumn>
                                <p>2nd Suggested</p>
                                <p>{stats.secondSelect}</p>
                            </SelectionColumn>
                            <SelectionColumn>
                                <p>Manual Choice</p>
                                <p>{stats.manualSelect}</p>
                            </SelectionColumn>
                        </SelectionWindow>
                    </StatHolder>
                   <TransferHolder>
                        <h2>Transfer Employees</h2>
                        <i className="fa-solid fa-arrow-right-arrow-left"
                            onClick={()=>{
                                setTransferPopUp(true)
                            }}
                        >
                        </i>  
                    </TransferHolder>              
                </PersonnelHolderAdvanced> */}
                
                <PersonnelHolderAdvanced vis={lvl === 2}>
                    <StatHolder>
                        <h2>Stats</h2>
                        <StatWindow>
                            <SkillRow>
                                <SkillMonth>
                                    <p>Topic</p>
                                    <p>1</p>
                                </SkillMonth>
                                <SkillTitleGroup>
                                    <SkillTitle><p>Time Management</p></SkillTitle>
                                    <SkillTitle><p>Handling Questions</p></SkillTitle>
                                    <SkillTitle><p>Why Change?</p></SkillTitle>
                                </SkillTitleGroup>
                                <SkillNumberGroup>
                                    <SkillNumber><p>{stats.timeManagement}</p></SkillNumber>
                                    <SkillNumber><p>{stats.handlingQuestions}</p></SkillNumber>
                                    <SkillNumber><p>{stats.whyChange}</p></SkillNumber>
                                </SkillNumberGroup>
                            </SkillRow>
                            <SkillRow>
                                <SkillMonth>
                                    <p>Topic</p>
                                    <p>2</p>
                                </SkillMonth>
                                <SkillTitleGroup>
                                    <SkillTitle><p>Modeling the Way</p></SkillTitle>
                                    <SkillTitle><p>5 Levels of Customer Influence</p></SkillTitle>
                                    <SkillTitle><p>Be Upstanding</p></SkillTitle>
                                </SkillTitleGroup>
                                <SkillNumberGroup>
                                    <SkillNumber><p>{stats.modelingTheWay}</p></SkillNumber>
                                    <SkillNumber><p>{stats.levelsOfCustomerInfluence}</p></SkillNumber>
                                    <SkillNumber><p>{stats.beUpstanding}</p></SkillNumber>
                                </SkillNumberGroup>
                            </SkillRow>
                            <SkillRow>
                                <SkillMonth>
                                    <p>Topic</p>
                                    <p>3</p>
                                </SkillMonth>
                                <SkillTitleGroup>
                                    <SkillTitle><p>Prioritization</p></SkillTitle>
                                    <SkillTitle><p>Success Planning</p></SkillTitle>
                                    <SkillTitle><p>Meeting Effectiveness</p></SkillTitle>
                                </SkillTitleGroup>
                                <SkillNumberGroup>
                                    <SkillNumber><p>{stats.prioritization}</p></SkillNumber>
                                    <SkillNumber><p>{stats.successPlanning}</p></SkillNumber>
                                    <SkillNumber><p>{stats.meetingEffectiveness}</p></SkillNumber>
                                </SkillNumberGroup>
                            </SkillRow>
                            <SkillRow>
                                <SkillMonth>
                                    <p>Topic</p>
                                    <p>4</p>
                                </SkillMonth>
                                <SkillTitleGroup>
                                    <SkillTitle><p>Non-Verbal Communication</p></SkillTitle>
                                    <SkillTitle><p>Healthy Customer Relationships</p></SkillTitle>
                                    <SkillTitle><p>Receiving Feedback</p></SkillTitle>
                                </SkillTitleGroup>
                                <SkillNumberGroup>
                                    <SkillNumber><p>{stats.nonVerbalCommunication}</p></SkillNumber>
                                    <SkillNumber><p>{stats.healthyCustomerRelationships}</p></SkillNumber>
                                    <SkillNumber><p>{stats.receivingFeedback}</p></SkillNumber>
                                </SkillNumberGroup>
                            </SkillRow>
                            <SkillRow>
                                <SkillMonth>
                                    <p>Topic</p>
                                    <p>5</p>
                                </SkillMonth>
                                <SkillTitleGroup>
                                    <SkillTitle><p>Thriving Through Change</p></SkillTitle>
                                    <SkillTitle><p>Effective Listening</p></SkillTitle>
                                    <SkillTitle><p>Engagement</p></SkillTitle>
                                </SkillTitleGroup>
                                <SkillNumberGroup>
                                    <SkillNumber><p>{stats.thrivingThroughChange}</p></SkillNumber>
                                    <SkillNumber><p>{stats.effectiveListening}</p></SkillNumber>
                                    <SkillNumber><p>{stats.engagement}</p></SkillNumber>
                                </SkillNumberGroup>
                            </SkillRow>
                         
                        </StatWindow>
                        
                    </StatHolder>
                   <TransferHolder>
                        <h2>Transfer Employees</h2>
                        <i className="fa-solid fa-arrow-right-arrow-left"
                            onClick={()=>{
                                setTransferPopUp(true)
                            }}
                        >
                        </i>  
                    </TransferHolder>              
                </PersonnelHolderAdvanced>
            

        </>
    )
}

export default PersonnelManager