import styled from "styled-components";

export const AboutContainer = styled.div`
    
    height: ${props => {
            if(props.standard){
                return "auto"
            }
            else{
                if(props.vis){
        
                    return "200px"
                }
                else return "0px"
            }
        }
        
    };
    
    overflow-y: ${props => {
            if(props.standard){
                return "visible"
            }
            else{
                if(props.vis){
        
                    return "scroll"
                }
                else return "hidden"
            }
        }
        
    };

    transition: all 1s ease;
`

export const FormContainer = styled.div`
    
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    align-items: flex-start;

    *{
        font-size: 0.9rem;
    }
    input{
        background: none;
        border: none;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        border-bottom: ${props => props.accentColor? `0.5px solid ${props.accentColor}` : "0.5px solid black"};
        color:  ${props => props.accentColor};
        width: 100%;
        font-size: 0.9rem;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props=>props.accentColor};
        opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props=>props.accentColor};
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props=>props.accentColor};
        }
    }
    
    
    input:last-of-type{
        flex: 100%;
    }

    select{
        padding: 0.2rem 0.5rem;
        background: none;
        border: ${props => props.accentColor? `0.5px solid ${props.accentColor}` : "0.5px solid black"};
        border-radius: 5px;
        color: ${props=>props.accentColor}; 
        width: 47%; 
    }


    option{
        background: ${props => props.bottomColor};
        font-size: 0.7rem;
    }

    p{
        color: ${props=>props.accentColor};
    }

    transition: margin 1s ease;
    
`

export const AboutFormField = styled.div`
    width: 47%;
    margin-bottom: 1rem;
    height: 8vh;
    p{
        padding-left: 0.25rem;
        padding-top: 0.25rem;
    }
    input{
        font-size: 16px;
    }
  
`
export const PhoneNumberPair = styled.div`

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    
`

export const PhoneNumberTickPair = styled.div`
    display: flex;

    div{
        width: 20%;
    }
    p{
        font-size: 0.7rem;
        width: 70%;
    
    }
    width: 48%;
`

export const PhoneNumberInputPair = styled.div`
    width: 48%;
    opacity: ${props => props.opc? "1" : "0.3"};
    
`
