import {useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom'
import { MenuContext } from '../../utils/menuContext'
import 
{ 
    LoginContainer,
    ListHolder,
    ListItem,
    Uploader,
    StyledInput,
    PasswordInput,
    SubmitButton,
    RedirectMessage,
    LoginInner
} 
from './adminLoginPageStyles'
import { setLog, getCookie } from '../../services/dataServices'
import { useNavigate } from 'react-router-dom'
import cookies from 'universal-cookie'
const cookiesInstance = new cookies()

const AdminLoginPage = () =>{
    const {admin, setAdmin, fullStringFormatter} = useContext(MenuContext)
    const [adminEmail, setAdminEmail] = useState("")
    const [adminPassword, setAdminPassword] = useState("")
    const [promptMessage, setPromptMessage] = useState("")
    const [passwordType, setPasswordType] = useState("password")
    const redirect = useNavigate()


    const createCookie = () =>{
        cookiesInstance.set(
            "person", 
            {
            name: "Roy Roseborne", age: 34}, 
            {
                sameSite: 'strict', path: '/', 
                expires: new Date(new Date().getTime() + 10 * 1000),
                httpOnly: true
            }
        )
    }

    // console.log(localStorage.getItem("person"))

    const emailChecker = (address) =>{
        if(address?.includes(" ")){
            return(`warning: no space allowed in email \n Check email entry: ${address}`)
            
        }
        if(!address?.includes("@")){
            return(`warning: email must contain "@" symbol \n Check email entry: ${address}`)
            
        }
    
        
        if(address?.slice(0, address.indexOf("@")) === ""){
            return(`warning: address name cannot be blank \nCheck email entry: ${address}`)
            
        }
    
        if(!address?.slice(address.indexOf("@")).includes(".")){
            return(`warning: email domain must include "." \n Check email entry: ${address}`)
            
        }
    
        return true
    }

    return(
        <LoginContainer>
           <LoginInner>
           <h1>Log In</h1>
            <StyledInput vis={promptMessage === ""} placeholder='email' value={adminEmail}  onChange={(e)=>{
                setAdminEmail(fullStringFormatter(e.target.value))
            }}/>

            <PasswordInput vis={promptMessage === ""}>
                <StyledInput vis={true} type={passwordType} placeholder='password' value={adminPassword} onChange={(e)=>{
                    setAdminPassword(e.target.value)
                }}/>
                {
                    passwordType === "password" && 
                    <i className="fa-solid fa-eye" onClick={()=>{
                        setPasswordType("text")
                    }}></i>
                }
                {
                    passwordType === "text" && 
                    <i className="fa-solid fa-eye-slash" onClick={()=>{
                        setPasswordType("password")
                    }}></i>
                }
            </PasswordInput>
                
            
            <SubmitButton vis={adminEmail !== "" && adminPassword !== ""} onClick={async()=>{
                if(emailChecker(adminEmail) !== true){
                    alert(emailChecker(adminEmail))
                    return
                }
                else{
                    await setLog({email: adminEmail, password: adminPassword})
                    .then(async(res) => {
                        if(res.data === false){
                            alert("email and password do not match")
                            return
                        }
                        if(res.data === "invalid"){
                            alert("email and password do not match")
                            return
                        }
                        else{
                            setAdmin(res.data)
                            setAdminEmail("")
                            setAdminPassword("")
                            localStorage.setItem("token", res.data._id)

                            setPromptMessage("login success! Signing in...")
                            // setTimeout(()=>{redirect("/admin")}, 2000)
                            await getCookie()
                            .then(res => setTimeout(()=>{redirect("/admin")}, 2000) )
                            .catch(err => alert(err))
                        }
                    })
                    .catch(err => alert(err))
                }
            }}>
                log in
            </SubmitButton>
            <RedirectMessage vis={promptMessage === ""}>Not registered? Sign up <Link to="/signup">here</Link></RedirectMessage>
            <RedirectMessage vis={promptMessage === ""}>Forgot Password? Click <Link to="/fp">here</Link></RedirectMessage>
            {
                promptMessage !== "" &&
                <p>{promptMessage}</p>
            }
            {/* <button onClick={getCookie}>
                create cookie
            </button> */}
           </LoginInner>
        </LoginContainer>
    )
}

export default AdminLoginPage