import {useState, useContext, useEffect} from 'react'
import { MenuContext } from '../../utils/menuContext'
import {CardSkillInput, CardConcealableOption, SearchContainer} from './cardOptionStyles'
import StyledList from '../SearchableDropDown/searchableDropDownStyles'
import CardSearchList from './CardSearchList'

const CardOptions = ({indx, vis, val, side}) =>{
    const {cardList, setCardList, tenArray, setTenArray, company, setSkillPopUp} = useContext(MenuContext)
    const [currentSkill, setCurrentSkill] = useState("")
    const [currentDevelopment, setCurrentDevelopment] = useState("")
    const [toggle, setToggle] = useState(false)
    const [value, setValue] = useState("")

    // useEffect(()=>{
    //     if(localStorage.getItem("list")){
    //         let savedCards = JSON.parse(localStorage.getItem("list")).cards
    //         setCardList(savedCards)
    //         savedCards.forEach(entry =>{
    //             if(tenArray[indx].skill === entry.skill){
    //                 setCurrentSkill(entry.skill)
    //             }
    //         })

    //     }
    // },[])
    


    const designateValue = (developmentStage) =>{
        let val
        switch(developmentStage){
            case 'developing' :
                val = 4
                break
            case 'intermediate' :
                val = 3
                break
            case 'advanced' :
                val = 2
                break
            case 'mastery':
                val = 1
            
        }

        return val
}

    const updateSelections = (target) =>{
    
        
        const modified = cardList.map(entry =>{
            if(entry.skill === tenArray[indx].skill){
                return {...entry, selected: false}
            }
            else return entry
        })

        

        cardList.forEach(entry =>{
            if(entry.skill === target){
                setCurrentSkill(target)
                let cloneTenArray = tenArray
                if(indx < 2){
                    return cloneTenArray.splice(indx, 1, {...entry, devStage: tenArray[indx].devStage , value: (designateValue(tenArray[indx].devStage)*3), selected: true, gameChanger: true})
                }
                else{
                    cloneTenArray.splice(indx, 1, {...entry, devStage: tenArray[indx].devStage , value: designateValue(tenArray[indx].devStage), selected: true, gameChanger: false})
                }
               
                setTenArray(cloneTenArray)
                // localStorage.setItem( "list", JSON.stringify({array: placeholder, cards: cardList}))
            }
        })

        // if(currentSkill === ""){
        //     const newList = cardList.map(entry =>{
        //         if(entry.skill === target){
        //             return {...entry, selected: true}
        //         }
        //         return entry
        //     })
        //     setCardList(newList)
        //     // localStorage.setItem( "list", JSON.stringify({array: tenArray, cards: newList}))
        // }

        // else{
        //     const newList = modified.map(entry =>{
        //         if(entry.skill === target){
        //             return {...entry, selected: true}
        //         }
        //         return entry
        //     })
        //     setCardList(newList)
        //     // localStorage.setItem( "list", JSON.stringify({array: tenArray, cards: newList}))

        // }
       
        setCardList(modified.map(entry =>{
            if(entry.skill === target){
                return {...entry, selected: true}
            }
            else return entry
        }))
    }



    return(
            
            <SearchContainer 
                vis={vis}
                bottomColor = {company !== undefined && company.bottomColor}
                accentColor = {company !== undefined && company.accentColor}
                titleColor = {company !== undefined && company.titleColor}
             >
                <CardSkillInput
                    vis={vis} 
                    val={value}
                    placeholder="search skills"
                    side={side} 
                    onChange={(e)=>{setValue(e.target.value) ; setToggle(true)}}
                    titleColor = {company !== undefined && company.titleColor}
                    accentColor = {company !== undefined && company.accentColor}
                    bottomColor = {company !== undefined && company.bottomColor}
                    onBlur={()=>{
                        window.scrollTo(0,0);
                    }}
                />

                
                {
                    toggle &&
                    
                    <CardSearchList
                        vis={vis}
                        value={value}
                        list = {cardList.length > 0 && cardList}
                        setSkill = {(target)=>{updateSelections(target)}}
                        toggle = {toggle}
                        setToggle = {setToggle}
                        indx = {indx}
                        accentColor = {company !== undefined && company.accentColor}
                    />
                    // <div>
                    // {
                    //     cardList && 

                    //     cardList.map(card =>
                    //         {       

                    //             return <CardConcealableOption 
                    //                         key={card._id} 
                    //                         vis={card.selected}
                    //                         onClick={()=>{setToggle(false)}}
                    //                     >
                    //                         {card.skill}
                    //                    </CardConcealableOption>                     
                    //         }
                    //     )       
                    // }
                    // </div>
                }
                <p onClick={()=>{setSkillPopUp(false)}}>cancel</p>
            </SearchContainer>
                


    

    )
}

export default CardOptions