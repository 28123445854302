import styled, {css} from 'styled-components'

export const SignUpContainer = styled.div`
    height: 100vh;
  
    @media (min-width: 1000px){
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const SignUpInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 12rem;
    input{
        margin-bottom: 1rem;
    }

    @media (min-width: 1000px){
        *{
            font-size: 1.5rem;
        }

        h1{
            font-size: 2rem;
        }

        margin: 0 auto;
    }
`

export const PageTitle = styled.h2`
    color: black;
    font-size: 1.5rem;
    margin-bottom: 2rem;
`

export const LoginTitle = styled.p`
    display: ${props => props.vis? "block" : "none"};
    margin: 1rem auto;
`

export const SubmitButton = styled.button`
    display: ${props => props.vis? "inline-block" : "none"};
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 0.5px solid black;
    background-image: linear-gradient(white, lightgrey);
`

export const PagePrompt = styled.p`
    color: black;
    max-width: 80%;
    text-align: center;
`


const styledInputStyles = css`
    border: 0.5px solid black;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
`

export const StyledInput = styled.input`
    ${styledInputStyles};
`
export const PasswordInput = styled.div`
    position: relative;
    i{
        position: absolute;
        right: 0;
        transform: translate(-5px, 5px);
        color: grey;
    }
    display: ${props => props.vis? "initial" : "none"};

   
`
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: ${props => props.vis? "initial" : "0px"};
    input{
        margin-bottom: 1rem;
    }
`

export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: ${props => props.vis? "initial" : "0px"};
    input{
        margin-bottom: 1rem;
    }
`