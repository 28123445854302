import { useState, useEffect } from 'react'
import {ContentContainer} from './contentStyles'

//a styled div
//children prop enables writing inner text / elements between 2 JSX tags

//USE: a standard element of the Frame schema, main portion of each frame

function Content({utils}){
    const [welcomeSelectShow, setWelcomeSelectShow] = useState(false)
    const {
        company,
        aTime,
        vis,
        display,
        position,
        children, 
        bg, 
        mt,
        mHeight
    } = utils

    useEffect(()=>{
        setTimeout(()=>{setWelcomeSelectShow(true)}, 1500)
    },[])


    return(
        <ContentContainer 
            aTime={aTime}
            vis={vis} 
            bg={bg} 
            mt={mt}
            nHeight={mHeight} 
            display={display} 
            position={position} 
            welcomeSelectShow={welcomeSelectShow}
            topColor={company !== undefined && company.topColor}
            bottomColor={company !== undefined && company.bottomColor}
            titleColor={company !== undefined && company.titleColor} 
            accentColor={company !== undefined && company.accentColor}
            auxColor={company !== undefined && company.auxColor}
        >
            {children}
        </ContentContainer>
    )
}

export default Content