import { useState } from 'react'
import {MoveContainer} from './moveStyles'

//a styled div
//message is for some sort of word display or console log
//prompt is the caption if you want to make this a button or just a captioned box
//-omit symbol if you need a prompt
//symbol is for icons if you want to make it look like a icon-button
//-omit prompt if you need a symbol
//action is for custom callbacks when clicking the component


//USE: can act as unique "back" and "forward" buttons at each Frame



function Move({utils}){
    const 
    {
      company,
      mt,
      ai,
      mr,
      promptFs,
      iFs,
      vis,
      alt_vis,
      aTime,
      message,
      prompt,
      symbol,
      action,
      order
    } = utils

    
    return(
        <MoveContainer 
          mt={mt} 
          vis={vis}
          alt_vis={alt_vis}   
          order={order}
          promptFs={promptFs}
          iFs={iFs}
          mr={mr}
          ai={ai}
          aTime = {aTime}
          // topColor={company !== undefined && company.topColor}
          // bottomColor={company !== undefined && company.bottomColor}
          // titleColor={company !== undefined && company.titleColor} 
          // accentColor={company !== undefined && company.accentColor}
        >
              <h3 onClick={action}>
                {prompt}
              </h3>
              {symbol}
        </MoveContainer>
    )
}

export default Move