import {useState, useEffect, useContext} from 'react'
import { MenuContext } from '../../utils/menuContext'
import 
{ 
    Container,
    InputPair,
    TopicOption
} 
from './assessmentUploadStyles'
import { 
    getNewCafes,
    getCurrentEmployeeAssessment,
    postAssessmentModified,
    searchEmployee
} from '../../services/dataServices'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'

const AssessmentUploadPage = () =>{
    const {admin, setAdmin, adminList, setAdminList, cafeList, setCafeList} = useContext(MenuContext)
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [topic1, setTopic1] = useState("")
    const [topic2, setTopic2] = useState("")
    const [topic3, setTopic3] = useState("")
    const [topic4, setTopic4] = useState("")
    const [topic5, setTopic5] = useState("")
    const [modelEmployee, setModelEmployee] = useState({})
    const [employee, setEmployee] = useState({})
    const [cards, setCards] = useState([])
    const [newCafes, setNewCafes] = useState([1, 2, 3, 4, 5 ])
     

    useEffect(()=>{
        async function getCafes(){
            await getNewCafes("62d47c7a36aeee14652966cd")
                    .then(async(res) => {
                        console.log("CAFES: ")
                        console.log(res)
                        setCafeList(res.data)
                        
                        await getCurrentEmployeeAssessment("6539f75d550ee9b24fa5fc5c")
                        .then(res =>{
    
                            setModelEmployee(res.data)
                            setCards(res.data.cards)
                            setIsLoading(false)
                        })
                        .catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
        }

        getCafes()
        return ()=>{}
    },[])

    console.log(cafeList)

    async function specificSubmitHandler(){
        setIsLoading(true)

        let placeholder = []

        newCafes.forEach(entry =>{
            const found = cafeList.find((element) => element._id === entry)
            placeholder.push(found)
        });
        
     
        try{
            const result = await searchEmployee(email)
            if(result){
                console.log('employee result')
                console.log(result)
                await postAssessmentModified({
                    employee_id: result.data._id,
                    cards: cards,
                    learningPath: null,
                    firstSuggested: "red",
                    secondSuggested: "blue",
                    secondScore: 13,
                    assessmentChoice:  null,
                    currentSkillsChallenges: placeholder,
                    companyId: "62d47c7a36aeee14652966cd"
                })
                    .then(res => {
                        if(res){
                            console.log('assessment result: ')
                            console.log(res)
                            setEmail("")
                            setTopic1("")
                            setTopic2("")
                            setTopic3("")
                            setTopic4("")
                            setTopic5("")
                            setNewCafes([])
                            setIsLoading(false)
                        }
                    })
                    .catch(err => alert(err))
            }
        }catch(e){
            console.log(e)
            alert(e)
        }
        
}


    if(isLoading){
        return(
            <Container>
                <Loader 
                        accentColor={"black"}  
                        iconSize={"1.5rem"}
                        mb={"0vh"}
                        mt={"5vh"}
                    />
            </Container>
        )
    }
            

        else{
            return(
            <Container>
                <h1>Assessment Upload</h1>
                <InputPair>
                    <p>Email</p>
                    <input value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                </InputPair>
                <InputPair>
                    <p>Topic 1</p>
                    <select onChange={(e)=>{
                        setNewCafes(prev =>{
                            const previous = prev
                            previous.splice(0, 1, e.target.value)
                            return previous
                        })
                    }}>
                                <option  hidden>topic 1</option>
                                
                                {
                                    cafeList.map((entry) =>{
                                        if(entry.month === 1){
                                            return(
                                                <TopicOption key={entry._id} value={entry._id}>
                                                {entry.title}
                                                </TopicOption>
                                            ) 
                                        } 
                                        else{
                                            return null
                                        }
                                    })
                                }
                    </select>
                </InputPair>
                <InputPair>
                    <p>Topic 2</p>
                    <select onChange={(e)=>{
                        console.log(e.target.value)
                        setNewCafes(prev =>{
                            const previous = prev
                            previous.splice(1, 1, e.target.value)
                            return previous
                        })
                    }}>
                                <option  hidden>topic 2</option>
                                
                                {
                                    cafeList.map((entry) =>{
                                        if(entry.month === 2){
                                            return(
                                                <TopicOption key={entry._id} value={entry._id}>
                                                {entry.title}
                                                </TopicOption>
                                            ) 
                                        } 
                                        else{
                                            return null
                                        }
                                    })
                                }
                    </select>
                </InputPair>
                <InputPair>
                    <p>Topic 3</p>
                    <select onChange={(e)=>{
                        console.log(e.target.value)
                        setNewCafes(prev =>{
                            const previous = prev
                            previous.splice(2, 1, e.target.value)
                            return previous
                        })
                    }}>
                                <option  hidden>topic 3</option>
                                
                                {
                                    cafeList.map((entry) =>{
                                        if(entry.month === 3){
                                            return(
                                                <TopicOption key={entry._id} value={entry._id}>
                                                {entry.title}
                                                </TopicOption>
                                            ) 
                                        } 
                                        else{
                                            return null
                                        }
                                    })
                                }
                    </select>
                </InputPair>
                <InputPair>
                    <p>Topic 4</p>
                    <select onChange={(e)=>{
                        console.log(e.target.value)
                        setNewCafes(prev =>{
                            const previous = prev
                            previous.splice(3, 1, e.target.value)
                            return previous
                        })
                    }}>
                                <option  hidden>topic 4</option>
                                
                                {
                                    cafeList.map((entry) =>{
                                        if(entry.month === 4){
                                            return(
                                                <TopicOption key={entry._id} value={entry._id}>
                                                {entry.title}
                                                </TopicOption>
                                            ) 
                                        } 
                                        else{
                                            return null
                                        }
                                    })
                                }
                    </select>
                </InputPair>
                <InputPair>
                    <p>Topic 5</p>
                    <select onChange={(e)=>{
                        console.log(e.target.value)
                        setNewCafes(prev =>{
                            const previous = prev
                            previous.splice(4, 1, e.target.value)
                            return previous
                        })
                    }}>
                                <option  hidden>topic 5</option>
                                
                                {
                                    cafeList.map((entry) =>{
                                        if(entry.month === 5){
                                            return(
                                                <TopicOption key={entry._id} value={entry._id}>
                                                {entry.title}
                                                </TopicOption>
                                            ) 
                                        } 
                                        else{
                                            return null
                                        }
                                    })
                                }
                    </select>
                </InputPair>
                <button onClick={(e)=>{
                    e.preventDefault()
                    specificSubmitHandler()
                }}>
                    submit
                </button>
            </Container>
            )
   

         } 
}

export default AssessmentUploadPage

