import axios from 'axios'

//'master' branch on Heroku Git (name on heroku: 'competency-cards')
const server = "https://competency-cards.herokuapp.com/"

//'tracer' branch on Heroku Git (name on heroku.com: 'shrouded-mesa-82150')
// const server = "https://shrouded-mesa-82150-c5acf27445e0.herokuapp.com/"
// const server = "http://localhost:4001/"


export const getCookie = async() =>{
    axios.get(`${server}cookie`)
    .then(result => result)
    .catch(err => console.log(err))
}



//MONGO CALLS

export const getCards = async(procedure, obj) =>{
    await axios.post(`${server}cards`, obj)
    .then(res => {
        procedure(res.data)
    })
    .catch(err=> alert(err))
}

export const getCards2 = async(obj) =>{
    return(
        await axios.post(`${server}cards`, obj)
        .then(result => result)
        .catch(err => err)
    )
}

export const getCompany = async(emailDomain) =>{
    
          return(
                await axios.get(`${server}company/email/${emailDomain}`)
                .then(res=> res)
                .catch(err=>err)
          )
        
}

export const listCompanies = async(adminId)=>{
    return(
        await axios.get(`${server}company/admin/${adminId}`)
        .then(res => res)
        .catch(err => err)
    )
}

// export const sendFirstContact = async(obj) =>{
//     return(
//         await axios.post(`${server}initialcontact`, obj)
//         .then(result => result)
//         .catch(err => err)
//     )
// }



///////////////////////////////
//// NEW CALLS
///////////////////////////////

export const serverActivate = async () =>{
    return (
        await axios.get()
        .then(res => res)
        .catch(err => err)
    )
}


export const getDate = async() =>{
    return(
        await axios.get(`${server}dates`)
        .then(result => result)
        .catch(err => err)
    )
}

// EMPLOYEES


export const checkEmployeeInfo = async(id) =>{

    return(
        await axios.get(`${server}employee/${id}`)
        .then(result => result)
        .catch(err => err)
    )
}

export const checkInitialInfo = async(email) =>{

    return(
        await axios.get(`${server}employee/${email}`)
        .then(result => result)
        .catch(err => err)
    )
}

export const searchEmployee = async(email) =>{
    return(
        await axios.get(`${server}employee/get/${email}`)
        .then(result => result)
        .catch(err => err)
    )
}

export const getAdminEmployees = async(email) =>{
  
    return(
        await axios.get(`${server}employee/mt/retrieve/${email}`)
        .then(result => result)
        .catch(err => err)
    )
}

export const transferEmployees = async(obj) =>{
  
    return(
        await axios.post(`${server}employee/mt/transfer`, obj)
        .then(result => result)
        .catch(err => err)
    )
}


export const sendInitial = async(obj) =>{
    return(
        await axios.post(`${server}employee/initial`, obj)
        .then(result => result)
        .catch(err => err)
    )
}


export const sendEmployeeInfo = async(obj) =>{
  
    return(
        await axios.post(`${server}employee/`, obj)
        .then(result => result)
        .catch(err => err)
    )
}



// export const getDBContact = async(obj) =>{
//     return(
//         await axios.post(`${server}ref`, obj)
//         .then(res => res.data)
//         .catch(err => err)
//     )
// }

///IMAGES

export const getImage = async(userId) => {
    return(
        await axios.get(`${server}images/${userId}`,)
        .then(res => res)
        .catch(err => err)
    )
}

export const getEmailLink = async(eid) => {
    return(
        await axios.get(`${server}images/emails/${eid}`,)
        .then(res => res)
        .catch(err => err)
    )
}

export const getLogo= async(cid) => {
    return(
        await axios.get(`${server}images/logos/${cid}`,)
        .then(res => res)
        .catch(err => err)
    )
}

export const imageUpload = async(id, obj) => {
    return(
        await axios.post(`${server}images/send/${id}`, obj)
        .then(res => res)
        .catch(err => err)
    )
}


export const removeImage = async(obj) => {
    return(
        await axios.delete(`${server}images/${obj.previous}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const logoUpload = async(obj) => {
    return(
        await axios.post(`${server}images/logos`, obj)
        .then(res => res)
        .catch(err => err)
    )
} 

// TERRITORIES

export const getTerritories = async(id) =>{
    return(
        await axios.get(`${server}territories/${id}`)
        .then(res => res)
        .catch(err => err)
    )
}

//ASSESSMENTS

// export const getCompletedStatus = async()=>{
//     return(
//         await axios.get(`${server}assessments/status`)
//         .then(res => res)
//         .catch(err => err)
//     )
// }

export const getPastAssessments = async(employee_id) =>{
    return(
        await axios.get(`${server}assessments/past/${employee_id}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const getCurrentEmployeeAssessment = async(employeeId) =>{
    return(
        await axios.post(`${server}assessments/employee/current`, {employeeId: employeeId})
        .then(res => res)
        .catch(err => err)
    )
}

export const getAssessmentSort = async(company_id)=>{
    return(
        await axios.get(`${server}assessments/${company_id}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const getCompletedStatus = async(company_id)=>{
    return(
        await axios.get(`${server}assessments/status/${company_id}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const postAssessment = async(obj) =>{
    return(
        await axios.post(`${server}assessments/${obj.employee_id}`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const postAssessmentModified = async(obj) =>{
    return(
        await axios.post(`${server}assessments/modified`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const postAssessmentLeaderModified = async(obj) =>{
    return(
        await axios.post(`${server}assessments/leadermodified`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

// export const confirmAssessment = async(obj) =>{
//     return(
//         await axios.post(`${server}finalcontact`, obj)
//         .then(result => result)
//         .catch(err => err)
//     )
// }

//ADMINS

export const adminCheck = async(email) => {
    return(
        await axios.get(`${server}admin/${email}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const getAdmins = async(companyId) =>{
    return(
        await axios.get(`${server}admin/cid/${companyId}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const reviseAdmin = async(id) => {
    return(
        await axios.get(`${server}admin/ver/${id}`)
        .then(res => res)
        .catch(err => err)
    )
}


export const setPass = async(obj) => {
    return(
        await axios.post(`${server}admin/signup`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const setLog = async(obj) => {
    return(
        await axios.post(`${server}admin/login`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const setNewAdminPass = async(obj) =>{
    return(
        await axios.put(`${server}admin/newpass`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const updateMainList = async(array) =>{
    return(
        await axios.put(`${server}admin/referencefile`, {documents: array})
        .then(res => res)
        .catch(err => err)
    )
}

export const getMainList = async()=>{
    return(
        await axios.get(`${server}admin/employees/list`)
        .then(res => res)
        .catch(err => err)
    )
}

//RESETS 

export const retrieveReset = async(code) => {
    return(
        await axios.get(`${server}reset/verify/${code}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const setupReset = async(obj) =>{
    //   setSent(true)
      try{
        const response = await axios.post(`${server}reset/setup`, obj)
        if(response){
          return response
        }
      }
      catch(err){
        alert("email not found")
      }
  
}

export const removeReset = async(obj) => {
    return(
        await axios.delete(`${server}reset/recycle`, {data: obj})
        .then(res => res)
        .catch(err => err)
    )
}    
  

//REPORTS

export const getBasicReport = async(email) =>{
    return(
        await axios.get(`${server}employee/mt/reports/${email}`)
        .then(res => res)
        .catch(err => err)
    )
}

export const getAdvancedReport = async(obj) =>{
    return(
        await axios.post(`${server}employee/mt/reports/nd/adv`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const singleAdmin = async(email) =>{
    return(
        await axios.post(`${server}create/one`, )
    )
}

//CAFE
 
export const getSkillsChallenges = async(companyId)=>{
    return(
        await axios.get(`${server}skillsChallenges/all/${companyId}`)
    )
}

export const getNewCafes = async(companyId)=>{
    return(
        await axios.get(`${server}cafes/all/${companyId}`)
    )
}

//LEADERS 

export const getLeaderStatus = async(obj) =>{
    return(
        await axios.post(`${server}leaders`, obj)
    )
}

//HUBSPOT

export const sendHB = async(obj) =>{
    return(
        await axios.post(`${server}hb`, obj)
        .then(result => result)
        .catch(err => err)
    )
}


///// UNMARKED


export const replaceCompletedEmployeeInfo = async(obj) =>{
    return(
        await axios.post(`${server}editcompletedemployee`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const replaceNewEmployeeInfo = async(obj) =>{

    return(
        await axios.put(`${server}employee/editemployee`, obj)
        .then(res => res)
        .catch(err => err)
    )
}

export const replacePreviousEmployeeInfo = async(obj) =>{

    return(
        await axios.put(`${server}employee/replaceemployee`, obj)
        .then(res => res)
        .catch(err => err)
    )
}


// export const confirmAssessment = async(formData) =>{
//     await axios.post('http://localhost:4001/completed', formData, {headers: {"content-Type": "multipart/form-data"}})
//     .then(result => console.log(result))
//     .catch(err => console.log(err))
// }

// export const confirmAssessment = async(formData) =>{
    
// await axios({
//   method: "post",
//   url: 'http://localhost:4001/completed',
//   data: formData,
//   headers: { 
//     Accept: "application/json",
//     "Content-Type": "multipart/form-data" },
// })
//   .then(function (response) {
//     //handle success
//     console.log(response);
//   })
//   .catch(function (response) {
//     //handle error
//     console.log(response);
//   });
// }



//HUBSPOT CALLS

export const postUser = async(obj) =>{
   
    return(
     await axios.post(`${server}newentry`, obj)
     .then(result => result.data.id)
     .catch(err=> err)

    )
}

export const setLearningPath = async(email, mPath, sPath)=>{
    if(mPath === ""){
       return(
        await axios.post(`${server}hbupdatelpath`, {email: email, learningPath: sPath})
        .then(result => result)
        .catch(err => console.log(err))
       )
    }

    else

   return(
    await axios.post(`${server}hbupdatelpath`, {email: email, learningPath: mPath})
    .then(result => result)
    .catch(err => console.log(err))
   )
}