import styled from 'styled-components'


export const SubmitChoicesContainer = styled.div`
    /* display: ${props => props.vis? "block" : "none"}; */
    position: absolute;
    min-height: 20%;
     top: 50%; 
    left: 50%;
    transform: ${props => props.move? `translate(-50%, 75%)` : `translate(200%, 75%)`};
    transition: transform 1s ease;
    width: 65%;

`
export const ChoiceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${props => props.bottomColor};
    *{font-size: 0.7rem;}
    border: ${props => `2px solid ${props.bottomColor}`};
    border-radius: 5px;
`

export const ChoiceLabel = styled.div`
    margin: 0;
    padding: 0.25rem;
    `

export const ChoiceCheck = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.25rem;
    width: 30%;
    color: ${props => props.fc};
    font-weight: ${props => props.fw};
    border-left: ${props => `2px solid ${props.bottomColor}`};
    background: ${props => props.bg};
`