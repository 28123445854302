import {Link} from 'react-router-dom'

export default function Error(){
    return(
        <>
            <div style={{position: "absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)"}}>
                <h2>404 ERROR. This Page doesn't Exist</h2>
                <Link style={{fontWeight: "700"}} to="/">Back to Homepage</Link>
            </div>
        </>
    )
}