import {LastModalContainer, ModalWindow, ModalTitleContent, SubmitButton} from './lastModalStyles.js'
import {useState, useContext, useEffect } from 'react'
import { MenuContext } from '../../utils/menuContext.js'
import { getImageLink, postAssessment, sendHB } from '../../services/dataServices.js'
import GraphicCard from '../GraphicCard/GraphicCard.js'
import SubmitChoices from '../SubmitChoices/SubmitChoices.js'
import styled from 'styled-components'
import { sendEmail } from '../../services/mailServices.js'
import axios from 'axios'


const LastModal = ({company}) =>{
    const [windowMove, setWindowMove] = useState(false)
    const [cardMove, setCardMove] = useState(false)
    const [choicesMove, setChoicesMove] = useState(false)
    const [choicesOn, setChoicesOn] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [learningPathType, setLearningPathType] = useState("Suggested")
    const {
        learningPath, 
        modalActive, 
        choiceSelection, 
        employeeId, 
        tenArray,
        finalResults,
        setCurrentFrame,
        firstName,
        imageEndpoint,
        lastName,
        email,
        managerEmail,
        employeeTerritory,
        industryYears,
        pathDateRange,
        phoneNumber,
        contact
    } = useContext(MenuContext)
    // const {sent, setSent} = useState(false)
    // const [emailText ,setEmailText] = useState("")
    
    useEffect(()=>{
    
        if(!loaded){
         if(modalActive){
             setChoicesOn(true)
             setLoaded(true)
             setTimeout(()=>{setWindowMove(true)}, 500)
             setTimeout(()=>{setCardMove(true)}, 1500)
             setTimeout(()=>{setChoicesMove(true)}, 2500)
         }
        }
        if(loaded){
         if(!modalActive){
             setWindowMove(false)
             setCardMove(false)
             setChoicesMove(false)
             setLoaded(false)
         }
        }
     }, [loaded, modalActive])

     const determineHeadline = (string) =>{
        let variable

        switch(string){
            case "1st" : variable = "Suggested"
            break
            case "2nd" : variable = "Suggested"
            break
            case "manual" : variable = "Chosen"
            break
            default: variable = undefined
        }
        return variable
    }


     const ModalTitle = () =>{
        

       
        return(
            <ModalTitleContent>
                {
                     choiceSelection!== undefined &&
                    `Your ${determineHeadline(choiceSelection)} Learning Path`
                }
            </ModalTitleContent>
        )
     }



    return(
        <LastModalContainer>
            <ModalWindow 
                move={windowMove}
                titleColor={company !== undefined && company.titleColor}
                bottomColor={company !== undefined && company.bottomColor}
            >
                <ModalTitle />
            </ModalWindow>
            <GraphicCard move={cardMove} company={company} path={choiceSelection}/>
            <SubmitChoices 
                move={choicesMove}
                bottomColor={company !== undefined && company.bottomColor}
                accentColor={company !== undefined && company.accentColor}
            />
            <SubmitButton vis={learningPath !== undefined} accentColor={company !== undefined && company.accentColor}>
                <button disabled={learningPath === undefined}
                    onClick={async()=>{
                        // await getImageLink(contact.employee_id)
                        // .then(async(res) =>{
                        //     const result = res.data
                        //     if(typeof result === "string"){
                        //         //you might need make a conditional based on createNewAssessment
                        //         //and a different dataService to make that call, instead of 
                        //         //postAssessment (which will probably replace the existing one)
                        //         await postAssessment({
                        //             employee_id: employeeId,
                        //             cards: tenArray,
                        //             learningPath: learningPath,
                        //             firstSuggested: finalResults[0].firstColor,
                        //             firstScore: finalResults[0].firstPlace,
                        //             secondSuggested: finalResults[1].secondColor,
                        //             secondScore: finalResults[1].secondPlace,
                        //             assessmentChoice: choiceSelection,
                        //             companyId: company._id
                                   
                        //         }, createNewAssessment)
                        //             .then(res => {
                        //                 if(res){
                        //                     setCurrentFrame(prev => prev+1)
                        //                     sendEmail({
                        //                         firstName: firstName,
                        //                         lastName: lastName,
                        //                         email: email,
                        //                         managerEmail : managerEmail,
                        //                         employeeTerritory: employeeTerritory,
                        //                         industryYears : industryYears,
                        //                         imageEndpoint: result,
                        //                         tenArray: tenArray,
                        //                         pathDateRange : pathDateRange,
                        //                         learningPath: learningPath,
                        //                         company: company.name
                        //                     })
                        //                 }
                        //             })
                        //             .catch(err => alert("alpha"))

                        //         sendHB({
                        //             firstName: firstName,
                        //             lastName: lastName,
                        //             email: email,
                        //             company: company.name,
                        //             learningPath: learningPath,
                        //             industryYears : industryYears,
                        //             phoneNumber: phoneNumber
                        //         })
                        //             .then(res =>{})
                        //             .catch(err => alert("beta"))
                                
                        //         return
                        //     }
                        //     else{
                        //         alert("Error: Unable to retrieve email records")
                        //         return
                        //     }
                        // })
                        // .catch(err => console.log(err))
                        
                        await postAssessment({
                            employee_id: employeeId,
                            cards: tenArray,
                            learningPath: learningPath,
                            firstSuggested: finalResults[0].firstColor,
                            firstScore: finalResults[0].firstPlace,
                            secondSuggested: finalResults[1].secondColor,
                            secondScore: finalResults[1].secondPlace,
                            assessmentChoice: choiceSelection,
                            companyId: company._id
                        })
                            .then(res => {
                                if(res){
                                    setCurrentFrame(prev => prev+1)
                                    sendEmail({
                                        firstName: firstName,
                                        lastName: lastName,
                                        email: email,
                                        managerEmail : managerEmail,
                                        employeeTerritory: employeeTerritory,
                                        industryYears : industryYears,
                                        imageEndpoint: "null",
                                        tenArray: tenArray,
                                        pathDateRange : pathDateRange,
                                        learningPath: learningPath,
                                        company: company.name
                                    })
                                }
                            })
                            .catch(err => alert("alpha"))

                        sendHB({
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            company: company.name,
                            learningPath: learningPath,
                            industryYears : industryYears,
                            phoneNumber: phoneNumber
                        })
                            .then(res =>{})
                            .catch(err => alert("beta"))
                    }}
                >
                    submit
                </button>
            </SubmitButton>

        </LastModalContainer>
    )
}

export default LastModal