import { useState } from "react"
import 
{ 
    ForgotContainer,
    InputContainer,
    PageTitle,
    StyledInput,
    PasswordInput,
    PasswordContainer,
    PagePrompt,
    SubmitButton,
    InstructionMessage,
    CodeContainer,
    NewInnerContainer
 } from "./adminForgotPageStyles"
 import {
    adminCheck,
    retrieveReset,
    setNewAdminPass,
    removeReset,
    setPass
} from '../../services/dataServices'
 import { useNavigate, Link } from "react-router-dom"

const ForgotPasswordPage = () =>{
    const [admin, setAdmin] = useState("")
    const [emailButtonVis, setEmailButtonVis] = useState(true)
    const [promptMessage, setPromptMessage] = useState("")
    const [passwordRequired, setPasswordRequired] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [verified, setVerified] = useState(false)
    const [code6, setCode6] = useState("")
    const [password1, setPassword1] = useState("")
    const [passwordType1, setPasswordType1] = useState("password")
    const [password2, setPassword2] = useState("")
    const [passwordType2, setPasswordType2] = useState("password")
    const [lastPrompt, setLastPrompt] = useState("")
    const redirect = useNavigate()
    
    const passwordChecker = (password) =>{
        if(password?.includes(" ")){
            return(`No spaces allowed in password \n Check email entry: ${password}`)
        }
        
        if(password?.length < 8){
            return(`Password must be longer than 7 characters \n Check entry: ${password}`)
        }

        // if(checkIfOnlyLetters(password)){
        //     return('Password must include at least 1 special character eg: !$@%^*#&+')
        // }

        if(checkIfNoNumbers(password)){
            return('Password must include at least 1 number character [0-9]')
        }

        if(checkIfNoSpecial(password)){
            return("Password must include at least 1 special character eg: !$@%^*#&+")
        }
 
        return true
    }

    const checkIfOnlyLetters = (field) =>{
        if(/^[a-zA-Z ]+$/.test(field)){
            return true
        }
        else return false
    }

    const checkIfNoNumbers = (field) =>{
        if(!/\d/.test(field)){
            return true
        }
        else return false
        
    }

    const checkIfNoSpecial = (field) =>{
        if(!/[ !$@%^*#&+]/.test(field)){
            return true
        }
        else return false
        
    }

    
    return(
        <ForgotContainer>
            <NewInnerContainer>
                <CodeContainer vis={!verified }>
                    <PageTitle>Enter Code</PageTitle>
                    <StyledInput type="text"  maxLength={6} placeholder="6-digit code" 
                            onChange={(e)=>{
                                setCode6((e.target.value).toString())
                            }}
                        />
    
                        <SubmitButton vis={promptMessage === ""} 
                            onClick={async()=>{
                                if(code6 === ""){
                                    alert(`code cannot be blank`)
                                    return
                                }
                                if(code6.match(".*[a-zA-Z].*")){
                                    alert('code cannot contain letter')
                                    return
                                }
                                
                                if(code6.length <6){
                                    alert("code must be 6 digits long")
                                    return
                                }
                                else{
                                    await retrieveReset(code6)
                                    .then(async(res) => {
                                    
                                        const result = res.data
                                        if(result._id === undefined){
                                            alert('invalid code')
                                            return
                                        }
                                        else{
                                            setAdmin(result)
                                            setVerified(true)
                                        }
                                        
                                    })
                                    .catch(err => alert('invalid code'))
                                }
                            }}
                        >
                            submit code
                        </SubmitButton>

                        <InstructionMessage vis={true}>
                            Enter the 6 digit verification code provided in the email.
                        </InstructionMessage>
                </CodeContainer>           


                <PasswordContainer vis={verified && !submitted }>
                    <PageTitle>Reset Password</PageTitle>

                    <PasswordInput>
                        <StyledInput type={passwordType1} placeholder='enter new password' value={password1} onChange={(e)=>{
                            setPassword1(e.target.value)
                        }}/>
                        {
                            passwordType1 === "password" && 
                            <i className="fa-solid fa-eye" onClick={()=>{
                                setPasswordType1("text")
                            }}></i>
                        }
                        {
                            passwordType1 === "text" && 
                            <i className="fa-solid fa-eye-slash" onClick={()=>{
                                setPasswordType1("password")
                            }}></i>
                        }
                    </PasswordInput>    

                    <PasswordInput>
                        <StyledInput  type={passwordType2} placeholder='confirm password' value={password2} onChange={(e)=>{
                            setPassword2(e.target.value)
                        }}/>
                        {
                            passwordType2 === "password" && 
                            <i className="fa-solid fa-eye" onClick={()=>{
                                setPasswordType2("text")
                            }}></i>
                        }
                        {
                            passwordType2 === "text" && 
                            <i className="fa-solid fa-eye-slash" onClick={()=>{
                                setPasswordType2("password")
                            }}></i>
                        }
                    </PasswordInput> 
                    
                    <SubmitButton vis={promptMessage === ""} 
                        onClick={async()=>{

                            if(passwordChecker(password1) !== true){
                                alert(passwordChecker(password1))
                                return
                            }
                            if(passwordChecker(password2) !== true){
                                alert(passwordChecker(password2))
                                return
                            }
                            
                            if(password1 !== password2){
                                alert("passwords do not match")
                                return
                            }
                            else{
                                await setNewAdminPass({email: admin.email, password: password1})
                                .then(async(res) => {
                                    if(res){
                                    setSubmitted(true)
                                    setLastPrompt(true)
                                    //   setTimeout(()=>{
                                    //     redirect("/login")
                                    //   }, 2000)
                                    await removeReset({code: code6})
                                    .then(res => {if(res){setTimeout(()=>{redirect('/login')},2000)}})
                                    .catch(err => console.log("NewPasswordPage line 184: ", err))
                                    }
                                })
                                .catch(err => alert(err))
                            }
                        }}
                    >
                        submit password
                    </SubmitButton>

                    <InstructionMessage vis={true}>
                        Password must be longer than 7 characters, include at least 1 number, and 1 special character (eg: !$@%^*#&)
                    </InstructionMessage>
                </PasswordContainer>
                
                {
                    lastPrompt && 
                    <p>Password change success! Redirecting to log in...</p>
                }
            </NewInnerContainer>
            
        </ForgotContainer>
    )
}

export default ForgotPasswordPage