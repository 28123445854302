import styled from 'styled-components'

const logoHighlight = (highlight) =>{
    let result
    switch(highlight){
        case "lighten" : result = "rgba(255, 255, 255, 0.20)"
        break
        case "darken" : result = "rgba(0, 0, 0, 0.15)"
        break
        case "none" : result = "initial"
        break
        default: return "initial"
    }

    return result
}

const colorSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "blue": val = `dodgerblue`
        break
        case "red": val = `firebrick`
        break
        case "orange": val = `tomato`
        break
        case "green": val = `forestgreen`
        break
        case "yellow": val = `gold`
        break
        default: val = "white"
    }
    return val
}

const fontSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "yellow": val = `black`
        break
        default: val = "white"
    }
    return val
}

export const CardContainer = styled.div`
    position: absolute;
    background: white;
    height: 50%;
    width: 40%;
    top: 50%; 
    left: 50%;
    transform: ${props => props.move? `translate(-50%, -76%)` : `translate(-50%, 250%)`};
    transition: transform 1.25s ease;
    border-radius: 10px;
`

export const CardTopContent = styled.div`
    color: ${props=>props.companyCardFontColor};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    background: ${props => logoHighlight(props.highlight)};
    img{
        max-height: 75%;
    }
`

export const CardTop = styled.div`
    height: 45%;
    background: ${props=>props.companyCardColor};
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-top: 0.25px solid lightgrey;
    border-bottom: 0.25px solid lightgray;
    
`

export const CardBottom = styled.div`
    height: 53%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    background: ${props => colorSwapper(props.bg)};
    color: ${props => fontSwapper(props.bg)};
`

export const LearningPathColor = styled.h4`
    color: ${props => fontSwapper(props.bg)};
`

export const CardDivider = styled.div`
    height: 2%;
    background: white;
`

export const ManualSelect = styled.select`

`

export const ManualOptionContainer = styled.option`

`

