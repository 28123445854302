import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'
import AssessmentPage from './pages/AssessmentPage'
import Error from './pages/Error'
import CardPage from './pages/CardPage'
import ContactPage from './pages/ContactPage'
import './styles/scroller.css'
import {MenuContext} from './utils/menuContext'
import { useContext } from 'react'
import RetakePage from './pages/RetakePage'
import ConfirmationPage from './pages/ConfirmationPage'
import MainPage from './pages/Main/MainPage'
import EditConfirmPage from './pages/EditConfirmPage/EditConfirmPage'
import AdminPage from './pages/AdminPage/AdminPage'
import AdminLoginPage from './pages/AdminLoginPage/AdminLoginPage'
import AdminSignUpPage from './pages/AdminSignUpPage/AdminSignUpPage'
import ForgotPasswordPage from './pages/AdminForgotPages/ForgotPasswordPage'
import NewPasswordPage from './pages/AdminForgotPages/NewPasswordPage'
import AboutPage from './pages/AboutPage/AboutPage'
import AssessmentUploadPage from './pages/assessmentUpload/AssessmentUploadPage'
import LeaderUpdate from './pages/assessmentUpload/LeaderUpdate'

function App() {
  const {setCartPanelActive} = useContext(MenuContext)

  
  return (
  
    <BrowserRouter >
      {/* <ScrollToTop/>
      <SingleItem/>
      <ShoppingCart/> */}
      {/* <NavBar>
          <LogoHolder>
            <img src="./images/lotus-symbol-white.png" alt="our lotus logo symbol"/>
            <h3>Lotus Cafe</h3> 
          </LogoHolder>
          <NavTray>
            <Link to="/">home</Link>
            <Link to="/menu">menu</Link>
            <i className="fa-solid fa-cart-shopping" onClick={()=>{setCartPanelActive(true)}}></i>
          </NavTray>
      </NavBar>
      <FakeNavBar/>  */}
      {/*Imitation navbar, to sit 'behind' the real on and help initial positioning */}
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/retake" element={<RetakePage/>}/>
            <Route path="/assess" element={<AssessmentPage/>}/>
            <Route path="/leaderupdate" element={<LeaderUpdate/>}/>
            <Route path="/assessment/upload" element={<AssessmentUploadPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/confirm" element={<CardPage/>}/>
            <Route path="/complete" element={<ConfirmationPage/>}/>
            <Route path="/contactinfochange" element={<EditConfirmPage/>}/>
            <Route path="/login" element={<AdminLoginPage/>}/>
            <Route path="/signup" element={<AdminSignUpPage/>}/>
            <Route path="/admin" element={<AdminPage/>}/>
            <Route path="/fp" element={<ForgotPasswordPage/>}/>
            <Route path="/pr" element={<NewPasswordPage/>}/>
            <Route path="*" element={<Error/>}/>
        </Routes>  
    </BrowserRouter>
  );
}

export default App;
