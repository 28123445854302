import {useContext} from 'react'
import Frame from '../Frame/Frame'
import {MenuContext} from '../../utils/menuContext'
import {CarouselContainer} from './carouselStyles'

//a styled div
//children prop enables writing inner text / elements between 2 JSX tags
//houses frames from the "frameElements" array and displays them based on iterations through
//the array

//USE: This acts as the static portion of the window that anchors the moving frames
function Carousel(){
    const {frameElements, 
        currentFrame, 
        alteredFrames, 
        clientInfoCheck, 
        editOnlyFrames,
        editOnlySwitch,
        setPreviousClientInfo
    } = useContext(MenuContext)

   
    return(
        <CarouselContainer isOnScreen={currentFrame}>
            {   

                clientInfoCheck &&

                clientInfoCheck === true ? 
    
                alteredFrames.map(entry =>
                    <Frame key={alteredFrames.indexOf(entry)} info={entry} vis={currentFrame === alteredFrames.indexOf(entry)}/>   
                ) 
      
                : 
                
                editOnlySwitch &&
                
                editOnlySwitch === true?

                    editOnlyFrames.map(entry =>
                        <Frame key={editOnlyFrames.indexOf(entry)} info={entry} vis={currentFrame === editOnlyFrames.indexOf(entry)}/>   
                    ) 
                    :
                    
                    frameElements.map(entry =>
                    <Frame key={frameElements.indexOf(entry)} info={entry} vis={currentFrame === frameElements.indexOf(entry)}/>   
                    )
            
        }


        </CarouselContainer>
    )
}

export default Carousel