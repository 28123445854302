import styled, {css} from 'styled-components'

export const OverContainer = styled.div`
    position: relative;
`

export const CompetencyContainer = styled.div`
    /* width: 95%; */
    margin: 0 auto;
    padding-top: 1rem;
    margin-bottom: 2rem;
    /* @media (min-width: 700px){
        width: 70%;
    }

    @media (min-width: 1200px){
        width: 60%;
    } */

    
`

// const valuePosition = (value) =>{
//     let val
//     switch(value){
//         case 1: val = "50%"
//         break
//         case 2: val = "25%"
//         break
//         case 3: val = "0%"
//         break
//         case 4: val = "-25%"
//         break
//         default: val = "-25%"
//     }

//     return val
// }

// const colorSwapper = (propColor) =>{
//     let val
//     switch(propColor){
//         case "blue": val = `linear-gradient(to right, dodgerblue 90%, #1769bd)`
//         break
//         case "red": val = `linear-gradient(to right, crimson 90%, #a10b26)`
//         break
//         case "orange": val = `tomato`
//         break
//         case "green": val = `forestgreen`
//         break
//         case "yellow": val = `gold`
//         break
//         default: val = "white"
//     }
//     return val
// }



// // const determineOrientation = (value) =>{
// //     let val
// //     switch(value){
// //         case 4: val = "row-reverse"
// //         break
// //         case 2: val = "row-reverse"
// //         break
// //         case 3: val = "row"
// //         break
// //         case 4: val = "row"
// //         break
// //         default : val = "row"
// //     }

// //     return val
// // }

// export const CardContainer = styled.div`
//     overflow-x: hidden;
// `

// export const CardPair = styled.div`
//     position: absolute;
//     left: ${props => props.val && valuePosition(props.val)};
//     top: 0; 
//     bottom: 0; 
//     transition: left 0.4s ease;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: ${props => props.val < 3? "row-reverse" : "row"};
//     width: 75%;
// `

// export const Card = styled.div`
//     background: ${props => props.bg_Color && colorSwapper(props.bg_Color)};
//     color: white;
//     width: 33%;
//     border-radius: 10px;
//     border: 1px solid black;
//     box-shadow: 0px 0px 10px grey;
//     /* margin-top: auto; 
//     margin-bottom: auto;  */
//     min-height: 100px;
    
    
//     p, h4{
//         margin: 0;
//         padding: 0;
//         font-size: 0.7rem;
//     }
//     p{
//         font-weight: 400;
//     }
//     h4{
//         padding: 0.5rem 0;
//         text-align: center;
//         cursor: pointer;
//     }
//     h4:first-of-type{
//         font-size: 0.6rem;
//         text-transform: uppercase;
//     }
// `

export const CardDesc = styled.div`
    background-image: linear-gradient(to right, aliceblue 90%, silver);
    color: black;
    min-height: 75px;
    padding: 0 0.25rem;


`


export const SkillReselect = styled.div`
       width: 33%;
`

export const CardTray = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    min-height: 145px;
    position: relative;
    justify-content: space-around;
`

export const GameChangerList = styled.div`

`

export const CompetencyList = styled.div`
    margin-bottom: 2rem;
`

export const cardSlotStyle = css`
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid ${props => props.accentColor};
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
    p{
        writing-mode: vertical-rl;
        text-orientation: upright;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        font-weight: 400;
        /* text-shadow: 0px 0px 2px lightgrey; */
        color: ${props => props.accentColor};
    }
    i{
        cursor: pointer;
        color: ${props => props.accentColor};
        text-shadow: ${props => `0px 0px 2px ${props.bottomColor}`}
        
    }
`

export const CardSlot1 = styled.div`
    ${cardSlotStyle};    
    p{
        font-size: 0.6rem;
        font-weight: 500;
    }

    i{
      position: absolute;
      left: 0.5rem;
      /* text-shadow: 0px 0px 2px black; */
      font-size: 1.1rem;  
    }
`

export const CardSlot2 = styled.div`
    ${cardSlotStyle};
    p{
        font-size: 0.55rem;
        font-weight: 500;
    }
`

export const CardSlot3 = styled.div`
    ${cardSlotStyle};
    p{
        font-size: 0.7rem;
    }
`

export const CardSlot4 = styled.div`
    ${cardSlotStyle};
    
    i{
      position: absolute;
      right: 0.5rem;
      /* text-shadow: 0px 0px 3px grey; */
      font-size: 1.1rem;  
    }
`
export const CardForm = styled.div`
   
`

const cardSelectStyles = css`
    display: block;
    @media (max-width: 300px){
        font-size: 0.6rem;
        padding: 0;
    }
    cursor: pointer;
    padding: 0.25rem 0rem;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 0.7rem;
    font-weight: 700;
    background-image: linear-gradient( azure , #11898A) ;
    margin: 0;

    @media(min-width: 650px){
        font-size: 0.85rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.3rem;

    }
`
export const CardSkillSelect = styled.select`
    
    ${cardSelectStyles};
    border: ${props => props.vis? "1px solid black" : "none"};
    width: ${props => props.vis? "100%" : "0px"};
    transition: width 0.5s ease;
    
`

export const ResultsContainer = styled.div`
    display: ${props => props.vis? "block": "none"};
    position: absolute; 
    background: rgba(0,0,0,0.5); 
    height: 100vh;
    width: 100vw; 
    z-index: 2;
`
export const ResultsModal = styled.div`
    /* position: absolute; */
    box-shadow: 0px 0px 10px black;
    background-image: linear-gradient(to top, #11898A, white);
    min-height: 50%;
    width: 75%;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    border-radius: 10px;
    overflow-x: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    padding: 1rem;
    opacity: ${props => props.expand? "0" : "1"};
    transition: all 1s ease;
`

export const ResultsCard = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.bg_color};
    border: 1px solid grey;
    width: 125px;
    min-height: 175px;
    border-radius: 10px;
    transform: ${props => props.status? "translateX(0rem) rotateY(0deg)" : "translateX(-30rem) rotateY(-359deg)" };
    transition: 0.75s ease;
    animation: ${props => props.anim? "spin 5s infinite linear" : "none"};
    @keyframes spin{
        0%{
            transform: rotateY(0deg); 
        }
        100%{
            transform: rotateY(359deg);
        }
        

        
    }

    div{
        animation: ${props => props.anim? "fadeColor 5s infinite linear" : "none"};

        @keyframes fadeColor{
            0%{
                color: black;
                background: white;
            }

            24%{
                color: black;
                background: white;
            }

            25%{
                color: transparent;
                background: transparent;
            }


            75%{
                color: transparent;
                background: transparent;
            }

            80%{
                color: black;
                background: white;
            }

            100%{
                color: black;
                background: white;
            }

            
        }
       
    }

   
    margin-bottom: 1rem;
    p{
        margin: 0;
        padding: 0;
    }


   
`

export const PathTip = styled.p`
    font-weight: 400;
`


export const LearningPathSelect = styled.select`
    cursor: pointer;
    background: transparent;
    border: ${props => props.expand? "none" : "1px solid black"};
    border-radius: 3px;
    margin-bottom: 1rem;
    width: ${props => props.expand? "0px" : "initial"};
    transition: all 1s ease;
    option{
        background: rgba(129,192,194,0.6);
        color: black;
        
    }
`

export const ConfirmationButton = styled.button`
    cursor: pointer;
    padding: 0.25rem 1rem;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 700;
    background-image: linear-gradient( azure , #11898A) ;
    margin: 0;
`


export const CardSubmitButton = styled.button`
    cursor: pointer;
    display: block;
    transition: opacity 0.25s ease;
    background: none;
    border: 2px solid black;
    border-radius: 5px;
    width: 100px;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    h4{
        margin: 0;
        padding: 0;
    }

    &:disabled{
        cursor: initial;
    }
`