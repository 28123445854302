import {GreetingContainer} from './greetingStyles'

//a styled H1
//children prop enables writing inner text / elements between 2 JSX tags
//fs is font-size for outside control
//mt is margin top for outside control

//USE: a standard element of the Frame schema, indicates the current Frame function

function Greeting({utils}){
    
    const {
        aTime,
        vis,
        fs,
        mt, 
        message,
        translate
    } = utils

    // company !== undefined && console.log(company.mainColor)
    return(
        <GreetingContainer 
            aTime={aTime}
            vis={vis} 
            fs={fs} 
            mt={mt} 
            translate={translate}
        >
            {message}
        </GreetingContainer>
    )
}

export default Greeting