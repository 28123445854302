import { useNavigate } from "react-router-dom"
import {useState, useEffect, useContext} from 'react'
import {MenuContext} from '../utils/menuContext'
import {RetakeContainer} from '../styles/retakeStyles'
// import { getDBContact } from "../services/dataServices"

function RetakePage(){
    const redirect = useNavigate()
    const {contact, setContact, previousPath, setPreviousPath} = useContext(MenuContext)
    useEffect(()=>{
       if(localStorage.getItem("contact")){
        setContact(JSON.parse(localStorage.getItem("contact")))
       }
        
    },[])

    console.log(previousPath)

    // const continueOn = async() =>{
    //     getDBContact(contact)
    //     .then(res => {
    //         const placeholder = res
    //         setPreviousPath(placeholder.assessmentImage)
    //     })
    //     .catch(err => alert(err))
    //     redirect("/assess")
    // }

    return(
        <RetakeContainer>
            <h3>It appears we already have your contact information on file</h3>
        
            <ul style={{fontWeight: "400"}}>
                <li>
                    If you have received an email, your assessment was completed. Continuing from this pointing
                    will overwrite your completed assessment.
                </li>
                <li>
                Close the page if you do not wish to retake your assessment.
                </li>
                <li>
                    If you did not receive an email, click on "continue" to
                    complete the proccess.

                </li>
            </ul>

            {/* <button onClick={continueOn}>
                Continue
            </button> */}
        </RetakeContainer>
    )
}

export default RetakePage