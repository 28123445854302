import styled from 'styled-components'


export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

   
        input::-webkit-input-placeholder {
            font-size: 0.65rem;
            
        }
    
`

export const StyledInput = styled.input`
    max-width: 100%;
    padding: 0.2rem;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 0.65rem;
`

export const StyledList = styled.div`
    position: absolute;
    top: 1.3rem;
    left: 0;
    border: 1px solid #232323;
    border-radius: 5px;
    background: #232323;
    padding: 0.25rem;

`

export const StyledItem = styled.div`
    padding: 0.25rem;
    color: ${props => props.danger || "#fff"};
    border-radius: 10px;
    font-size: 0.65rem;

    &:hover{
        background: ${props => props.danger? "transparent" : "#1bb953"};
        cursor: ${props => props.danger || "pointer"};
    }
`