import {ReviewScrollContainer} from './reviewScrollStyles'


function ReviewScroll({children, accentColor}){
    return(
        <ReviewScrollContainer accentColor={accentColor}>
            {children}
        </ReviewScrollContainer>
    )
}

export default ReviewScroll