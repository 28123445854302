import { useState, useEffect, useContext } from 'react'
import { MenuContext } from '../../utils/menuContext'
import {FirstEmailContainer} from './firstEmailStyles'

function FirstEmail (props){
    const {email, setEmail, company, stringFormatter} = useContext(MenuContext)
    const {aTime, vis} = props

    // const unfocused = () =>{
    //     console.log("it works")
    // }

    return(
        <FirstEmailContainer
            aTime={aTime}
            vis={vis}
            accentColor={company !== undefined && company.accentColor} 
            bottomcolor= {company !== undefined && company.bottomColor} 
        >
            <input type="email"  value={email} 
            onChange={e =>{
                setEmail(e.target.value)
            }}
            onBlur={()=>{
                window.scrollTo(0,0);
            }}
            />
            
        </FirstEmailContainer>
    )
}

export default FirstEmail