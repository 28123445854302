import {useEffect,useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import {MenuContext} from '../utils/menuContext'
import {
    // getDBContact, 
    removeImage} from '../services/dataServices'
import {ContactHeader} from '../styles/contactInfoStyles'
import {Logo,Instructions} from '../styles/assessStyles'
import {
    CompetencyContainer,
    OverContainer
} from '../styles/cardPageStyles'
import 
{ 
    ConfirmationContainer, 
    ImageConfirm 
} 
from '../styles/confirmationStyles'
import { sendEmail } from '../services/mailServices'
import ImageConComponent from '../components/ImageConComponent'
import CompetencyBoard from '../components/CompetencyBoard'



const ConfirmationPage = () =>{
    const redirect = useNavigate()
    const [endpoint, setEndPoint] = useState(null)
    const [prevPicture, setPrevPicture] = useState(null)
    const {contact, setResultsActive, cardList, setCardList, setTenArray, tenArray} = useContext(MenuContext)
   
    useEffect(()=>{
        if(!localStorage.getItem("complete")){
            redirect("/")
        }
        if(localStorage.getItem("list")){
            setTenArray(JSON.parse(localStorage.getItem("list")).array)
            setCardList(JSON.parse(localStorage.getItem("list")).cards)
        }
        
        // setResultsActive(false)
       
    },[])



    // useEffect(()=>{
    //     if(contact !== null){
    //          getDBContact(contact)
    //         .then(res => {
    //             setPrevPicture(res.assessmentImage)

    //             setEndPoint(res.assessmentImage)
    //         })
    //         .catch(err => alert(err))
    //     }
    // },[])

    


    const finalSubmit = ()=>{
        // sendEmail({
        //     recipient: contact.email,
        //     text: "Open to view your confirmation",
        //     html: `<html lang="en">
        //     <head>
        //       <meta charset="UTF-8" />
        //       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        //       <title>Document</title>
        //     <style>
        //         body {background-color: powderblue;}
        //         h1   {color: blue;}
        //         p    {color: red;}
        //     </style>
        //     </head>
        //     <body>
        //       <div>
        //         <h1>${contact.firstName}, good job on completing your Competency Assessment</h1>
        //         <div>
        //           <h2>Your Results</h2>
        //           <p>Your chosen learning path is: ${manualPath ===""? suggestedPath : manualPath}</p>
        //         </div>
        //       </div>
        //     </body>
        //   </html>`
        // })
        // sendEmail({
        //     recipient: contact.managerEmail,
        //     text: "Your employee has completed their assessment",
        //     html: `<html lang="en">
        //     <head>
        //       <meta charset="UTF-8" />
        //       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        //       <title>Document</title>
        //     <style>
        //         body {background-color: powderblue;}
        //         h1   {color: blue;}
        //         p    {color: red;}
        //     </style>
        //     </head>
        //     <body>
        //       <div>
        //         <h1>${contact.firstName}'s has completed their Competency Assessment</h1>
        //         <div>
        //           <h2>Results</h2>
        //           <p>Your employee's learning path is: ${manualPath ===""? suggestedPath : manualPath}</p>
        //         </div>
        //       </div>
        //     </body>
        //   </html>`
        // })
    }

   const erasePhoto = () =>{
        removeImage({previous: prevPicture})
   }

    return(
        <div>
            <h1>Assessment Complete!</h1>
            <h2>you will be receving an email shortly with your results</h2>
        </div>
        // <ConfirmationContainer>
             
        //     <ContactHeader>
        //         <Logo />
        //         <h1>Confirmation</h1>
        //     </ContactHeader>
            
        //     <Instructions style={{fontWeight: "400", textAlign: "center"}}>Verify all information before submitting</Instructions>
            
        //     <ImageConComponent end_={endpoint} prev={erasePhoto}/>
            
        //     <CompetencyBoard />

        //     {/* <ConfirmCompetencyBoard/> */}

        //     {/* <button onClick={()=> {
        //         localStorage.removeItem("list")
        //         localStorage.removeItem("complete")
        //         localStorage.removeItem("contact")
        //     }}>push to generate image</button>
        //      */}
        // </ConfirmationContainer>
     
    )
}

export default ConfirmationPage