import styled from "styled-components"

export const PopUpContainer = styled.div`
 
`

export const PopUpInner = styled.div`
    display: ${props => props.vis? "flex" : `none`};
    position: absolute;
    background: rgba(0,0,0, 0.75);
    top: 0vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    flex-direction: column;
    padding-top: 7vh;

    i{
       color: white;
       font-size: 2rem;
       margin: 0 auto;
    }
    
`

