import styled, {css} from 'styled-components'

const instructionStyles = css`
    color: ${props => props.titleColor};
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 500;
    /* margin-bottom: ${props =>props.mb? `${props.mb}vh` : `2vh`}; */
    text-align: center;
    opacity: ${props => props.vis? "1" : "0"};
    transition: all ${props => `${props.aTime}s`} ease;
    color: ${props => props.auxColor? props.auxColor : props.titleColor};
    
    @media (min-width: 540px){
        margin-bottom: 2rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 800px){
        font-size: 1.2rem;
    }

  
`



export const InstructionsContainer = styled.p`
    ${instructionStyles};
`

export const InitialInstructions = styled.p`
    ${instructionStyles};
    margin-bottom: 4rem;

`
