import { useState, useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import { PopUpInner} from '../PopUp/popUpStyles'
import { PersonnelContainer, 
    SubmitAllButton,
    ManagerSearch,
    TargetManager,
    SearchTopRow,
    SearchBottomRow,
    FromAdminHolder,
    SingleAdmin,
    ToAdminHolder,
    ToAdmin,
    EmployeeSearch,
    ManagerInput,
    Top,
    TopRowLeftColumn,
    TopRowRightColumn,
    TransferButton
} from "./personnelModalStyles"
import ModularRow from "./ModularRow"
import ManagerSearchList from "./ManagerSearchList"
import EmployeeSearchList from './EmployeeSearchList'
import { transferEmployees } from "../../services/dataServices"



const TransferModal = () =>{
    const {transferPopUp, setTransferPopUp, addManagersList, setAddManagersList,
    adminList, setAdminList,
    firstTransferManager,
    setFirstTransferManager,
    secondTransferManager,
    setSecondTransferManager,
    firstTransferList,
    setFirstTransferList,
    secondTransferList, 
    setSecondTransferList,
    pseudoTransferList
    } = useContext(MenuContext)
    const [initiatingManager, setInitiatingManager] = useState("")
    const [targetManager, setTargetManager] = useState("")

    const submitMany = () =>{
        //some axios call to some endpoint
        setTransferPopUp(false)
        setAddManagersList([])
    }

    const SelectedManager = ({manager, setterFunctions}) =>{
        const setLocalManger = setterFunctions[0]
        const setGlobalManager = setterFunctions[1]
        const setEmployeeList = setterFunctions[2]
        return(
            <SingleAdmin>
                <p>{manager}</p>
                <i className="fa-solid fa-xmark"
                    onClick={() => {
                        setLocalManger("")
                        setGlobalManager("")
                        setEmployeeList([])
                    }}
                ></i>
            </SingleAdmin>
        )
    }

    const isOneSelected = () =>{
        if(pseudoTransferList.length > 0){
            let marker = false

            pseudoTransferList.forEach(entry =>{
             
                if(entry.selected === true){
                    marker = true
                }
            })

            return marker
        }
    }

    return(
          <PopUpInner vis={transferPopUp}>
            <PersonnelContainer>
                {/* {
                   addManagersList !== undefined &&
                   addManagersList.map(entry =>{
                        return <ModularRow key={addManagersList.indexOf(entry)} indx={addManagersList.indexOf(entry)}
                                    singleMarker={addManagersList !== undefined && addManagersList.length < 2}
                                />
                   })
                } */}

    
                    <h2>Transfer Employees</h2>
                    <ManagerSearch>
                        <SearchTopRow>
                            <TopRowLeftColumn>
                                <ManagerInput vis={firstTransferManager === ""} value={initiatingManager} placeholder="from email" onChange={(e)=>{
                                        setInitiatingManager(e.target.value)
                                    }} />
                            </TopRowLeftColumn>
                        
                            <i className="fa-solid fa-arrow-right"></i>
                            
                            <TopRowRightColumn>
                                <ManagerInput  vis={secondTransferManager === ""} value={targetManager} placeholder="to email" onChange={(e)=>{
                                    setTargetManager(e.target.value)
                                }}/>
                            </TopRowRightColumn> 
                            
                        </SearchTopRow>
                        <SearchBottomRow>
                            {
                                firstTransferManager === ""?

                                // ManagerSearchList is actually in charge of a lot of the logic necessary
                                // you may need to look at its standalone Component file to clarify this page
                                <FromAdminHolder>
                                    <ManagerSearchList preset={"initiator"}list={adminList} value={initiatingManager}/>
                                </FromAdminHolder>

                                :
                                
                                //this component is declared above in this same file (not imported from the folder)
                                <SelectedManager manager={firstTransferManager} setterFunctions={[setInitiatingManager, setFirstTransferManager, setFirstTransferList]}/>
                                    
               
                            }
                            {
                                secondTransferManager === ""?

                                <FromAdminHolder>
                                    <ManagerSearchList preset={"target"} list={adminList} value={targetManager}/>
                                </FromAdminHolder>

                                :

                                <SelectedManager manager={secondTransferManager} setterFunctions={[setTargetManager, setSecondTransferManager, setSecondTransferList]}/>
                            }
         
                            
                        </SearchBottomRow>
                    
                       
                    </ManagerSearch>
                    
                    <EmployeeSearch vis={firstTransferList.length > 0}>
                            {/* <SearchTopRow>
                                <TopRowColumn>
                                    <ManagerInput vis={firstTransferManager === ""} value={initiatingManager} placeholder="from email" onChange={(e)=>{
                                            setInitiatingManager(e.target.value)
                                        }} />
                                </TopRowColumn>
                            
                                <i className="fa-solid fa-arrow-right"></i>
                                
                                <TopRowColumn>
                                    <ManagerInput  vis={secondTransferManager === ""} value={targetManager} placeholder="to email" onChange={(e)=>{
                                        setTargetManager(e.target.value)
                                    }}/>
                                </TopRowColumn> 
                                
                            </SearchTopRow> */}
                   
                                {
                                    firstTransferList.length > 0 &&

                              
                                        <EmployeeSearchList preset={"initiator"}list={firstTransferList} value={initiatingManager}/>
                             
                                }
                                {/* {
                                    secondTransferList.length > 0 &&

                                    <FromAdminHolder>
                                        <EmployeeSearchList preset={"target"} list={secondTransferList} value={targetManager}/>
                                    </FromAdminHolder>
                                }
                                */}
                                
                          
                    
                    </EmployeeSearch>
                   
                    

                    
            </PersonnelContainer >
            <TransferButton vis={(
                firstTransferManager !== "" 
                && secondTransferManager !== ""
                && firstTransferList.length > 0 
                && isOneSelected())}
                onClick={async()=>{
                    const filtered = pseudoTransferList.filter(entry => {if(entry.selected === true) return entry})
                    const restoredList = filtered.map(entry => {
                        let placeholder = entry
                        delete placeholder.selected
                        return placeholder
                    })
                    await transferEmployees({employees: restoredList, originalAdmin: firstTransferManager, newAdmin: secondTransferManager})
                    .then(res => {
                        if(res){
                            setInitiatingManager("")
                            setTargetManager("")
                            setFirstTransferManager("")
                            setSecondTransferManager("")
                            setFirstTransferList([])
                            setTransferPopUp(false)
                        }
                    })
                    .catch(err => console.log("TransferModal line 211: ", err))
                }}    
            >
                Transfer
            </TransferButton>
            <SubmitAllButton vis={addManagersList.length > 1} width={'150px'} 
                onClick={submitMany}
            >
                submit all
            </SubmitAllButton>
            <i style={{cursor: "pointer"}}className="fa-regular fa-circle-xmark"
                    onClick={()=>{setTransferPopUp(false)}}
                ></i>
            
        </PopUpInner>
    )
}

export default TransferModal