import styled, {css} from 'styled-components'



export const FrameContainer = styled.div`
    margin: 0;
    padding: 0 ${props => props.padding? props.padding : "5vw"};
    min-width: 100vw;
    visibility: ${props => props.vis? "visible" : "hidden"};
    position: relative;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this.*/
    height: fill-available; 
    height: 100vh;

`

export const FrameNavigation = styled.div`
    display: flex;
    justify-content: ${props => props.navJc? props.navJc : "space-between"};
    align-items: center;
    width: 100%;
    margin-top: ${props => props.navMt? `${props.navMt}vh` : "0vh"};
    /* border: 1px solid green; */
`

export const FrameBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: ${props => props.bodyMt? `${props.bodyMt}vh` : "0vh"};
    min-height: ${props => props.bodyHt? `${props.bodyHt}vh` : "50vh"};
    /* border: 1px solid red;  */
`

