import styled, {css} from 'styled-components'

const contentStyles = css`
    display: ${props => props.display};
    position: ${props => props.position};
    flex-direction: column;
    align-items: center;
    background: ${props => `${props.bg}`};
    margin-top: ${props => `${props.mt}`};
    /* border: 1px solid forestgreen; */
    min-height: 25vh;
    .contact-select{
        display: block;
        margin: 0 auto;
        padding: 0.25rem;
        font-size: 0.85rem;
        opacity: ${props => props.welcomeSelectShow? "1" : "0"};
        transition: all ${props => `${props.aTime}s`} ease;
        padding: 0.25rem;
        background: none;
        border: none;
        border-bottom: ${props => props.accentColor?  `1px solid ${props.accentColor}` : `1px solid black`};
        color: ${props=> props.accentColor?  props.accentColor : "black"};
        
    }

    .contact-select option{
            background: ${props => props.bottomColor? props.bottomColor : "#37A89A"};
            font-size: 0.7rem;
            font-weight: 500;
            text-shadow: 0px 0px 2px black;
        }

   .hello-list{
        color: ${props=> props.accentColor?  props.accentColor : "black"};
        list-style-type: none;
        max-width: 85vw;
        margin: 1rem auto;
   }

   .hello-list li{
         margin-bottom: 1rem;
         font-size: 0.85rem;
   }

   .first-email{

   }
   
`

export const ContentContainer = styled.div`
    ${contentStyles};
`

export const EndMessage = styled.p`
    color: ${props => props.accentColor};
`