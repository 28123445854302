import styled, {css} from 'styled-components'

const fadeStyle = css`
        opacity: ${props => props.on_off? "1" : "0"};
        transition: opacity 3s ease;
`

export const MainContainerMobile = styled.div`
    position: relative;
    height: 100vh;
    overflow: hidden;

    @media (min-width: 1000px){
        display: none;
    }
   
`


export const DesktopDisclaimer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 40%;
    min-height: 45vh;
    padding: 1rem;
    *{
        text-align: center;
    }
    h2{
        font-size:  3rem;
    }
    p{
        font-size: 2rem;
        
    }
`

export const LogoPair = styled.div`
    border: 1px solid yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${props => props.initiate? "translateY(25vh)" : `translateY(100vh)`};
    transition: all ${props => `${props.aTime}s`} linear;
    width: 99%;
    margin: 0 auto;
`

export const LogoMessage = styled.h4`
    transition: color 1s ease;
    font-size: 0.7rem;
    font-weight: 400;
    margin-right: 0.25rem;
    justify-content: center;
    transform: ${props => props.initiate? "translateY(0vh)" : `translateY(100vh)`};
    transition: all ${props => `${props.aTime}s`} linear;
`

export const LogoImage = styled.div`
    background-image: url('./images/tero-logo.png');
    background-position: center;
    background-size: cover;
    height:20px;
    width: 20px;
    border-radius: 100%;
    transform: ${props => props.initiate? "translatex(0vw)" : `translateX(400vw)`};
    transition: all ${props => `${props.aTime}s`} linear;
    /* @media (min-width: 390px){
        left: 63%;
    }
    @media (min-width: 412px){
        left: 61.5%;
    }
    @media (min-width: 540px){
        left: 57%;
    } */
    box-shadow: 0px 0px 1px black;
`
const greetingStyles = css`
    color: #37A89A;
    font-size: ${props => `${props.fs}rem`};
    text-align: right;
    margin-top: ${props => `${props.mt}vh`};
    transition: margin 1s ease;
`

// export const InitialGreeting = styled.h1`
//     ${greetingStyles};
//     margin-bottom: ${props => props.lift? ""}
// `
export const Greeting = styled.h1`
    ${greetingStyles};
    ${fadeStyle};

`

const instructionStyles = css`
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
    /* @media (min-width: 540px){
        margin-bottom: 2rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 800px){
        font-size: 1.2rem;
    } */
    ${fadeStyle};
`



export const Instructions = styled.p`
    ${instructionStyles};
`

export const InitialInstructions = styled.p`
    ${instructionStyles};
    margin-bottom: 6rem;
    margin-top: ${props => props.on_off? `${props.mt}vh` : "0vh"};
    transition:  all 3s ease;

`

const bodyStyles = css`
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    ${bodyStyles};
`

export const InitialContent = styled.div`
    ${bodyStyles};

    .contact-select{
        display: block;
        border: none;
        padding: 0.5rem;
        padding-right: 1rem;
        border-radius: 5px;
        margin-left: ${props => `${props.shift? "0rem" : "50rem"}`};
        transition: margin 2s ease;
        box-shadow: 0px 0px 5px 1px grey;
    
    }

 
`