import { CafeOpt, CafeOptNavPair, CafeOptNav, SingleOpt, OptCheckBox, GroupingHolder, CafeOptTopRow, CafeOptKeyContainer, SuggestedKey, MandatoryKey, KeyPair} from "./cafeSubmissionsStyles";
import Loader from "../Loader/Loader";

const CafeOption = (obj, arr, arraySetter, checkSetter, colorCheck, leaderCheck) =>{

    const isSuggestedColor = (color) =>{
        let checker = false 
        obj.associatedColors.forEach(entry =>{
            if(entry === color){
                checker = true
                
            }
        })

        return checker
    }

    return(
        <SingleOpt key={obj._id} 
            // isLeader={leaderCheck}
            // mandatoryTitle ={}
            highlight={{
                suggested: isSuggestedColor(colorCheck),
                isLeader: leaderCheck
            }}  onClick={()=>{
            
                const modified = arr.map(entry => {
                    if(entry.month === obj.month){
                        if(entry._id !== obj._id){
                            return {...entry, selected: false}
                        }else{
                            return {...entry, selected: true}
                        }
                    } else{
                        return entry
                    }
                })
                arraySetter(modified)
                checkSetter(obj._id)
            }}
            mandatory = {obj.leaderMandatory}
        >
            <p>{obj.title}</p>
            <OptCheckBox vis={obj.selected} />
        </SingleOpt>
    )
}

const CafeOptionGroup = (first, second, third, array, arraySetter, position, positionSetter, company, setSelection, color, leaderStatus, isLoading, cafeLock,setWarningCheck, setWarnCheckPopUp) =>{

    const navLeft = () =>{
        
            if(position > 1){
            
                    positionSetter(position-1)
               
            } 
         
        
    }

    const navRight = () =>{
   
        if(position < 5 ){
            
                positionSetter(position+1)
            
           } 
       
    }

    // const navLeft = () =>{
    //     if(leaderStatus){
    //         if(position > 1){
    //             if(!cafeLock){
    //                 positionSetter(position-1)
    //             }
    //             else{
    //                 setWarningCheck("Be Upstanding is a required selection for topic 2")
    //                 setWarnCheckPopUp(true)
    //             }
    //         } 
    //     } else{
    //         if(position > 1){
    //             positionSetter(position-1)
    //         }
    //     }
    // }

    // const navRight = () =>{
    //    if(leaderStatus){
    //     if(position < 5 ){
    //         if(!cafeLock){
    //             positionSetter(position+1)
    //         } else{
    //             setWarningCheck("Be Upstanding is a required selection for topic 2")
    //             setWarnCheckPopUp(true)
    //         }
    //        } 

    //    } else{
    //     if(position < 5){
    //         positionSetter(position+1)
    //        }
    //    }
       
    // }

    const grouping = [first, second, third]


    return(
        <CafeOpt key={first.month} month={first.month} selected={position === first.month}>
            <CafeOptTopRow titleColor={company !== undefined && company.titleColor}>
                <h2>{`Topic ${first.month}`}</h2>
                <CafeOptKeyContainer>
                    <KeyPair>
                        <p>Suggested</p>
                        <SuggestedKey />
                    </KeyPair>
                    {/* <KeyPair>
                        <p>Required</p>
                        <MandatoryKey />
                    </KeyPair> */}
                </CafeOptKeyContainer>
            </CafeOptTopRow>
            <GroupingHolder>
                {
                    
                    grouping.map(entry =>  CafeOption(entry, array, arraySetter, setSelection, color, leaderStatus)
                    )

                }
            </GroupingHolder>
 
            <CafeOptNavPair month={first.month} selected={position === first.month}>
                <CafeOptNav month={first.month} orientation={"left"} className="fa-solid fa-chevron-left" onClick={navLeft}></CafeOptNav>
                {isLoading && <Loader 
                accentColor={company !== undefined && company.accentColor}  
                iconSize={"1.5rem"}
                mb={"0vh"}
                mt={"5vh"}
            /> }
                <CafeOptNav month={first.month} orientation={"right"} className="fa-solid fa-chevron-right" onClick={navRight}></CafeOptNav>
            </CafeOptNavPair>
        </CafeOpt>
    )
}


export default CafeOptionGroup

// await postAssessmentModified({
//     employee_id: employeeId,
//     cards: tenArray,
//     learningPath: null,
//     firstSuggested: finalResults[0].firstColor,
//     secondSuggested: finalResults[1].secondColor,
//     secondScore: finalResults[1].secondPlace,
//     assessmentChoice:  null,
//     currentSkillsChallenges: finalCafes,
//     companyId: company._id
// })
//     .then(res => {
//         if(res){
//             setCurrentFrame(prev => prev+1)
//             sendEmail({
//                 firstName: firstName,
//                 lastName: lastName,
//                 email: email,
//                 managerEmail : managerEmail,
//                 employeeTerritory: employeeTerritory,
//                 industryYears : industryYears,
//                 tenArray: tenArray,
//                 pathDateRange : pathDateRange,
//                 skillsChallenges: finalCafes,
//                 company: company.name
//             })
//         }
//     })
//     .catch(err => alert(err))

// sendHB({
//     firstName: firstName,
//     lastName: lastName,
//     email: email,
//     company: company.name,
//     learningPath: learningPath,
//     industryYears : industryYears,
//     phoneNumber: phoneNumber,
//     currentSkillsChallenges: finalCafes

// })
//     .then(res =>{})
//     .catch(err => alert(err))
