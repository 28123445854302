import{
    ResultsContainer,
    ResultsModal,
    ResultsCard,
    LearningPathSelect,
    ConfirmationButton,
    
} from '../styles/cardPageStyles'
import { MenuContext } from '../utils/menuContext'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { 
    // confirmAssessment, 
    setLearningPath } from '../services/dataServices'
import { sendEmail } from '../services/mailServices'
import EmailComponent from './EmailComponent'


const ResultModal = ({res, l_card, anim, btn_lock}) =>{
    const {colorArray, contact, setContact, tenArray} = useContext(MenuContext)
    const [suggestedPath, setSuggestedPath] = useState("")
    const [manualPath, setManualPath] = useState("")
    const [manualText, setManualText] = useState("")
    const [buttonActive, setButtonActive] = useState(true)
    const [formData, setFormData] = useState(null)
    const redirect = useNavigate()

    console.log(suggestedPath)
    // if(formData !== null){
    //     console.log(formData.get("assessmentImage"))
    // }

    useEffect(()=>{
        setContact({...contact, 
            suggestedPath: suggestedPath,
            manualPath: manualPath,
            cardSet: JSON.stringify(tenArray.map(entry =>{
                if(tenArray.indexOf(entry) < 2){
                    return {...entry, gameChanger: true}
                }
                else return entry
            }))
        })
    },[manualPath])

    useEffect(()=>{
        
        switch(colorArray.indexOf(Math.max(...colorArray))){
            case 0: setSuggestedPath("The Blue Path")
            break
            case 1: setSuggestedPath("The Red Path")
            break
            case 2: setSuggestedPath("The Green Path")
            break
            case 3: setSuggestedPath("The Yellow Path")
            break
            case 4: setSuggestedPath("The Orange Path")
            break
            default: setSuggestedPath("")
        }

        

    },[colorArray])

    const getResultColor = (a) =>{
        
        if(a===""){
            let val
            switch(colorArray.indexOf(Math.max(...colorArray))){
                case 0: val = "dodgerblue"
                break
                case 1: val = "crimson"
                break
                case 2: val = "darkgreen"
                break
                case 3: val = "gold"
                break
                case 4: val = "tomato"
                break
                default: val = "blue"
            }
            return val
        }
        else{
            let val
            switch(a){
                case "The Blue Path": val = "dodgerblue"
                break
                case "The Red Path": val = "crimson"
                break
                case "The Green Path": val = "darkgreen"
                break
                case "The Yellow Path": val = "gold"
                break
                case "The Orange Path": val = "tomato"
                break
                default: val = "blue"
            }

            return val
        }
    }

    const getResultText = (a) =>{
        if(a===""){
            let val
        switch(colorArray.indexOf(Math.max(...colorArray))){
            case 0: val = "The Blue Path"
            break
            case 1: val = "The Red Path"
            break
            case 2: val = "The Green Path"
            break
            case 3: val = "The Yellow path"
            break
            case 4: val = "The Orange Path"
            break
            default: val = "The Blue Path"
        }
        return val
        }
        else{
            let val
            switch(a){
                case "The Blue Path": val = "The Blue Path"
                break
                case "The Red Path": val = "The Red Path"
                break
                case "The Green Path": val = "The Green Path"
                break
                case "The Yellow Path": val = "The Yellow Path"
                break
                case "The Orange Path": val = "The Orange Path"
                break
                default: val = "The Blue Path"
            }
            return val
        }
    }


    const changeSelection = (target) =>{
        setManualPath(target)
        setManualText(target)
    }


    const finalize = async() =>{
        const placeholder = {
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            company: contact.company,
            managerEmail: contact.managerEmail,
            cardSet: tenArray.map(entry =>{
                if(tenArray.indexOf(entry) < 2){
                    return {...entry, gameChanger: true}
                }
                else return entry
            }),
            suggestedPath: suggestedPath,
            manualPath: manualPath,
            assessmentImage: contact.assessmentImage
        }

        setContact({...placeholder, assessmentImage: contact.assessmentImage})
        localStorage.setItem("complete", true)
        localStorage.setItem("contact", JSON.stringify(placeholder))
        setLearningPath(contact.email, manualPath, suggestedPath)
        // await confirmAssessment(placeholder)
        // .then(res => {
        //     if(res){
        //         redirect('/complete')
        //     }
        // })
        // .catch(err => alert(err))
    
    }
   
    
    return(
        // <ResultsContainer vis={res} >
                
        //     </ResultsContainer>
        <ResultsModal expand={btn_lock}>
                    <h3
                     style={{textAlign: "center", color: "#11898A"}}
                    >{manualPath===""? `Your Suggested Learning Path` : `Your Selected Learning Path`}</h3>
                    <ResultsCard expand={btn_lock} status={l_card} anim={anim} bg_color={getResultColor(manualPath)}>
                        <div style={{background: "white", width: "100%", padding: "1rem"}}><h3 style={{margin: "0", padding: "0"}}>{getResultText(manualText)}</h3></div>
                    </ResultsCard>
                    <p>set your path manually below</p>
                    <LearningPathSelect expand={btn_lock} style={{marginBottom: "1rem"}} onChange={e => changeSelection(e.target.value)}>
                        <option hidden>Select a Path</option>
                        <option value="The Blue Path">The Blue Path</option>
                        <option value="The Red Path">The Red Path</option>
                        <option value="The Green Path">The Green Path</option>
                        <option value="The Yellow Path">The Yellow Path</option>
                        <option value="The Orange Path">The Orange Path</option>
                    </LearningPathSelect>
                    {/* <input type="file" onChange ={e => {
                        const placeholder = new FormData()
                        const fContact = {...contact, 
                            suggestedPath: suggestedPath, 
                            manualPath: manualPath,
                            cardSet: JSON.stringify(tenArray.map(entry =>{
                                if(tenArray.indexOf(entry) < 2){
                                    return {...entry, gameChanger: true}
                                }
                                else return entry
                            }))
                        }
                     
                        for(let i in fContact){

                            placeholder.append(i, fContact[i])
                        }

                       
                        placeholder.append("assessmentImage", e.target.files[0])
                  
                        setFormData(placeholder)
                        setContact({...fContact, assessmentImage: e.target.files[0]})
                        setButtonActive(prev => !prev)
                    }}/> */}
                    <ConfirmationButton  
                        disabled={btn_lock}
                        onClick={()=> {
                        // set_res(false)
                        // set_l_card(false)
                        // set_anim(false)
                        finalize()
                    }}>submit</ConfirmationButton>
                    
                </ResultsModal>
    )
}

export default ResultModal