import styled from "styled-components";


export const FirstEmailContainer = styled.div`
    width: 45%;
    margin: 0 auto;
    margin-top: 2rem;
    
    input{
            touch-action: none;
            width: 100%;
            background: none;
            border: none;
            padding: 0.25rem 0.5rem 0.25rem 0.25rem;
            border-bottom: ${props => props.accentColor? `0.5px solid ${props.accentColor}` : "0.5px solid black"};
            color:  ${props => props.accentColor}; 
            font-size: 16px;
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ${props=>props.accentColor};
            opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: ${props=>props.accentColor};
            }

            ::-ms-input-placeholder { /* Microsoft Edge */
            color: ${props=>props.accentColor};
            }

    
        }
        
        
        input:last-of-type{
            flex: 100%;
        }

        select{
            padding: 0.2rem 0rem;
            background: none;
            border: ${props => `0.5px solid ${props.accentColor}`};
            border-radius: 5px;
            color: ${props=>props.accentColor}; 
            width: 47%; 
        }


        option{
            background: ${props => props.bottomColor};
            font-size: 0.7rem;
        }

        p{
            font-size: 0.8rem;
            color: ${props=>props.accentColor};
            padding-left: 0.25rem;
        }

        opacity: ${props => props.vis? "1" : "0"};
        transition: all ${props => `${props.aTime}s`} ease;

        @media(min-width: 500px){
            width: 30%;
        }

        @media(min-width: 1200px){
            width: 20%;
        }

        @media(min-width: 1400px){
            width: 15%;
        }
`