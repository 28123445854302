import {InstructionsContainer} from './instructionsStyles'

//a styled p
//children prop enables writing inner message / elements between 2 JSX tags
//mt is margin top for outside control

//USE: a standard element of the Frame schema, indicates the current Frame function

function Instructions({utils}){
    const {company, message, mt, vis, aTime, mb} = utils
    
    return(
        <InstructionsContainer 
            vis={vis} mt={mt} 
            aTime={aTime}
            mb={mb}
            titleColor={company !== undefined && company.titleColor}
            topColor={company !== undefined && company.topColor}
            bottomColor={company !== undefined && company.bottomColor}
            accentColor={company !== undefined && company.accentColor}
            auxColor={company !== undefined && company.auxColor}
        >
        
            {message}
        </InstructionsContainer>
    )
}

export default Instructions