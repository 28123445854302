import styled from 'styled-components'

export const ImageSubmitButton = styled.div`
    color: ${props => props.accentColor};
    /* border: 2px solid ${props => props.accentColor};
    border-radius: 5px; */
    display: block;
    padding: 0.25rem 0.5rem;
    background: none;
    margin: 0 auto;
    margin-top: 1rem;
    
    span{
        margin-right: 0.5rem;
        font-weight: bold;
    }
`
