import styled from 'styled-components'

export const DesktopAwayContainer = styled.div`
    display: none;
    position: relative;
    height: 100vh;

    // this is for the desktop disclaimer to be centered;
    @media (min-width: 1000px){
       display: flex;
       justify-content: center;
       align-items: center;
    }
   
`

export const DesktopAwayDisclaimer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 50%;
    min-height: 45vh;
    padding: 1rem;
    *{
        text-align: center;
    }
    h2{
        font-size:  2rem;
        margin-bottom: 2rem;
    }
    p{
        font-size: 1.5rem;
        
    }
`
