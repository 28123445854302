import { useState, useContext, useEffect } from "react"
import { MenuContext } from "../../utils/menuContext"
import{imageUpload, getImage} from "../../services/dataServices"
import { ImageSubmitButton } from "./imageInputStyles"

function ImgInput({sButtonImageLock_, eraser, }){
    const {
        contact, 
        setContact, 
        tenArray, 
        setImageLoading,
        setImageEndpoint,
        setPromptShow
    } = useContext(MenuContext)
    const [dataForm, setDataForm] = useState(undefined)
    const [buttonLock, setButtonLock] = useState(false)


    return(
       
        <div>
        <label htmlFor="file-upload" >
            select file
        </label>
        <input style={{margin: "0"}} type="file" id="file-upload" onChange ={async(e) => {
          
            if(e.target.files[0] !== undefined){
                // setImageLoading(true)
                
                setContact({
                    ...contact, 
                    cardSet: tenArray.map(entry =>{
                        if(tenArray.indexOf(entry) < 2){
                            return {...entry, gameChanger: true}
                        }
                        else return entry
                    })
                })
                const placeholder = new FormData()
                const fContact = {...contact, 
                    cardSet: JSON.stringify(tenArray.map(entry =>{
                        if(tenArray.indexOf(entry) < 2){
                            return {...entry, gameChanger: true}
                        }
                        else return entry
                    }))
                }
                
    
                for(let i in fContact){
                    placeholder.append(i, fContact[i])
                }
    
                
                placeholder.append("image", e.target.files[0])
                setDataForm(placeholder)

            }
            
        }}/>
        {
            dataForm !== undefined &&
            <ImageSubmitButton  onClick={async()=>{
                if(!buttonLock){
                    setButtonLock(true)
                    setImageLoading(true)
                    await imageUpload(contact.employee_id,dataForm)
                    .then( async(res) =>{
                        setDataForm(undefined)
                        if(res.data !== undefined){
                            
                            await getImage(contact.employee_id)
                            .then(res => {
                                setPromptShow(true)
                                setTimeout(()=>{setPromptShow(false)}, 3000)
                                setImageEndpoint(res.data.url)
                                setImageLoading(false)
                                setButtonLock(false)
                                return
                            })
                            .catch(err => alert(err))  
                        }
        
                    
                        else{
                            setImageLoading(false)
                            setButtonLock(false)
                        }
                    })                
                }
            }}>
                <span>submit</span>
                <i className="fa-solid fa-upload"></i>
            </ImageSubmitButton>
            
        }
        
    </div> 
       
    )
}

export default ImgInput