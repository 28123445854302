import styled from "styled-components";

export const ImageModContainer = styled.div`
        width: 100%;
        margin: 0 auto;
        margin-top: 1rem;
        height: ${props => props.height};
`

export const ImageUploader = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

   input{
    display: none;
   }

   label{
    border-radius: 5px;
    border: 2px solid ${props => props.accentColor? props.accentColor : "black"};
    color: ${props => props.accentColor};
    padding: 0.25rem 0.5rem;
   }
`

export const ImageOuterWindow = styled.div`
    height: 300px;
    width: 150px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
`

export const UploadConfirm = styled.div`
    background: red;
    position: absolute;
    color: white;
    text-shadow: 0px 0px 5px black;
    background: none;
    text-align: center;
    top: 5rem;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -10rem);
    font-weight: bold;
    p:first-of-type{
        margin-bottom: 1rem;
    }

    @keyframes showUp {
        0%   {transform: translate(-50%, -10rem); opacity: 0;}
        25%  {transform: translate(-50%, 0rem); opacity: 1;}
        75%  {transform: translate(-50%, 0rem); opacity: 1;}
        100% {transform: translate(-50%, -10rem); opacity: 0;}
    }

    animation-duration: 3s;
    animation-name: ${props => props.promptShow? "showUp" : "none"};
    transition: all 1s ease;
`

export const ImageInnerContent = styled.div`
    height:${props => props.currentImage? `100%` : "0%"};
    background-image:${props => props.currentImage? `url(${props.currentImage})` : "none"};
    background-size: ${props => props.currentImage? `contain` : "auto"};
    background-repeat: ${props => props.currentImage? `no-repeat` : "auto"};
    background-position: ${props => props.currentImage? `center` : "auto"};
`

export const ImageNotLoaded = styled.p`
    color: ${props => props.accentColor};
    text-align: center;
`
