import styled, {css} from 'styled-components'

export const OverContainer = styled.div`
    position: relative;
`

export const CompetencyContainer = styled.div`

    margin: 0 auto;
    padding: 0 1vw;
    padding-top: 1rem;
    margin-bottom: 2rem;
    border: 2px solid ${props => props.accentColor};
    height: 45vh;
    overflow-y: scroll;
    border-radius: 10px;
    width: 100%;
`


export const CardContainer = styled.div`
    overflow-x: hidden;
    padding-bottom: 10vh;
`


const listSharedStyles = css`
     h3{
        font-size: 1.4rem;
        text-align: right;
        margin-bottom: 5vh;
    }
    margin-bottom: 5vh;
`

export const GameChangerList = styled.div`
   ${listSharedStyles};
`

export const CompetencyList = styled.div`
   ${listSharedStyles};
`

export const ScrollPrompt = styled.p`
    font-size: 0.8rem;
    margin: 1rem auto;
    text-align: center;
    color: ${props => props.accentColor};
    i{
        color: ${props => props.accentColor};
        margin-left: 0.3rem;
    }
`

export const PreviousButton = styled.p`
    font-size: 0.8rem;
    margin: 1rem auto;
    text-align: center;
    border: 2px solid ${({accentColor})=>accentColor};
    width: 50%;
    padding: 0.5rem;
    border-radius: 5px;
`