import {ScrollableContainer} from './scrollableStyles'


function Scrollable({children, accentColor}){
    return(
        <ScrollableContainer accentColor={accentColor}>
            {children}
        </ScrollableContainer>
    )
}

export default Scrollable