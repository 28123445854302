import { useContext, useState } from "react"
import {ListHolder, 
    ReportAdvancedContainer, 
    ReportContainer, 
    ReportStandardContainer,
    FilterOption,
    FilterPair
} from "./adminPageStyles"
import { MenuContext } from "../../utils/menuContext"
import { getBasicReport, getAdvancedReport } from "../../services/dataServices"
import * as XLSX from "xlsx"

const ReportManager = ({lvl, filtered, setFiltered}) =>{
    const {admin} = useContext(MenuContext)
    const [extractedContacts, setExtractedContacts] = useState(undefined)
    const [managerToCheck, setManagerToCheck] = useState("")






    const standardExport = (assessmentArray) =>{
        let workBook = XLSX.utils.book_new()
        let  contactSheet = XLSX.utils.json_to_sheet(assessmentArray)

        XLSX.utils.book_append_sheet(workBook, contactSheet, "Assessments")

        XLSX.writeFile(workBook, "competency-report-object.xlsx")
    }

    //not being used becuase it needs an array of arrays, mongo seems
    //to give an array of objects
    const arrayExport = (data) =>{
        let workBook = XLSX.utils.book_new()
        let  workSheet = XLSX.utils.aoa_to_sheet(data)

        XLSX.utils.book_append_sheet(workBook, workSheet, "Assessments")

        XLSX.writeFile(workBook, "competency-report-array.xlsx")
    }


    return(
        <ReportContainer>
            <ReportStandardContainer vis={lvl}>
                    <h2>Assessment Report</h2>
                    <p>Click below to download the latest assessment report for your team</p>
                    <button onClick={async()=>{
                        await getBasicReport(admin.email)
                        .then(res =>{
                            const result = res.data
                            console.log(res.data)
                          
                            const contacts = result.map(entry=>{
                                const document = {
                                    firstName: entry.firstName,
                                    lastName: entry.lastName,
                                    email: entry.email,
                                    managerEmail: entry.managerEmail,
                                    employeeTerritory: entry.employeeTerritory,
                                    industryYears: entry.industryYears,
                                    completionDate: entry.completionDate,
                                    firstSuggested: entry.firstSuggested,
                                    secondSuggested: entry.secondSuggested,
                                    // firstScore: entry.firstScore,
                                    // learningPath: entry.learningPath,
                                    // secondScore: entry.secondScore,
                                    // assessmentChoice: entry.assessmentChoice
                                    
                                }
            

                                //This is included for newever assessments with skills topics instead of
                                //1st, 2nd, and manual selections

                                const skillsTopics = entry.currentSkillsChallenges

                                for(const topic of skillsTopics){
                                    document[`Skill Topic ${skillsTopics.indexOf(topic)}`]= topic.title
                                }

                                const localCards = entry.cards

                                for(const card of localCards){
                                    
                                    if(localCards.indexOf(card) === 0){
                                        document["gameChanger1"]= card.skill
                                        document["gameChanger1DevStage"]= card.devStage
                                        document["gameChanger1color"]= card.learningPath      
                                    }
                                    if(localCards.indexOf(card) === 1){
                                        document["gameChanger2"]= card.skill
                                        document["gameChanger2DevStage"]= card.devStage
                                        document["gameChanger2color"]= card.learningPath  
                                    }
                                    else if(localCards.indexOf(card) > 1){
                                        document[`competency${localCards.indexOf(card)-1}`]= card.skill
                                        document[`competency${localCards.indexOf(card)-1}DevStage`]= card.devStage
                                        document[`competency${localCards.indexOf(card)-1}Color`]= card.learningPath
                                
                                    }
                                }

                                return document
                            })
                            
                          
        
                            standardExport(contacts)
                            setExtractedContacts(contacts)
                        })
                        .catch(err => console.log(err))
                    }}>generate</button>
            </ReportStandardContainer>
            
            <ReportAdvancedContainer vis={lvl}>
                    <h2>Assessment Report</h2>
                    <p>Click below to download the latest assessment global report</p>
                   
                    <button
                        onClick = {async()=>{
                            await getAdvancedReport({company_id: admin.company_id, level: admin.level })
                            .then(res =>{
                                const result = res.data
    
                              
                                const contacts = result.map(entry=>{
                                    const document = {
                                    firstName: entry.firstName,
                                    lastName: entry.lastName,
                                    email: entry.email,
                                    managerEmail: entry.managerEmail,
                                    employeeTerritory: entry.employeeTerritory,
                                    industryYears: entry.industryYears,
                                    completionDate: entry.completionDate,
                                    firstSuggested: entry.firstSuggested,
                                    secondSuggested: entry.secondSuggested
                                    // firstScore: entry.firstScore,
                                    // learningPath: entry.learningPath,
                                    // secondScore: entry.secondScore,
                                    // assessmentChoice: entry.assessmentChoice
                                        
                                    }

                                    
                                //This is included for newever assessments with skills topics insteado f
                                //1st, 2nd, and manual selections

                                const skillsTopics = entry.currentSkillsChallenges

                                for(const topic of skillsTopics){
                                    document[`Skill Topic ${skillsTopics.indexOf(topic)+1}`]= topic.title
                                }


                                const localCards = entry.cards
                                    // console.log(`employee ${result.indexOf(entry)+1}'s cards: `, localCards)
                                    for(const card of localCards){
                                        
                                        if(localCards.indexOf(card) === 0){
                                            document["gameChanger1"]= card.skill
                                            document["gameChanger1DevStage"]= card.devStage
                                            document["gameChanger1color"]= card.learningPath      
                                        }
                                        if(localCards.indexOf(card) === 1){
                                            document["gameChanger2"]= card.skill
                                            document["gameChanger2DevStage"]= card.devStage
                                            document["gameChanger2color"]= card.learningPath  
                                        }
                                        else if(localCards.indexOf(card) > 1){
                                            document[`competency${localCards.indexOf(card)-1}`]= card.skill
                                            document[`competency${localCards.indexOf(card)-1}DevStage`]= card.devStage
                                            document[`competency${localCards.indexOf(card)-1}Color`]= card.learningPath
                                    
                                        }
                                    }

                                    return document
                                })
                                
                              
            
                                standardExport(contacts)
                                setExtractedContacts(contacts)
                            })
                            .catch(err => console.log(err))
                        
                        }}
                    >all reports</button>
                    <FilterPair>
                        <p> filter by manager </p>
                    
                        {!filtered && <i className="fa-solid fa-chevron-down" onClick={()=>{
                            setFiltered(prev => !prev)
                        }}></i>}
                        {filtered && <i className="fa-solid fa-chevron-up" onClick={()=>{
                            setFiltered(prev => !prev)
                        }}></i>}
                    </FilterPair>

                    <FilterOption vis={filtered}>
                        <input placeholder="manager email" value={managerToCheck}
                            onChange={(e)=>{
                                setManagerToCheck(e.target.value)
                            }}
                        />
                    
                        <button disabled={managerToCheck === ""} onClick={async()=>{
                                            await getAdvancedReport({company_id: admin.company_id, level: admin.level, managerEmail: managerToCheck })
                                            .then(res =>{
                                                const result = res.data

                                            
                                                const contacts = result.map(entry=>{
                                                    const document = {
                                                        firstName: entry.firstName,
                                                        lastName: entry.lastName,
                                                        email: entry.email,
                                                        managerEmail: entry.managerEmail,
                                                        employeeTerritory: entry.employeeTerritory,
                                                        industryYears: entry.industryYears,
                                                        completionDate: entry.completionDate,
                                                        firstSuggested: entry.firstSuggested,
                                                        secondSuggested: entry.secondSuggested
                                                        // firstScore: entry.firstScore,
                                                        // secondScore: entry.secondScore,
                                                        // assessmentChoice: entry.assessmentChoice
                                                        // learningPath: entry.learningPath,
                                                        
                                                    }

                                                    const skillsTopics = entry.currentSkillsChallenges

                                                    for(const topic of skillsTopics){
                                                        document[`Skill Topic ${skillsTopics.indexOf(topic)+1}`]= topic.title
                                                    }

                                                    
                                                    const localCards = entry.cards
                                                    // console.log(`employee ${result.indexOf(entry)+1}'s cards: `, localCards)
                                                    for(const card of localCards){
                                                        
                                                        if(localCards.indexOf(card) === 0){
                                                            document["gameChanger1"]= card.skill
                                                            document["gameChanger1DevStage"]= card.devStage
                                                            document["gameChanger1color"]= card.learningPath      
                                                        }
                                                        if(localCards.indexOf(card) === 1){
                                                            document["gameChanger2"]= card.skill
                                                            document["gameChanger2DevStage"]= card.devStage
                                                            document["gameChanger2color"]= card.learningPath  
                                                        }
                                                        else if(localCards.indexOf(card) > 1){
                                                            document[`competency${localCards.indexOf(card)-1}`]= card.skill
                                                            document[`competency${localCards.indexOf(card)-1}DevStage`]= card.devStage
                                                            document[`competency${localCards.indexOf(card)-1}Color`]= card.learningPath
                                                    
                                                        }
                                                    }
                                                    return document
                                                })
                                                
                                            
                            
                                                standardExport(contacts)
                                                setExtractedContacts(contacts)
                                            })
                                            .catch(err => console.log(err))
                                        }}>
                                            generate
                        </button>
                    </FilterOption>


                </ReportAdvancedContainer>

        </ReportContainer>
    )
}

export default ReportManager