import { useContext, useState } from "react";
import { MenuContext } from "../../utils/menuContext";
import { CafeContainer, CafeOptionContainer, SubmitButton} from "./cafeSubmissionsStyles";
import CafeOptionGroup from "./CafeOptionGroup";
import {postAssessmentModified, sendHB } from '../../services/dataServices.js'
import { sendEmail } from '../../services/mailServices.js'
import { getCurrentEmployeeAssessment } from "../../services/dataServices.js";



const CafeSubmission = () =>{
    const {cafeList, setCafeList, finalCafes, cafePosition, setCafePosition, company, setCurrentCafe, topColor, isLeader, cafeSubmitLock,
        learningPath, 
        choiceSelection, 
        employeeId, 
        tenArray,
        finalResults,
        setCurrentFrame,
        firstName,
        lastName,
        email,
        managerEmail,
        employeeTerritory,
        industryYears,
        pathDateRange,
        phoneNumber,
        verfied,
        setWarning,
        contact,
        setWarningPopUp
    } = useContext(MenuContext)
    let resultArray = []
    let threeStack = []
    const [isLoading, setIsLoading] = useState(false)

    //this sifts thorugh the cafeList in order to split it into groups of 3, per month (3 options per selection window)
    //NOTE: This doesn't account for ordering by month, can cause issues if the data comes from the database without being manipulated
    //prior, as MongoDB can just not list the documents in order by the month (you may need to find a way to get it to be order in such a way)
    //before this function takes it in
    const groupPopulate = (array) =>{
        
        let counter = 0

        for(let i = 0; i < array.length ; i++){
            
            if(counter <= 2){
                threeStack.push(array[i])
                counter++
            }
            if(threeStack.length > 2){
                resultArray.push(CafeOptionGroup(threeStack[0], threeStack[1], threeStack[2], cafeList, setCafeList, cafePosition, setCafePosition, company, setCurrentCafe, topColor !== undefined && topColor, isLeader, isLoading))
                threeStack = []
                counter = 0
            }

            
        }

        return resultArray
    }
    
    return(
        <CafeContainer>
            {
                cafeList.length > 0 &&
                <CafeOptionContainer>
                    {
                        groupPopulate(cafeList)
                    }
                </CafeOptionContainer>
            }        

{/* The assessments have to have been modified in a way that doesn't include a learningPath and assessmentChoice property 
(should be set to null). This is because the submitFrame no longer sports the finalModal, 
which is where they would've made both these choices simulataenously (the 1st, 2nd, or manual learningPath choice) pertaining 
to the learningPath and choiceSelection state variables taken from MenuContext.js. So for now, it makes sense to perserve the 
assessment schema with every other property still intact, with the addition of the currentSkillsChallenges property 
(tied to the finalCafes state variable on the front end). */}
            {
                !isLoading &&  
                        <SubmitButton vis={finalCafes.length > 4} 
                        onClick={
                            async()=>{
                            // if(isLeader){
                            //     if(!cafeSubmitLock){
                            //         await postAssessmentModified({
                            //             employee_id: employeeId,
                            //             cards: tenArray,
                            //             learningPath: null,
                            //             firstSuggested: finalResults[0].firstColor,
                            //             secondSuggested: finalResults[1].secondColor,
                            //             secondScore: finalResults[1].secondPlace,
                            //             assessmentChoice:  null,
                            //             currentSkillsChallenges: finalCafes,
                            //             companyId: company._id
                            //         })
                            //             .then(res => {
                            //                 if(res){
                            //                     setCurrentFrame(prev => prev+1)
                            //                     sendEmail({
                            //                         firstName: firstName,
                            //                         lastName: lastName,
                            //                         email: email,
                            //                         managerEmail : managerEmail,
                            //                         employeeTerritory: employeeTerritory,
                            //                         industryYears : industryYears,
                            //                         tenArray: tenArray,
                            //                         pathDateRange : pathDateRange,
                            //                         skillsChallenges: finalCafes,
                            //                         company: company.name
                            //                     })
                            //                 }
                            //             })
                            //             .catch(err => alert(err))
            
                            //         sendHB({
                            //             firstName: firstName,
                            //             lastName: lastName,
                            //             email: email,
                            //             company: company.name,
                            //             learningPath: learningPath,
                            //             industryYears : industryYears,
                            //             phoneNumber: phoneNumber,
                            //             currentSkillsChallenges: finalCafes,
                            //         })
                            //             .then(res => console.log("submission:"+res.status))
                            //             .catch(err => alert(err))
                            //     } else{
                            //         setWarning("Be Upstanding is a required selection for topic 2")
                            //         setWarningPopUp(true)
                            //     }
                            // } else{
                                const currentAssessment = await getCurrentEmployeeAssessment(employeeId)
                                if(contact.verified === true && currentAssessment.data !== null){
                 
                                if(!cafeSubmitLock){
                                    setCurrentFrame(prev => prev+2)
                                } else{
                                    setWarning("Be Upstanding is a required selection for topic 2")
                                    setWarningPopUp(true)
                                }
                            } else{
                 
                                setIsLoading(true)
                                await postAssessmentModified({
                                    employee_id: employeeId,
                                    cards: tenArray,
                                    learningPath: null,
                                    firstSuggested: finalResults[0].firstColor,
                                    secondSuggested: finalResults[1].secondColor,
                                    secondScore: finalResults[1].secondPlace,
                                    assessmentChoice:  null,
                                    currentSkillsChallenges: finalCafes,
                                    companyId: company._id
                                })
                                    .then(res => {
                                        if(res){
                                            setIsLoading(false)
                                            setCurrentFrame(prev => prev+1)
                                            sendEmail({
                                                firstName: firstName,
                                                lastName: lastName,
                                                email: email,
                                                managerEmail : managerEmail,
                                                employeeTerritory: employeeTerritory,
                                                industryYears : industryYears,
                                                tenArray: tenArray,
                                                pathDateRange : pathDateRange,
                                                skillsChallenges: finalCafes,
                                                company: company.name
                                            })
                                        }
                                    })
                                    .catch(err => alert(err))
            
                                sendHB({
                                    firstName: firstName,
                                    lastName: lastName,
                                    email: email,
                                    company: company.name,
                                    learningPath: learningPath,
                                    industryYears : industryYears,
                                    phoneNumber: phoneNumber,
                                    currentSkillsChallenges: finalCafes
            
                                })
                                    .then(res =>{})
                                    .catch(err => alert(err))
            
                            }
                        }}
                        >
                            Submit
                            </SubmitButton>
            }
        </CafeContainer>
    )
}

export default CafeSubmission