import {
    CompetencyContainer, 
    CardSubmitButton, 
    CardContainer, 
    GameChangerList, 
    CompetencyList
} from '../styles/cardPageStyles'
import {useState, useEffect, useContext} from 'react'
import {MenuContext} from '../utils/menuContext'
import CardSet from './CardSet'
import {Link} from 'react-router-dom'

function CompetencyBoard({btn_active, setLCard, setACard, setLock}){
    // const [cardCheck, setCardCheck] = useState(false)
    const {tenArray, tallyUp, imageLock} = useContext(MenuContext)

    // useEffect(()=>{
    //     let BreakException = {}
    //     try{
    //         tenArray.forEach(entry =>{
    //             if(entry.skill===""){
    //                 setCardCheck(false)
    //                 throw BreakException
    //             }
    //             setCardCheck(true)
    //         })
    //     }
    //     catch(e){
    //         if(e !== BreakException) throw e;
    //     }

    //     return () =>{
    //         return null
    //     }
    // },[tenArray])


    return(
        <CompetencyContainer>
        
        
        <CardContainer >
                <GameChangerList>
                    <h2>Game Changer Cards</h2>
                    {
                        tenArray.map(item =>{
                            if(tenArray.indexOf(item) < 2){
                                return(
                                    <CardSet 
                                        key={tenArray.indexOf(item)} 
                                        bg_Color={item.learningPath} 
                                        skill={item.skill} 
                                        devStage={item.devStage} 
                                        val_={item.value}
                                        ind_={tenArray.indexOf(item)}
                                    />
                                    )
                                    }
                                })
                            }
                </GameChangerList>
                <CompetencyList>
                    <h2>Competency Cards</h2>
                    {
                        tenArray.map(item =>{
                            if(tenArray.indexOf(item) > 1){
                                return(
                                    <CardSet 
                                    key={tenArray.indexOf(item)} 
                                    bg_Color={item.learningPath} 
                                    skill={item.skill} 
                                    devStage={item.devStage} 
                                    dev_={item.value} 
                                    val_={item.value}
                                    ind_={tenArray.indexOf(item)}
                                    />
                                    )
                                }
                            })
                        }
                </CompetencyList>
                <p style={{textAlign: "center", fontWeight: "400"}}>Click to generate your Learning Path!</p>
                {
         
            <>
                <CardSubmitButton className='button-class'
                    disabled={imageLock}
                    onClick={()=>{
                    tallyUp()
                    // setResultsActive(true)
                    setTimeout(()=>{
                        setLCard(true)
                    }, 250)
                    setTimeout(()=>{
                        setACard(true)
                    }, 3000)
                    setLock(false)
                    }}
                >
                    <h4>confirm</h4>
                </CardSubmitButton>
            </>
                
            }
            </CardContainer>

        </CompetencyContainer>
    )
}

export default CompetencyBoard