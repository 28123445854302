import {
    Card, 
    CardTray, 
    CardDesc, 
    CardSlot1,
    CardSlot2,
    CardSlot3,
    CardSlot4
} from '../styles/cardPageStyles'
import CardComponent from './CardComponent'
import {useContext, useState} from 'react'
import { MenuContext } from '../utils/menuContext'

const CardSet = ({bg_Color, skill, devStage, val_, ind_}) =>{
    const {cardList, tenArray, setTenArray}=useContext(MenuContext)

    const determinePath = (value) =>{
        let val
        switch(value){
            case 1: val = "master"
            break
            case 2: val = "advanced"
            break
            case 3: val = "intermediate"
            break
            case 4: val = "developing"
            break
            default: val ="developing"
            break
        }
        return val
    }

    const decreaseVal = () =>{
        let tempArray = tenArray.map(item =>{
            if(tenArray.indexOf(item) === ind_){
                if(item.value > 1){
                    return {...item, value: item.value - 1, devStage: determinePath(item.value-1)}
                }
                return item
            }
            else return item
        })
        localStorage.setItem( "list", JSON.stringify({array: tempArray, cards: cardList}))
        setTenArray(tempArray)
    }

    const increaseVal = () =>{
        let tempArray = tenArray.map(item =>{
            if(tenArray.indexOf(item) === ind_){
                if(item.value < 4){
                    return {...item, value: item.value + 1, devStage: determinePath(item.value+1)}
                }
                return item
            }
            else return item
        })
        localStorage.setItem( "list", JSON.stringify({array: tempArray, cards: cardList}))
        setTenArray(tempArray)
    }

    return(
        <CardTray>
            <CardComponent  bg_Color={bg_Color} val_={val_} skill={skill} devStage={devStage} indx_={ind_}/>
            <CardSlot1><p>developing</p><i className="fa-solid fa-chevron-left" onClick={increaseVal} ></i></CardSlot1>
            <CardSlot2><p>intermediate</p></CardSlot2>
            <CardSlot3><p>advanced</p></CardSlot3>
            <CardSlot4><p>master</p><i className="fa-solid fa-chevron-right" onClick={decreaseVal}></i></CardSlot4>
        </CardTray>
    )
}

export default CardSet