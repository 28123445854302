import styled, {css} from 'styled-components'


const windowStyles = css`
    border-radius: 10px;
    background: white;
    box-shadow: -5px 5px 5px #4f4f4f;
    margin-bottom: 2rem;
`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    
`
export const VerticalAligner = styled.div`
    height: 85vh;
    margin-bottom: 3rem;
    h2{
        font-size: 1.25rem;
        color: black;
        margin-bottom: 1rem;
    }
    position: relative;
    overflow-y: scroll;
`

export const ScrollPrompt = styled.p`
    // display: ${props => props.vis? "block" : "none"};
    position: absolute; 
    bottom: 2%;
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: white;
    
    @media(min-height: 750px){
        display: none;
    }
`

export const InnerWindow = styled.div`
    padding: 1rem;
    border-radius: 10px;
    width: 85%;
    // background: white;
    // box-shadow: 0px 0px 8px grey;
    margin: 2rem auto;

    @media (min-width: 750px){
        width: 65%;
    }
    
    @media (min-width: 1000px){
        height: initial;
        padding: 2rem;
       
    }

    @media (min-width: 1300px){
        height: initial;
        padding: 2rem;
        width: 40%;
    }

`


export const UploadContainer = styled.div`
    display: ${props => props.vis? "block" :"none"};
    padding: 1rem;
    ${windowStyles};
    
    h2{
        text-align: center;
    }

`

const listHolderStyles = css`
    border: 0.5px solid black;

    h2{
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.5px solid black;
    }
    max-height: 200px;
    overflow-y: scroll;

    div:last-of-type{
    border: none;
    }

    p{
    font-weight: 500;
    }
`

export const ListHolder = styled.div`
   ${listHolderStyles};
`

export const ListItem = styled.div`
    padding: 0.5rem;
    border-bottom: 0.5px solid black;
    display: flex;
    justify-content: space-between;
`

export const Uploader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;


    input{

        text-align: center;
        width: 80%;
        margin: 2rem auto;
    }

    label{
        font-size: 1.2rem;
        font-weight: 500;
    }
`

export const ReportContainer = styled.div`
    margin: 0 auto;
    margin-bottom: 2rem;
    ${windowStyles};  

    
    @media (min-width: 1000px){
        padding: 1rem;
    }
   
`

const ReportContainerStyles = css`
    padding: 1rem;
    p{
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 400;
    }

    button{
        padding: 0.25rem 0.5rem;
        border: 0.5px solid black;
        border-radius: 5px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
    }

    h2{
        text-align: center;
    }
`

export const ReportStandardContainer = styled.div`
    ${ReportContainerStyles};
    display: ${props => props.vis === 1? "initial" : "none"};
`



export const ReportAdvancedContainer = styled.div`
    ${ReportContainerStyles};
    display: ${props => props.vis === 2? "initial" : "none"};
`

//neeeds to be created:
export const ReportGlobalContainer = styled.div`
    ${ReportContainerStyles};
    display: ${props => props.vis === 2? "initial" : "none"};
`

export const TempIcon = styled.span`
    background: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid black;
    transform: ${props => props.rotation? `rotateZ(45deg)` : 'none'};
    margin-left: 0.5rem;
`



export const ItemControl = styled.div`
    display: flex;
`


export const PersonnelConsole = styled.div`
    background : white;
    border: 0.5px solid black;
    border-bottom: none;
    padding: 0.5rem;
`

export const ConsoleTop = styled.div`
    background : white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input{
        padding: 0.25rem 0.5rem;
        width: 50%;
        border-radius: 5px;
        border: 0.5px solid grey;
    }


    i{
        font-weight: 400;
        transform: rotateZ(-45deg);
        cursor: pointer;
    }
    
    @media (min-width: 1000px){
        i{
            font-size: 1.5rem;
        }
    }
`

export const FilterOption = styled.div`
    border-top: 0.5px solid black;
    width: 90%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    input{
        margin: 1rem auto;
        padding: 0.25rem 0.5rem;
    }

    height: ${props => props.vis? "initial": "0"};
    padding: ${props => props.vis? "1rem": "0"};
    overflow: hidden;
    transition: all 0.25s ease;
`

export const FilterPair = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 1rem auto;
    p{
        margin-right: 1rem;
    }

    i{
        cursor: pointer;
    }

`

export const StatusDisplay = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 0.5px solid black;
    border-radius: 5px;
    padding: 1rem;
    h3{
        max-width: 30%;
    }

   
`