import styled from 'styled-components'

export const EditConfirmPageContainer = styled.div`
    
    h2, h3{
        margin: 0rem;
        text-align: center;
        color: ${props => props.accentColor};
    }

`



