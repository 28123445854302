import styled, {css} from 'styled-components'

const greetingStyles = css`
    text-align: right;
    font-size: ${props => `${props.fs}rem`};
    /* margin-top: ${props => `${props.mt}vh`}; */
    padding-top: 5vh;
    transform: ${props => props.vis? "translateY(vh)" : `translateY(-20vh)`};
    transition: all ${props => `${props.aTime}s`} ease;

`

// export const InitialGreeting = styled.h1`
//     ${greetingStyles};
//     margin-bottom: ${props => props.lift? ""}
// `
export const GreetingContainer = styled.h1`
    ${greetingStyles};
`