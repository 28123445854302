import axios from 'axios'


const mailUrl = `https://competency-emails.herokuapp.com/`
// const mailUrl = `http://localhost:5001/`
 

   export const sendEmail = async(obj) =>{
    //   setSent(true)
      try{
        const response = await axios.post(`${mailUrl}emails`, obj)
        if(response){
          return response
        }
      }
      catch(err){
        console.log(err)
      }
  
    }

    
  
    export const sendForgotEmail = async(obj) =>{
      //   setSent(true)
        try{
          const response = await axios.post(`${mailUrl}reset/send`, obj)
          if(response){
            return response
          }
        }
        catch(err){
          console.log(err)
        }
    
      }


    export const emailActivate = async() =>{
      return(
        await axios.get( mailUrl)
        .then(res => res)
        .catch(err => err)
      )
    }

    