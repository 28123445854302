import styled, { createGlobalStyle } from "styled-components"


export const GlobalStyle = createGlobalStyle` 
    *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
        /* text-shadow: 0px 0px 0.85px black; */
        
    }

    body{
        
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        background-image: ${props => props.bottomColor? `linear-gradient(to top, ${props.bottomColor}, ${props.topColor})`:
            "linear-gradient(to top, #37A89A, white)"
        };

        height: 100vh;
        overflow: hidden;
    }

    

    a{
        text-decoration: none;
        /* text-shadow: 0px 0px 1px grey; */
        color: #11898A;
    }
    a:visited{
          color: #11898A;
    }


    h1,h2,h3,h4{
        font-family: 'Chivo', sans-serif;
        font-weight: 700;
        color: ${props => props.accentColor? props.accentColor : "black"};
       
    }

    i{
        color: ${props => props.accentColor? props.accentColor : "black"};
       

    }
    
    h1{
        color: ${props => props.titleColor? props.titleColor : "#37A89A"};
    }

`

export const ResultScreen = styled.div`
    position: absolute; 
    background: rgba(0,0,0,0.5); 
    top: 0; 
    bottom: 0; 
    width: 100vw; 
    z-index: 2;
`