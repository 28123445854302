import styled from 'styled-components'


export const AssessmentContainer = styled.div`
    border: 1px solid black;
    width: 85vw;
    margin: 0 auto;
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: white;
    color: black;
`
export const WarningContainer = styled.div`
    border: 1px solid black;
    width: 85vw;
    height: 40vh;
    margin: 0 auto;
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1rem;

    p{
        line-height: 2rem;
    
    }
`

export const EntryContainer = styled.div`
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    
`

export const AssessmentTop = styled.div`
    h1{
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid black;
        // margin-bottom 1rem;
    }
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const RadialButton = styled.div`
    width: 20px;
    background-color: ${({selected})=>selected? "green" : "lightgrey"};
`

export const AssessmentBottom = styled.div`
    overflow: hidden;
    height: ${({expanded})=>expanded? "65vh" : "10vh"};
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: height 0.5s ease-out;
`

export const ChevronContainer = styled.div`
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 0.5rem;
    i{
        margin:0;
        padding: 0;
        color: black;
    }
`

export const AssessmentList = styled.div`
    h2{
        color: ${({titleColor}) => titleColor};
        margin-bottom: 0.5rem;
        text-align: center;

    }
    
    overflow: scroll;
    padding: 1rem;
    max-height: 85%;    
`

export const CardListItem = styled.div`
    display: flex; 
    flex-direction: space-between;
    border-bottom: 1px solid grey;
    margin-bottom: 0.75rem;
    padding: 0.5rem;
    span{
        width: 25%;
    }
    p{
        width: 75%;
        text-align: right;
    }

    &:p
`