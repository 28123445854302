import styled, {css} from 'styled-components'


export const ClientLogoContainer = styled.img`
    opacity: ${props => props.vis? "1" : "0"};
    max-height: ${props => `${props.logoHeight}px`};
    max-width: ${props => `${props.logoHeight}px`};
    transition: ${props => `opacity ${props.aTime}s ease`}; 
 
`
    

