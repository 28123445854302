import { 
    LogoPair,
    LogoMessage, 
    LogoImage
} from "../pages/Main/mainStyles"
import Move from '../components/Move/Move'
import { 
    getCompanies, 
    sendEmployeeInfo, 
    checkEmployeeInfo,
    getCards,
    getCompany,
    replaceNewEmployeeInfo,
    replacePreviousEmployeeInfo,
    searchEmployee,
    sendInitial
} from "../services/dataServices"
import AboutForm from "../components/Content/children/AboutContainer/AboutForm"
import Scrollable from '../components/Scrollable/Scrollable'
import {EditConfirmPageContainer} from "../pages/EditConfirmPage/editConfirmPageStyles"
import { EndMessage } from "../components/Content/contentStyles"
import InfoEditScreen from "../components/InfoEditScreen/InfoEditScreen"
import Assessment from '../components/Assessment/Assessment'
import FirstEmail from "../components/FirstEmail/FirstEmail"
import * as XLSX from "xlsx"
import ClientLogo from "../components/Content/children/ClientLogo/ClientLogo"
import ImageMod from "../components/ImageMod/ImageMod"
import CardArray from "../components/CardArray/CardArray"
import ReviewScroll from "../components/ReviewScroll/ReviewScroll"
import CompetencyBoard from "../components/ComptenecyBoard/CompetencyBoard"
import LastModal from '../components/LastModal/LastModal'
import Loader from '../components/Loader/Loader'
import PreviousAssessmentButton from '../components/PreviousAssessmentButton/PreviousAssessmentButton'
import { ScrollPrompt, PreviousButton } from "../components/ComptenecyBoard/competencyBoardStyles"
import CafeSubmission from "../components/CafeSubmission/CafeSubmission"

// const fakeContact =[
//     {firstName: "John",
//     lastName: "Smith",
//     email: "j.smith@company.com",
//     managerEmail: "j.doe@company.com",
//     industryYears: 3,
//     jobTitle: "accountant",
//     territoryCode: 2}
// ]



// const handleExport = () =>{
//     //make a new work book
//     let workBook = XLSX.utils.book_new()
//     //converts param into a work sheet
//     let workSheet = XLSX.utils.json_to_sheet(fakeContact)

//     XLSX.utils.book_append_sheet(workBook, workSheet, "First Sheet")

//     XLSX.writeFile(workBook, "competency-contact.xlsx")
// }


export const frameData = (extras, designation) =>{
    const {contact, setContact, setCurrentFrame, aboutButtonLock, setAboutButtonLock, welcomeForward, setWelcomeForward, setWelcomeStart, welcomeStart,
    company, setCompany, companies, aboutForward, setAboutForward, previousClientInfo, setPreviousClientInfo, clientInfoCheck, setClientInfoCheck, setLatestCompletion,
    helloReturn,dateRange, editOnlySwitch, cardList, setCardList, email, firstEmailAccept, clientLogo, initialEmployeeId,
    currentImage, setCurrentImage, foundContact, assessmentForward, setAssessmentForward, gameBoardForward, setGameBoardForward,
    welcomeLoading, forwardLock, setForwardLock, returnLock, setReturnLock, managerEmail, fullStringFormatter, startLock, setStartLock,
    assessmentLock, setAssessmentLock, gameBoardLock, setGameBoardLock, gameBoardReturnLock, setGameBoardReturnLock, assessmentReturnLock, setAssessmentReturnLock,
    modalActive, setModalActive, tallyUp, determinePathResults, colorArray, setFinalResults, navLoading, setNavLoading, popUpInfo, setPopUpInfo,
    prevPair, setPrevPair, setAssessmentPopUp, setWarning, setWarningPopUp
} = extras     
    
  

    // const companySelectOnChange = (e) =>{
    //     setWelcomeForward(true)
    //     setCompany(companies[e.target.value])
    //     getCards(setCardList, {company: companies[e.target.value]._id})
    // }

    const safeStart = () =>{
        if(!startLock){
            setStartLock(true)
            firstEmailAccept()
            setWelcomeForward(false)
        }
    }

    // const companySelectOnEmail = (e) =>{
    //     setWelcomeForward(true)
    //     setCompany(companies[e.target.value])
    //     getCards(setCardList, {company: companies[e.target.value]._id})
    // }

    const aboutAdvance = async() =>{
       
        if(!forwardLock){
            setNavLoading(true)
            setForwardLock(true)
    


            await getCompany(contact.email)
            .then(async(res) =>{


                    if(typeof res.data === "string"){
                        setForwardLock(false)
                        setNavLoading(false)
                        alert(res.data)
                    }             
                    
                
                    if(typeof res.data === "boolean"){
                        setForwardLock(false)
                        setNavLoading(false)
                        alert("invalid company domain entered for your email")
                    }

                    if(typeof res.data === "object"){
     
                        await searchEmployee(contact.email)
                        .then(async(res) => {
                            const employeeObject = res.data
                        
                            if(typeof res.data === "object"){
                            
                                            setClientInfoCheck(false)
                                        
                                            await getCompany(managerEmail)
                                                    .then(async(res) =>{

        
                                                if(typeof res.data === "string"){
                                                    setForwardLock(false)
                                                    setNavLoading(false)
                                                    alert(res.data)
                                                }             
                                                
                                            
                                                if(typeof res.data === "boolean"){
                                                    setForwardLock(false)
                                                    setNavLoading(false)
                                                    alert("invalid company domain entered for manager's email")
                                                
                                                }

                                                if(typeof res.data === "object"){

                                                await replaceNewEmployeeInfo({...contact,
                                                        assessmentImage: employeeObject.assessmentImage
                                                    })
                                                    .then(res =>{
                                                        
                                                        
                                                        if(res.data === true){

                                                            
                                                        
                                                            setContact({...contact,
                                                                assessmentImage: employeeObject.assessmentImage,
                                                                employee_id: employeeObject._id
                                                            })
                                
                                                            setCurrentFrame(prev => prev+1)
                                                            setNavLoading(false)
                                                            setTimeout(()=>{
                                                                setForwardLock(false)
                                                                
                                                            }, 3000)    
                                
                                                            return
                                                        }
                                                        
                                                        setForwardLock(false)
                                                        setNavLoading(false)
                                                        alert(res.data)

                                                    })
                                                    .catch(err => {console.log(err)})
                                                }
                        
                                    }).catch(err => alert("error"))
                }  
                // else if(contact.managerEmail !== undefined){
                //             console.log('replacePreviousEmployee: '+JSON.stringify(contact))
                //             await replacePreviousEmployeeInfo({...contact,
                //                     assessmentImage: employeeObject.assessmentImage,
                //                     prevEmail: prevPair.email
                //                 })
                //                 .then(res =>{
                                    
                                    
                //                     if(res.data === true){

                                        
                                    
                //                         setContact({...contact,
                //                             assessmentImage: employeeObject.assessmentImage,
                //                             employee_id: prevPair.employee_id
                //                         })
            
                //                         setCurrentFrame(prev => prev+1)
                //                         setNavLoading(false)
                //                         setTimeout(()=>{
                //                             setForwardLock(false)
                                            
                //                         }, 3000)    
            
                //                         return
                //                     }
                                    
                //                     setForwardLock(false)
                //                     setNavLoading(false)
                //                     alert(res.data)

                //                 })
                //                 .catch(err => {console.log(err)})
                          
    
                       
                // } 
            })
            .catch(err => alert(err))
    
                    }

        }).catch(err => alert("error"))
            
            }
    }


    const aboutWarning = () =>{
        setWarning("All fields are required. Check for missing entries, misspelled or non-company email addresses, and missing or incomplete phone numbers while opting in is checked.")
        setWarningPopUp(true)
    }

    const advanceFrame = (lock, lockSetter) =>{
      
        if(!lock){
            lockSetter(true)
            setCurrentFrame(prev => prev+1)
            setTimeout(()=>{
                lockSetter(false)
            }, 3000)
        }
        
    }

    const returnFrame = (lock, lockSetter) =>{
        if(!lock){
            lockSetter(true)
            setCurrentFrame(prev => prev-1)
            setTimeout(()=>{
                lockSetter(false)
            }, 3000)
        }
        
    }

    const welcomeFrame = { 
        frame:{
            // pd: 2,
            startPoint: "0rem",
            endPoint : "30rem",
            index : 0,
            navigationOn: false,
            bodyMt: 20,
            bodyHt: 5,
            navMt: 20,
            navJc : "center"
        },
        greeting:{
            vis: welcomeStart,
            aTime: 2,
            message:"Welcome",
            fs: 2.5,
            // mt: 5,
            translate : 25,
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            // message: "select your company to begin",
            message: "Enter your company email to begin",
            mt: 31,
            aTime: 2
        },
        content:{
            company: company,
            aTime: 1.5,
            vis: welcomeStart,
            display: "block",
            position: "relative",
            mt: 5,
            mHeight: 25,
            children: 
            <>  

                {/* the nature of the welcome frame's content children, is the only slight
                variance in the pattern. Since the first element needs to act alone, there's
                chance you'll need a custom component, as everythign else has to follow
                certain patterns, in this case there's a custom first email component (needs state).
                prior to that it was a company selection. Said select had to be styled by making a 
                className that then was targeted within the contentStyles file. */}

                {/* <select 
                    className="contact-select" 
                    accentcolor={company !== undefined && company.accentColor} 
                    bottomcolor= {company !== undefined && company.bottomColor} 
                    onChange={(e)=>{companySelectOnChange(e)}}>
                    <option hidden>select a company</option>
                    {companies && companies.map(entry =>{
                        
                        return(
                            <option key={entry._id} className="option" value={companies.indexOf(entry)}>{entry.name}</option>
                        )
                    })}
                </select> */}

                {/* sidenote: The FirstEmail's vis prop being set to welcomeStart might be 
                redundant since the welcomeFrame.content.children's vis is also set to welcomeStart. */}
                <FirstEmail 
                    aTime = {2.5}
                    vis = {welcomeStart}
                    // companySelectOnEmail = {companySelectOnEmail}
                />

                {   welcomeLoading ?
                
                    <Loader 
                        accentColor={company !== undefined && company.accentColor}  
                        iconSize={"1.5rem"}
                        mb={"0vh"}
                        mt={"5vh"}
                    />

                    :

                    <Move utils={
                        {
                         vis: welcomeForward,
                         ai: "baseline",
                         mt: 5,
                         iFs: 0.8,
                         mr: 0.25,
                         aTime: 0.25,
                         action: safeStart,
                         prompt: "start",
                         symbol: <i className="fa-solid fa-chevron-right" onClick={safeStart}></i>,
                         color: "white",
                         order: "row"
                        }
                     } />
                    
                }

                

                

                <div style={{display: "flex", justifyContent: "center"}}>
                    <ClientLogo 
                    vis={clientLogo} 
                    aTime={1.25}
                    logo={clientLogo} 
                    logoHeight={company !== undefined && company.logoHeight} 
                    logoWidth={company !== undefined && company.logoWidth} 
                    />
                </div>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: true,
            symbol:  <LogoMessage initiate={welcomeStart} aTime={2}  >Powered by</LogoMessage>,
            promptFs: 0.6,
        },
        moveForward:{
            company: false,
            vis:true,
            symbol: <LogoImage initiate={welcomeStart}  aTime={2.75}/>
            // <LogoPair initiate={welcomeStart} aTime={2} >
            // <LogoImage initiate={welcomeStart}  aTime={2.75}/>
            // </LogoPair>
        }  
    }

    const aboutFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "30rem",
            // index : 0,
            navigationOn: true,
            bodyMt: 5,
            bodyHt: 70,
            navMt: 30
        },
        greeting:{
            vis: welcomeStart,
            aTime: 2,
            message:"About You",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "Fill out all fields to create your competency cards profile",
            aTime: 2
        },
        body:{
            style: true,
            display: "flex",
            fDirection: "column",
            jc: "space-between",
            mHeight: 65
        },
        content:{
            company: company,
            aTime: 1.5,
            vis: welcomeStart,
            display: "block",
            position: "relative",
            mt: 5,
            children: 
            <>
                {/* The AboutForm component file is location within the Content component folder
                as child of the Content, which have their own folder nested within */}
                <AboutForm standard={true}/>
                {/* <button onClick={handleExport}>Export</button> */}
            </> 
        },
        moveBack:{
            company: company,
            vis: false,
            // symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            // mt: 25.23,
            // vis: forwardLock === undefined? aboutForward : !forwardLock,
            vis: true,
            prompt: navLoading?   "" : "next",
            action: ()=>{
                aboutForward === true? 

                aboutAdvance()
                :
                alert("All fields are required, check for missing entries")
            },
            actionLock: forwardLock,
            symbol: navLoading?   
            <Loader 
                accentColor={company !== undefined && company.accentColor}  
                iconSize={"1.5rem"}
                mb={"0vh"}
                mt={"5vh"}
            />  
            : 
            <i className="fa-solid fa-angle-right" onClick={()=>{
                aboutForward === true? 

                aboutAdvance()
                :
                alert("All fields are required, check your entries")
            }}></i>
            
        }  
    }

    const aboutFrameModified = { 
        frame:{
            startPoint: "0rem",
            endPoint : "30rem",
            // index : 0,
            navigationOn: true,
            bodyMt: 5,
            bodyHt: 70,
            navMt: 30
        },
        greeting:{
            vis: welcomeStart,
            aTime: 2,
            message:"About You",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "Fill out all fields to create your competency cards profile",
            aTime: 2
        },
        body:{
            style: true,
            display: "flex",
            fDirection: "column",
            jc: "space-between",
            mHeight: 65
        },
        content:{
            company: company,
            aTime: 1.5,
            vis: welcomeStart,
            display: "block",
            position: "relative",
            mt: 5,
            children: 
            <>
                {/* The AboutForm component file is location within the Content component folder
                as child of the Content, which have their own folder nested within */}
                <AboutForm standard={true}/>
                {/* <button onClick={handleExport}>Export</button> */}
            </> 
        },
        moveBack:{
            company: company,
            vis: false,
            // symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            // mt: 25.23,
            // vis: forwardLock === undefined? aboutForward : !forwardLock,
            vis: true,
            prompt: navLoading?   "" : "next",
            action: ()=>{
                aboutForward === true? 

                aboutAdvance()
                :
                aboutWarning()
            },
            actionLock: forwardLock,
            symbol: navLoading?   
            <Loader 
                accentColor={company !== undefined && company.accentColor}  
                iconSize={"1.5rem"}
                mb={"0vh"}
                mt={"5vh"}
            />  
            : 
            <i className="fa-solid fa-angle-right" onClick={()=>{
                aboutForward === true? 

                aboutAdvance()
                :
                aboutWarning()
            }}></i>
            
        }  
    }

    const assessmentFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "30rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5,
        },
        greeting:{
            vis: welcomeStart,
            aTime: 2,
            message:"Assessment",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "Click on cards to search skills for 2 gamechangers and 8 competencies. Use side arrows to adjust development stage.",
            aTime: 2,
            
        },
        body:{
            style: true,
            display: "flex",
            fDirection: "column",
            jc: "space-between",
        },
        content:{
            company: company,
            aTime: 1.5,
            vis: welcomeStart,
            display: "block",
            position: "relative",
            children: 
            <>
                <CompetencyBoard />
                    <ScrollPrompt
                        accentColor={company !== undefined && company.accentColor}
                    >
                    Scroll down inside the window to view all 10 cards
                    <i className="fa-regular fa-hand-pointer"></i>
                </ScrollPrompt>
            </> 
        },
        popUp:{
            active: true,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: true,
            symbol: <i className="fa-solid fa-angle-left" onClick={()=>{returnFrame(assessmentReturnLock, setAssessmentReturnLock) }}></i>
        },
        moveForward:{
            company: company,
            // vis: forwardLock === undefined? assessmentForward : !forwardLock,
            vis: assessmentForward,
            aTime: 1,
            prompt: "next",
            action: ()=>{advanceFrame(assessmentLock, setAssessmentLock) ; tallyUp()},
            symbol: <i className="fa-solid fa-angle-right" onClick={()=>{advanceFrame(assessmentLock, setAssessmentLock) ; tallyUp()} }></i>
          
        }  
    }

    //modified version of the assessment from to setModalActive to true, in order to jump
    //to the submitFrame directly (since the uploadFrame is ommitted in the noImageArray)
    const assessmentFrameModified = { 
        frame:{
            startPoint: "0rem",
            endPoint : "30rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5,
        },
        greeting:{
            vis: welcomeStart,
            aTime: 2,
            message:"Assessment",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "Click on cards to search skills for 2 gamechangers and 8 competencies. Use side arrows to adjust development stage.",
            aTime: 2,
            
        },
        body:{
            style: true,
            display: "flex",
            fDirection: "column",
            jc: "space-between",
        },
        content:{
            company: company,
            aTime: 1.5,
            vis: welcomeStart,
            display: "block",
            position: "relative",
            children: 
            <>
                <CompetencyBoard />
                    <ScrollPrompt
                        accentColor={company !== undefined && company.accentColor}
                    >
                    Scroll down inside the window to view all 10 cards
                    <i className="fa-regular fa-hand-pointer"></i>
                </ScrollPrompt>
                <PreviousButton accentColor={company.accentColor} onClick={()=>{setAssessmentPopUp(true)}}>Previous Assessments</PreviousButton>
            </> 
        },
        popUp:{
            active: true,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: true,
            symbol: <i className="fa-solid fa-angle-left" onClick={()=>{returnFrame(assessmentReturnLock, setAssessmentReturnLock) }}></i>
        },
        moveForward:{
            company: company,
            // vis: forwardLock === undefined? assessmentForward : !forwardLock,
            vis: assessmentForward,
            aTime: 1,
            prompt: "next",
            action: ()=>{advanceFrame(assessmentLock, setAssessmentLock) ; tallyUp() ; setTimeout(()=>{setModalActive(true)}, 1000)},
            symbol: <i className="fa-solid fa-angle-right" onClick={()=>{advanceFrame(assessmentLock, setAssessmentLock) ; tallyUp() ; setTimeout(()=>{setModalActive(true)}, 1000)}}></i>
          
        }  
    }
    
    const uploadFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"Upload",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "upload a photo of your gameboard. Shooting portrait style is encouraged for best results. Upload times may vary depending on file sizes",
            aTime: 2,

        },
        body:{
            style: true,
            display: "flex",
            fDirection: "column",
            jc: "space-between"
        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 50,
            children: 
            <>
                <ImageMod
                    message="no file chosen"
                    accentColor={company !== undefined && company.accentColor} 
                    bottomColor={company !== undefined && company.bottomColor} 
                />
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: true,
            alt_vis: returnLock,
            symbol: <i className="fa-solid fa-angle-left" onClick={()=>{returnFrame(gameBoardReturnLock, setGameBoardReturnLock)}}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: gameBoardForward,
            alt_vis: forwardLock,
            prompt: "submit",
            action: ()=>{advanceFrame(gameBoardLock, setGameBoardLock); setFinalResults(determinePathResults(colorArray)) ; setTimeout(()=>{setModalActive(true)}, 1000)},
            symbol: <i className="fa-solid fa-angle-right" onClick={()=>{advanceFrame(gameBoardLock, setGameBoardLock); setFinalResults(determinePathResults(colorArray)) ;  setTimeout(()=>{setModalActive(true)}, 1000)}}></i>


        }  
    }

    const submitFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"Done!",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "select from 2 suggested learning paths, or, choose your own",
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 5,
            children: 
            <>
                <LastModal company={company}/>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: false,
            symbol: <i className="fa-solid fa-angle-left" onClick={returnFrame}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: false,
            prompt: "submit",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    //modified version of the submitFrame, to feature cafes instead of selectable paths
    //colors are now implied but not shown as results
    //The first suggested path will dictate the "suggested" cafes to be highlighted
    //per month drop-down
    const submitFrameModified = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"Done!",
            fs: 2.5, 
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "Choose 5 topics you’d like to focus on in 2024.  Some topics will be suggested by your card sort, but choose the topic that is most valuable to you",
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 2,
            children: 
            <>
                <CafeSubmission/>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: false,
            symbol: <i className="fa-solid fa-angle-left" onClick={returnFrame}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: false,
            prompt: "submit",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    const confirmFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "",
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 5,
            children: 
            <>
                <EndMessage  accentColor={company !== null && company.accentColor}>
                    Your assessment is complete and has been submitted. Your manager will be notified and you will receive a confirmation at {email}. You can close this window at anytime.
                </EndMessage>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: false,
            symbol: <i className="fa-solid fa-angle-left" onClick={returnFrame}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: false,
            prompt: "submit",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    const verifiedConfirmFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true,
            bodyHt: 70,
            bodyMt: 5
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "",
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 5,
            children: 
            <>
                <EndMessage  accentColor={company !== null && company.accentColor}>
                    Thank you for submitting your selections, however, we verified your selections have already been completed for your 5 topics. Your new choices will not overwrite your previously submitted ones in order to preserve your records. You can close this window at any time. 
                </EndMessage>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: false,
            symbol: <i className="fa-solid fa-angle-left" onClick={returnFrame}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: false,
            prompt: "submit",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    const helloAgainFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            bodyMt: 5,
            navigationOn: true
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"Hello Again",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            message: "It looks like you've been here before!",
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 10,
            children: 
            <>
                <ul className="hello-list">
                    <li>If you've received an email, your assessment was completed. Continue to assessment to overwrite previous results.</li>
                    <li>Scroll to compare current and previous contact information.</li>
                    <li>Close the window if you don't wish to edit your previous entries.</li>
                </ul>
                <Scrollable accentColor={company !== undefined && company.accentColor} >
                    <InfoEditScreen
                        standard={true} 
                        helloReturn={helloReturn} 
                        accentColor={company !== undefined && company.accentColor} 
                        previousClientInfo={previousClientInfo}
                        bottomColor={company !== undefined && company.bottomColor}
                    />
                </Scrollable>
            </> 
        },
        popUp:{
            active: false,
            message: "pop up here"
        },
        moveBack:{
            company: company,
            vis: helloReturn,
            alt_vis: returnLock,
            symbol: <i className="fa-solid fa-angle-left" onClick={returnFrame}></i>
        },
        
        moveForward:{
            company: company,
            mr: 0.4,
            vis: aboutForward,
            alt_vis: forwardLock,
            prompt: "next",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    const editConfirmFrame = { 
        frame:{
            startPoint: "0rem",
            endPoint : "25rem",
            index : 0,
            navigationOn: true
        },
        greeting:{
            vis: true,
            aTime: 2,
            message:"",
            fs: 2.5,
            translate : 25
        },
        instructions:{
            company: company,
            vis: welcomeStart,
            aTime: 2,

        },
            content:{
            company: company,
            aTime: 1.5,
            vis: true,
            display: "block",
            position: "relative",
            mt: 10,
            children: 
            <>
            <EditConfirmPageContainer 
                titleColor= {company !== undefined && company.titleColor} 
                accentColor={company !== undefined && company.accentColor} 
            >
                {/* <h2>your new information is listed below</h2> */}
                
                <InfoEditScreen 
                        helloReturn={helloReturn} 
                        previousClientInfo={previousClientInfo}
                        accentColor={company !== undefined && company.accentColor} 
                        bottomColor={company !== undefined && company.bottomColor}
                    />

                <h3>You may close this window at any time</h3>
            </EditConfirmPageContainer>
            </> 
        },
        moveBack:{
            company: company,
            vis: false,
            symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
        },
        moveForward:{
            company: company,
            mr: 0.4,
            vis: false,
            prompt: "next",
            symbol: <i className="fa-solid fa-angle-right"></i>

        }  
    }

    
    const returnArray = [welcomeFrame, aboutFrame, assessmentFrame, uploadFrame, submitFrame, confirmFrame]

    const noImageArray = [welcomeFrame, aboutFrameModified, assessmentFrameModified, submitFrameModified, confirmFrame, verifiedConfirmFrame]

    const alteredArray = [welcomeFrame, aboutFrame, helloAgainFrame, assessmentFrame, gameBoardForward, submitFrame, confirmFrame]

    const editOnlyArray = [welcomeFrame, aboutFrame, helloAgainFrame, editConfirmFrame]
    ; setTimeout(()=>{}, 1000)

    //Original alternative arrays:

    // const alteredArray = [

    //     ////////////////////////////////////////////////////////////// 
    //     /// WELCOME FRAME
    //     //////////////////////////////////////////////////////////////
        


    //     { 
    //         frame:{
    //             // pd: 2,
    //             startPoint: "0rem",
    //             endPoint : "30rem",
    //             index : 0,
    //             navigationOn: false,
    //             bodyMt: 25,
    //             bodyHt: 55,
    //             navMt: 5,
    //             navJc : "center"
    //         },
    //         greeting:{
    //             vis: welcomeStart,
    //             aTime: 2,
    //             message:"welcome",
    //             fs: 2.5,
    //             // mt: 5,
    //             translate : 25,
    //         },
    //         instructions:{
    //             company: company,
    //             vis: welcomeStart,
    //             // message: "select your company to begin",
    //             message: "enter your email to begin",
    //             mt: 31,
    //             aTime: 2
    //         },
    //         content:{
    //             company: company,
    //             aTime: 1.5,
    //             vis: welcomeStart,
    //             display: "block",
    //             position: "relative",
    //             mt: 5,
    //             children: 
    //             <>  
    //                 {/* <div className="client-logo">
    //                     hello
    //                 </div> */}
    //                 {/* then nature of the welcome frame's content children, is the only slight
    //                 variance in the patter. Since the first element needs to act alone, there's
    //                 chance you'll need a custom component, as the everythign else has to follow
    //                 certain patterns, in this case there's a custom first email component (needs state).
    //                 prior to that it was a company selection. Said select had to be styled by making a 
    //                 className that then was targeted within the contentStyles file. */}

    //                 <FirstEmail 
    //                     aTime = {2.5}
    //                     vis = {welcomeStart}
    //                 />

    //                 {/* <select 
    //                     className="contact-select" 
    //                     accentcolor={company !== undefined && company.accentColor} 
    //                     bottomcolor= {company !== undefined && company.bottomColor} 
    //                     onChange={(e)=>{companySelectOnChange(e)}}>
    //                     <option hidden>select a company</option>
    //                     {companies && companies.map(entry =>{
                            
    //                         return(
    //                             <option key={entry._id} className="option" value={companies.indexOf(entry)}>{entry.name}</option>
    //                         )
    //                     })}
    //                 </select> */}
    //                 <Move utils={
    //                    {
    //                     vis: welcomeForward,
    //                     ai: "baseline",
    //                     mt: 5,
    //                     iFs: 0.8,
    //                     mr: 0.25,
    //                     aTime: 1,
    //                     action: ()=>{welcomeForward && firstEmailAccept()},
    //                     prompt: "start",
    //                     symbol: <i className="fa-solid fa-chevron-right" onClick={() => welcomeForward && firstEmailAccept()}></i>,
    //                     color: "white",
    //                     order: "row"
    //                    }
    //                 } />

    //                 <ClientLogo 
    //                  vis={clientLogo} 
    //                  aTime={1.25}
    //                  logo={company !== undefined && company.logo} 
    //                  logoHeight={company !== undefined && company.logoHeight} 
    //                  logoWidth={company !== undefined && company.logoWidth} 
    //                  />
    //             </> 
    //         },
    //         moveBack:{
    //             company: company,
    //             vis: true,
    //             symbol:  <LogoMessage initiate={welcomeStart} aTime={2}  >Powered by</LogoMessage>,
    //             promptFs: 0.6,
    //         },
    //         moveForward:{
    //             company: false,
    //             vis:true,
    //             symbol: <LogoImage initiate={welcomeStart}  aTime={2.75}/>
    //             // <LogoPair initiate={welcomeStart} aTime={2} >
    //             // <LogoImage initiate={welcomeStart}  aTime={2.75}/>
    //             // </LogoPair>
    //         }  
    //     },





    //     ////////////////////////////////////////////////////////////// 
    //     /// ABOUT FRAME
    //     //////////////////////////////////////////////////////////////





    //     { 
    //         frame:{
    //             startPoint: "0rem",
    //             endPoint : "30rem",
    //             // index : 0,
    //             navigationOn: true
    //         },
    //         greeting:{
    //             vis: welcomeStart,
    //             aTime: 2,
    //             message:"about you",
    //             fs: 2.5,
    //             translate : 25
    //         },
    //         instructions:{
    //             company: company,
    //             vis: welcomeStart,
    //             message: "fill out all fields to create your assessment profile",
    //             aTime: 2,
   
    //         },
    //         content:{
    //             company: company,
    //             aTime: 1.5,
    //             vis: welcomeStart,
    //             display: "block",
    //             position: "relative",
    //             mt: 10,
    //             children: 
    //             <>
    //                 <AboutForm standard={true}/>
    //                 {/* <button onClick={handleExport}>Export</button> */}
    //             </> 
    //         },
    //         moveBack:{
    //             company: company,
    //             vis: false,
    //             symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
    //         },
    //         moveForward:{
    //             company: company,
    //             mr: 0.4,
    //             vis: aboutForward,
    //             prompt: "assessment",
    //             action: aboutAdvance,
    //             symbol: <i className="fa-solid fa-angle-right" onClick={aboutAdvance}></i>

    //         }  
    //     },






    //     ////////////////////////////////////////////////////////////// 
    //     /// HELLO AGAIN FRAME
    //     //////////////////////////////////////////////////////////////


        
    //     helloAgainFrame,




    //     ////////////////////////////////////////////////////////////// 
    //     /// ASSESSMENT FRAME
    //     //////////////////////////////////////////////////////////////





    //     { 
    //         frame:{
    //             startPoint: "0rem",
    //             endPoint : "30rem",
    //             index : 0,
    //             navigationOn: true
    //         },
    //         greeting:{
    //             vis: welcomeStart,
    //             aTime: 2,
    //             message:"assessment",
    //             fs: 2.5,
    //             translate : 25
    //         },
    //         instructions:{
    //             company: company,
    //             vis: welcomeStart,
    //             message: "selections should reflect your skill card choices of 2 gamechangers and 8 competencies",
    //             aTime: 2,
   
    //         },
    //         content:{
    //             company: company,
    //             aTime: 1.5,
    //             vis: welcomeStart,
    //             display: "block",
    //             position: "relative",
    //             mt: 10,
    //             children: 
    //             <>

    //             </> 
    //         },
    //         moveBack:{
    //             company: company,
    //             vis: true,
    //             symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
    //         },
    //         moveForward:{
    //             company: company,
    //             mr: 0.4,
    //             vis: aboutForward,
    //             prompt: "game board",
    //             action: aboutAdvance,
    //             symbol: <i className="fa-solid fa-angle-right" onClick={aboutAdvance}></i>

    //         }  
    //     },



        
    //     ////////////////////////////////////////////////////////////// 
    //     /// HELLO AGAIN FRAME
    //     //////////////////////////////////////////////////////////////
    // ]

    // const editOnlyArray = [

    //     ////////////////////////////////////////////////////////////// 
    //     /// WELCOME FRAME
    //     //////////////////////////////////////////////////////////////
        




    //     { 
    //         frame:{
    //             // pd: 2,
    //             startPoint: "0rem",
    //             endPoint : "30rem",
    //             index : 0,
    //             navigationOn: false,
    //             bodyMt: 25,
    //             bodyHt: 55,
    //             navMt: 5,
    //             navJc : "center"
    //         },
    //         greeting:{
    //             vis: welcomeStart,
    //             aTime: 2,
    //             message:"welcome",
    //             fs: 2.5,
    //             // mt: 5,
    //             translate : 25,
    //         },
    //         instructions:{
    //             company: company,
    //             vis: welcomeStart,
    //             // message: "select your company to begin",
    //             message: "enter your email to begin",
    //             mt: 31,
    //             aTime: 2
    //         },
    //         content:{
    //             company: company,
    //             aTime: 1.5,
    //             vis: welcomeStart,
    //             display: "block",
    //             position: "relative",
    //             mt: 5,
    //             children: 
    //             <>  
    //                 {/* <div className="client-logo">
    //                     hello
    //                 </div> */}
    //                 {/* then nature of the welcome frame's content children, is the only slight
    //                 variance in the patter. Since the first element needs to act alone, there's
    //                 chance you'll need a custom component, as the everythign else has to follow
    //                 certain patterns, in this case there's a custom first email component (needs state).
    //                 prior to that it was a company selection. Said select had to be styled by making a 
    //                 className that then was targeted within the contentStyles file. */}

    //                 <FirstEmail 
    //                     aTime = {2.5}
    //                     vis = {welcomeStart}
    //                 />

    //                 {/* <select 
    //                     className="contact-select" 
    //                     accentcolor={company !== undefined && company.accentColor} 
    //                     bottomcolor= {company !== undefined && company.bottomColor} 
    //                     onChange={(e)=>{companySelectOnChange(e)}}>
    //                     <option hidden>select a company</option>
    //                     {companies && companies.map(entry =>{
                            
    //                         return(
    //                             <option key={entry._id} className="option" value={companies.indexOf(entry)}>{entry.name}</option>
    //                         )
    //                     })}
    //                 </select> */}
    //                 <Move utils={
    //                    {
    //                     vis: welcomeForward,
    //                     ai: "baseline",
    //                     mt: 5,
    //                     iFs: 0.8,
    //                     mr: 0.25,
    //                     aTime: 1,
    //                     action: ()=>{welcomeForward && firstEmailAccept()},
    //                     prompt: "start",
    //                     symbol: <i className="fa-solid fa-chevron-right" onClick={() => welcomeForward && firstEmailAccept()}></i>,
    //                     color: "white",
    //                     order: "row"
    //                    }
    //                 } />

    //                 <ClientLogo 
    //                  vis={clientLogo} 
    //                  aTime={1.25}
    //                  logo={company !== undefined && company.logo} 
    //                  logoHeight={company !== undefined && company.logoHeight} 
    //                  logoWidth={company !== undefined && company.logoWidth} 
    //                  />
    //             </> 
    //         },
    //         moveBack:{
    //             company: company,
    //             vis: true,
    //             symbol:  <LogoMessage initiate={welcomeStart} aTime={2}  >Powered by</LogoMessage>,
    //             promptFs: 0.6,
    //         },
    //         moveForward:{
    //             company: false,
    //             vis:true,
    //             symbol: <LogoImage initiate={welcomeStart}  aTime={2.75}/>
    //             // <LogoPair initiate={welcomeStart} aTime={2} >
    //             // <LogoImage initiate={welcomeStart}  aTime={2.75}/>
    //             // </LogoPair>
    //         }  
    //     },





    //     ////////////////////////////////////////////////////////////// 
    //     /// ABOUT FRAME
    //     //////////////////////////////////////////////////////////////





    //     { 
    //         frame:{
    //             startPoint: "0rem",
    //             endPoint : "30rem",
    //             // index : 0,
    //             navigationOn: true
    //         },
    //         greeting:{
    //             vis: welcomeStart,
    //             aTime: 2,
    //             message:"about you",
    //             fs: 2.5,
    //             translate : 25
    //         },
    //         instructions:{
    //             company: company,
    //             vis: welcomeStart,
    //             message: "fill out all fields to create your assessment profile",
    //             aTime: 2,
   
    //         },
    //         content:{
    //             company: company,
    //             aTime: 1.5,
    //             vis: welcomeStart,
    //             display: "block",
    //             position: "relative",
    //             mt: 10,
    //             children: 
    //             <>
    //                 <AboutForm standard={true}/>
    //                 {/* <button onClick={handleExport}>Export</button> */}
    //             </> 
    //         },
    //         moveBack:{
    //             company: company,
    //             vis: false,
    //             symbol: <i className="fa-solid fa-angle-left" onClick={()=>{setCurrentFrame(prev => prev-1)}}></i>
    //         },
    //         moveForward:{
    //             company: company,
    //             mr: 0.4,
    //             vis: aboutForward,
    //             prompt: "assessment",
    //             action: aboutAdvance,
    //             symbol: <i className="fa-solid fa-angle-right" onClick={aboutAdvance}></i>

    //         }  
    //     },





    //     ////////////////////////////////////////////////////////////// 
    //     /// HELLO AGAIN FRAME
    //     //////////////////////////////////////////////////////////////


        
    //     helloAgainFrame,




    //     ////////////////////////////////////////////////////////////// 
    //     /// EDIT CONFIRMATION FRAME
    //     //////////////////////////////////////////////////////////////



    //     editConfirmFrame



        
     
    // ]


    if(designation === "alteredFrames"){
        return alteredArray
    }
    if(designation === "editOnlyFrames"){
        return editOnlyArray
    }

    return (
        // returnArray
        noImageArray
    )
}
    

    export default frameData