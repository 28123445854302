import {
    Card, 
    CardPair,
    CardTop,
    CardDivider,
    CardBottom,
    SkillReselect,
    CardBottomLPath,
    ReversableContainer
} from './cardComponentStyles'
import { MenuContext } from "../../utils/menuContext"
import { useContext, useState } from 'react'
import SearchableDropDown from '../SearchableDropDown/SearchableDropDown'
import CardOptions from '../CardOptions/CardOptions'

const CardComponent = ({bg_Color, entry, val_, skill, devStage, indx_}) =>{
    const {cardList, company, tenArray, pseudoCardList, setSkillPopUp, setCurrentPopUp} = useContext(MenuContext)
    // const [skillOn, setSKillOn] = useState(false)



    return(
        // val_ > 2?
        
        <CardPair val={val_} gameChanger={entry.gameChanger}>

            <Card  
                accentColor = {company !== undefined && company.accentColor} 
                >
                <CardTop
                      companyCardColor = {company !== undefined && company.companyCardColor} 
                      companyCardFontColor = {company !== undefined && company.companyCardFontColor}
                      onClick={()=>{
                        setCurrentPopUp(indx_)
                        setSkillPopUp(prev => !prev)
                        // setSKillOn(prev => !prev)
                      }}
                      >
                    <h4 >{skill.length > 12? skill.slice(0, 10)+"...": skill}</h4>
                </CardTop>
                <CardDivider/>
                <CardBottom 
                    pathColor={entry.learningPath} 
                    companyCardFontColor = {company !== undefined && company.companyCardFontColor}
                    
                    >
                    <CardBottomLPath
                        pathColor={entry.learningPath} 
                        
                        >
                        {
                            <p style={{fontWeight: "700"}}>{entry.learningPath}</p>
                        }
                        <p>learning path</p>
                    </CardBottomLPath>
                    <h4>{devStage}</h4>
                </CardBottom>
            </Card>

            {/* <SearchableDropDown list={pseudoCardList} indx={indx_} /> */}
           
{/*         
                    {
                        cardList &&
                        <CardOptions
                            indx={indx_} 
                            vis={skillOn}
                            val={val_}
                            
                        />       
                      
                    }
           */}

        </CardPair>

        // :

        // <CardPair val={val_}>
            

        //     {/*This generic div's purpose is to pad the left side of the card, if removed
        //         The card slide will be misaligned*/}

            
        //     {/* <SkillReselect vis={skillOn} >
        //             {
        //                 cardList &&
        //                 <CardOptions
        //                 indx={indx_} 
        //                 vis={skillOn}
        //                 val={val_}
                        
        //                 />       
                        
        //             }
        //         </SkillReselect>           */}


        //     <ReversableContainer val={val_}>

        //     <Card  
        //         accentColor = {company !== undefined && company.accentColor} 
        //         >
        //         <CardTop
        //               companyCardColor = {company !== undefined && company.companyCardColor} 
        //               companyCardFontColor = {company !== undefined && company.companyCardFontColor}
        //               onClick={()=>setSKillOn(prev => !prev)}
        //               >
        //             <h4 >{skill.length > 17? skill.slice(0, 17)+"...": skill}</h4>
        //         </CardTop>
        //         <CardDivider/>
        //         <CardBottom 
        //             pathColor={entry.learningPath} 
        //             companyCardFontColor = {company !== undefined && company.companyCardFontColor}
                    
        //             >
        //             <CardBottomLPath
        //                 pathColor={entry.learningPath} 
                        
        //                 >
        //                 {
        //                     <p style={{fontWeight: "700"}}>{entry.learningPath}</p>
        //                 }
        //                 <p>learning path</p>
        //             </CardBottomLPath>
        //             <h4>{devStage}</h4>
        //         </CardBottom>
        //     </Card>

        //     <SearchableDropDown list={pseudoCardList} />
        //     </ReversableContainer>
            
           
            
        //     <div style={{width: "25%"}}>
              
        //     </div>

        //     <div style={{width: "25%"}}>
              
        //     </div>

        // </CardPair>

        

        

        

        
    )
}

export default CardComponent