import styled from "styled-components";

export const AboutContainer = styled.div`
    display: none;
    position: relative;
    height: 100vh;

    // this is for the desktop disclaimer to be centered;
    @media (min-width: 1000px){
       display: flex;
       justify-content: center;
       align-items: center;
    }
   
`

export const InnerContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 80%;
    min-height: 45vh;
    padding: 1rem;
    *{
        text-align: center;

    }
    h2{
        font-size:  3rem;
        margin-bottom: 2rem;
    }
    p{
        margin-bottom: 1rem;
        font-size: 1.25rem;
        
    }

    input{
        font-size: 1.25rem;
        text-align: left;
        padding: 0.25rem 0.5rem;
    }


`

export const FormContainer = styled.div`
    
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    align-items: flex-start;
    border: 2px solid black;
    border-radius: 10px;
    width: 60%;
    min-height: 45vh;

    *{
        font-size: 0.9rem;
    }
    input{
        background: none;
        border: none;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        width: 100%;
        font-size: 0.9rem;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props=>props.accentColor};
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props=>props.accentColor};
        }
    }
    
    
    input:last-of-type{
        flex: 100%;
    }

    select{
        padding: 0.2rem 0.5rem;
        background: none;
        border: ${props => props.accentColor? `0.5px solid ${props.accentColor}` : "0.5px solid black"};
        border-radius: 5px;
        color: ${props=>props.accentColor}; 
        width: 47%; 
    }


    option{
        background: ${props => props.bottomColor};
        font-size: 0.7rem;
    }

    p{
        color: ${props=>props.accentColor};
    }

    transition: margin 1s ease;
    
`

export const AboutFormField = styled.div`
    width: 47%;
    margin-bottom: 1rem;
    height: 8vh;
    p{
        padding-left: 0.25rem;
        padding-top: 0.25rem;
    }
    input{
        font-size: 16px;
    }
  
`
export const PhoneNumberPair = styled.div`

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    
`

export const PhoneNumberTickPair = styled.div`
    display: flex;

    div{
        width: 20%;
    }
    p{
        font-size: 0.7rem;
        width: 70%;
    
    }
    width: 48%;
`

export const PhoneNumberInputPair = styled.div`
    width: 48%;
    opacity: ${props => props.opc? "1" : "0.3"};
    
`
