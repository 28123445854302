import styled, {css} from 'styled-components'


export const ReviewScrollContainer = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 100vw;
    margin: 0 auto;
    height: 45vh;
    /* border: 0.5px solid ${props => props.accentColor? props.accentColor : "black"}; */
    border-radius: 10px;

    z-index: -1;
`

