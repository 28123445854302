import {useState, useEffect, useContext} from 'react'
import { getMainList, updateMainList } from '../../services/dataServices'
import { CSVInner, SubmitMessage, CSVReaderConatiner} from './csvReaderStyles'
import Loader from '../Loader/Loader'
import GlobalEmployeeList from '../GlobalEmployeeList/GlobalEmployeeList'
import { MenuContext } from '../../utils/menuContext'

const CSVReader = () =>{
    const [csvFile, setCsvFile] = useState(undefined)
    const [csvArray, setCsvArray] = useState([])
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const {setGlobalEmployeeList} = useContext(MenuContext)
    useEffect(()=>{
        if(successMessage){
            setTimeout(()=>{setSuccessMessage(false)}, 2500)
        }
    }, [successMessage])

    const submitCSV = () =>{
        const file = csvFile
        const reader = new FileReader()

        reader.onload = async(e) =>{
            const text = e.target.result
            const docArray =  processCSV(text)

            await updateMainList(docArray)
            .then(async(res) => {
                console.log(res)
                setCsvFile(undefined)
                document.getElementById('csvFile').value = ''
                setLoading(false)
                setSuccessMessage(true)
                await getMainList()
                .then(res => setGlobalEmployeeList(res.data))
                .catch(err => console.log("CSVReader line 37: ", err))
            })
            .catch(err => console.log("CSVReader line 17: ", err))
        }

        reader.readAsText(file)
        
    }


    const processCSV = (str, delim=",") =>{
        const headers = str.slice(0, str.indexOf('\n')).split(delim)
        const rows = str.slice(str.indexOf('\n')+1).split('\n')
        
        const newArray = rows.map(entry =>{
            const values = entry.split(delim)
            const eachObject = headers.reduce((obj, header, i)=>{
                obj[header] = values[i]
                if(values.indexOf(values[i]) === values.length - 1){
                    obj[header] = values[i].slice(0, values[i].length - 1)
                }
                return obj
            }, {})
            return eachObject
        })

        setCsvArray(newArray)
        return newArray
    }

    

    return(
        <CSVReaderConatiner>
            <CSVInner buttonVis={csvFile !== undefined && !loading}>
            <h2>Update Employee List</h2>
                    <input 
                    type="file"
                    accept=".csv"
                    id="csvFile"
                    onChange = {(e)=>{
                        setCsvFile(e.target.files[0])
                    }}
                        />
            {
                csvFile !== undefined && 
                <button
                onClick={(e)=>{
                    if(csvFile !== undefined){
                        setLoading(true)
                        submitCSV()
                    }
                }}
            >
                    submit
            </button>
            }
            {
                loading && 
                <Loader accentColor={"black"} iconSize={"1rem"} mb={"1rem"} mt={"0"}/>
            }
            {
                successMessage && 
                <SubmitMessage vis={successMessage}>
                    List updated successfully!
                </SubmitMessage>
            }
            <GlobalEmployeeList/>
            </CSVInner>
        </CSVReaderConatiner>
    )
}

export default CSVReader