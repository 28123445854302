import {useContext} from 'react'
import {AboutContainer, FormContainer, AboutFormField, PhoneNumberPair, PhoneNumberTickPair, PhoneNumberInputPair} from './aboutStyles'
import {MenuContext} from '../../../../utils/menuContext'

function AboutForm({vis, standard}){
    const {
        company,
        territories,
        employeeTerritory,
        setEmployeeTerritory,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        managerEmail,
        setManagerEmail,
        jobTitle,
        setJobTitle,
        territoryCode,
        setTerritoryCode,
        industryYears,
        setIndustryYears,
        contact,
        setContact,
        foundContact,
        setFoundContact,
        stringFormatter,
        listPhone,
        setListPhone,
        phoneNumber,
        setPhoneNumber
    } = useContext(MenuContext)


    const phoneFormatter = (val) =>{
        if(!val) return val
        const phoneNumber = val.replace(/[^\d]/g, "")
        const phoneNumberLength = phoneNumber.length
        if(phoneNumberLength <4) return phoneNumber
        if(phoneNumberLength < 7){
            return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`
        }
        return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(
            3,
            6
            )}-${phoneNumber.slice(6,10)}`
    }


    return(
        <AboutContainer vis={vis} standard={standard}>
           <FormContainer 
                topColor={company !== undefined && company.topColor}
                bottomColor={company !== undefined && company.bottomColor}
                titleColor={company !== undefined && company.titleColor} 
                accentColor={company !== undefined && company.accentColor}
                auxColor={company !== undefined && company.auxColor}
                standard = {standard}
                vis={vis}
            >
                {/* {
                    foundContact !== undefined?

                    <>
                        <AboutFormField>
                            <input type="text" value={foundContact.firstName} onChange={e => setFoundContact({...foundContact, firstName: e.target.value})}/>
                            <p>first name</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="text"  value={foundContact.lastName} onChange={e => setFoundContact({...foundContact, lastName: e.target.value})}/>
                            <p>last name</p>
                        </AboutFormField>
                        <AboutFormField>
                            {standard && <input type="email"  value={foundContact.email} onChange={e => setFoundContact({...foundContact, email: e.target.value})}/>}
                            <p>email</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="email"  value={foundContact.managerEmail} onChange={e => setFoundContact({...foundContact, managerEmail: e.target.value})}/>
                            <p>manager's email</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="number"  value={foundContact.industryYears} onChange={e => setFoundContact({...foundContact, industryYears: e.target.value})}/>
                            <p>years in industry</p>
                        </AboutFormField>
                        <select onChange={(e)=>{setFoundContact({...foundContact, territoryCode: e.target.value})}}>
                            {
                            foundContact !== undefined? 
                            <option selected value={foundContact.territoryCode}>{foundContact.territoryCode}</option>
                            :
                            <option  hidden>territory code</option>
                            }
                            <option value="0100">0100</option>
                            <option value="0101">0101</option>
                            <option value="0102">0102</option>
                            <option value="0103">0103</option>
                        </select>
                        
                        <input type="text"  value={foundContact.jobTitle} onChange={e =>  setFoundContact({...foundContact, industryYears: e.target.value})}/>
                        <p style={{paddingLeft: "0.25rem", paddingTop: "0.25rem"}}>job title</p>
                    </>

                    : */}

           
                        <AboutFormField>
                            <input  type="text" value={firstName} onChange={e => setFirstName(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>first name</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="text"  value={lastName} onChange={e => setLastName(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>last name</p>
                        </AboutFormField>
                        <AboutFormField>
                            {standard && <input type="email"  value={email} onChange={e => setEmail(e.target.value)} 
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />}
                            <p>email</p>
                        </AboutFormField>
                        <AboutFormField>
                            <input type="email"  value={managerEmail} onChange={e => setManagerEmail(e.target.value)}
                                onBlur={()=>{
                                    window.scrollTo(0,0);
                                }}
                            />
                            <p>manager's email</p>
                        </AboutFormField>
                        {/* <AboutFormField>
                            <input type="number"  value={industryYears} onChsange={e => setIndustryYears(e.target.value)}/>
                            <p>years in industry</p>
                        </AboutFormField> */}


                        <select onChange={(e)=>{setIndustryYears(e.target.value)}}>
                            <option  hidden>years of sales</option>
                            
                            {   (typeof company === "object") &&
                                company.industryYears.map(entry =>{
                                    if(entry === industryYears){
                                        return(
                                            <option key={entry} value={entry} selected>
                                            {entry}
                                            </option>
                                        )
                                    }
                                    else{
                                        return(
                                            <option key={entry} value={entry}>
                                            {entry}
                                         </option>
                                        )
                                    }
                                })
                            }
                        </select>
                        <select style={{marginBottom: "1rem"}} onChange={(e)=>{setEmployeeTerritory(e.target.value)}}>
                            <option  hidden>{territories !== undefined && territories.designation}</option>
                
                            {territories !== undefined && territories.sectors.map(entry =>{
                
                                    if(entry === employeeTerritory){
                                        return(
                                            <option key={entry} value={entry} selected>
                                            {entry}
                                            </option>
                                        )
                                    }
                                    else{
                                        return(
                                            <option key={entry} value={entry}>
                                            {entry}
                                         </option>
                                        )
                                    }
                                }

                            )}
                        </select>
                        <PhoneNumberPair>
                            <PhoneNumberTickPair>
                                <div>
                                    <input type="checkbox" value={listPhone} 
                                            checked={listPhone}
                                            onChange={()=>{
                                            setListPhone(prev => !prev)
                                            // if(contact !== undefined){
                                            //     console.log("from IF in aboutForm")
                                            //     let placeholder = contact
                                            //     delete placeholder.phoneNumber
                                            //     setContact(placeholder)
                                            // }
                                            // else{
                                            //     console.log("from ELSE in aboutForm")
                                            //     let placeholder = contact
                                            //     placeholder = {...placeholder, phoneNumber: e.target.value}
                                            //     setContact(placeholder)
                                            // }
                                    }}/>
                                </div>
                                <p>Opt me in for text messages</p>
                            </PhoneNumberTickPair>
                            <PhoneNumberInputPair opc={listPhone}>
                                <input  
                                    value={phoneNumber} 
                                    onChange={(e)=>{
                                        if(listPhone){
                                            let modifiedNumber = phoneFormatter(e.target.value)
                                            setPhoneNumber(modifiedNumber)
                                        }
                                    }}
                                    onBlur={()=>{
                                        window.scrollTo(0,0);
                                    }}
                                    disabled={!listPhone}
                                />                 
                                <p>phone number</p>
                            </PhoneNumberInputPair>
                        </PhoneNumberPair>
                        {/*                         
                        <input type="text"  value={jobTitle} onChange={e => setJobTitle(e.target.value)}/>
                        <p style={{paddingLeft: "0.25rem", paddingTop: "0.25rem"}}>job title</p> */}
                
                        {/* } */}
                
           </FormContainer>
        </AboutContainer>
    )
}

export default AboutForm

