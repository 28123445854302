import { useContext } from "react"
import { MenuContext } from "../../utils/menuContext"
import { PopUpInner} from "../PopUp/popUpStyles"
import AssessmentWindow from "./AssessmentWindow"

const PreviousAssessmmentModal = () =>{
    const {pastAssessments, assessmentPopUp, setAssessmentPopUp} = useContext(MenuContext)

    return(
          <PopUpInner vis={assessmentPopUp}>
            
                        {
                            pastAssessments.length > 0 &&
                            <AssessmentWindow />
                        }
                <i className="fa-regular fa-circle-xmark"
                    onClick={()=>{setAssessmentPopUp(false)}}
                ></i>
            
        </PopUpInner>
    )
}

export default PreviousAssessmmentModal