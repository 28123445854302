import {useState, useEffect, useContext} from 'react'
import { MenuContext } from '../../utils/menuContext'
import 
{ 
    Container,
    InputPair,
    TopicOption
} 
from './assessmentUploadStyles'
import { 
    getNewCafes,
    getCurrentEmployeeAssessment,
    postAssessmentModified,
    postAssessmentLeaderModified,
    searchEmployee
} from '../../services/dataServices'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'

const LeaderUpdate = () =>{
    const {admin, setAdmin, adminList, setAdminList, cafeList, setCafeList} = useContext(MenuContext)
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [employee, setEmployee] = useState({})
    const [cards, setCards] = useState([])
    const [newCafes, setNewCafes] = useState([1, 2, 3, 4, 5 ])
     

    useEffect(()=>{
        async function getCafes(){
            await getNewCafes("62d47c7a36aeee14652966cd")
                    .then(async(res) => {
             
                        setCafeList(res.data)
                        setIsLoading(false)
                    })
                    .catch(err => console.log(err))
        }

        getCafes()
        return ()=>{}
    },[])

 

    async function specificSubmitHandler(){
        setIsLoading(true)
        
     
        try{
            const employee = await searchEmployee(email)
            if(employee){
           
                try{
                    const reply = await getCurrentEmployeeAssessment(employee.data._id)
                    if(reply){
                            
                            const currentAssessment = reply.data
                     
                        //     if(currentAssessment === null){
                        //         alert("Cannot update user's skill challenges. User has not taken assessment for 2024!")
                        //         setIsLoading(false)
                        //         return
                        //     }
                            const oldCafes = currentAssessment.currentSkillsChallenges
                            oldCafes.splice(1, 1, cafeList.find(entry => entry.title === "Be Upstanding"))
                           await postAssessmentModified({
                            ...currentAssessment,
                                currentSkillsChallenges: oldCafes
                           })  
                                .then(res => {
                                    if(res){
                                     
                                        setEmail("")
                                        setIsLoading(false)
                                    }
                                })
                                .catch(err => alert(err))
                    }
                }catch(e){
                    console.log(e)
                    alert(e)
                }
            }
        }catch(e){
            console.log(e)
            alert(e)
        }
        
}


    if(isLoading){
        return(
            <Container>
                <Loader 
                        accentColor={"black"}  
                        iconSize={"1.5rem"}
                        mb={"0vh"}
                        mt={"5vh"}
                    />
            </Container>
        )
    }
            

        else{
            return(
            <Container>
                <h1>Update Leader</h1>
                <InputPair>
                    <p>Email</p>
                    <input value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                </InputPair>
            
                <button onClick={(e)=>{
                    e.preventDefault()
                    specificSubmitHandler()
                }}>
                    submit
                </button>
            </Container>
            )
   

         } 
}

export default LeaderUpdate

