import {
    AssessmentHeader,
    Logo,
    LoadingMessage,
    Instructions, 
    SelectContainer,
    RowPair,
    AssessmentContainer,
    SubmitButton
} 
from '../styles/assessStyles'
import ConfirmOptions from '../components/ConfirmOptions'
import ImageConComponent from '../components/ImageConComponent'
import { MenuContext } from "../utils/menuContext"
import { useContext, useState, useEffect } from "react"
import {useNavigate} from 'react-router-dom'
import {assessUpload} from '../services/dataServices'

function AssessmentPage(){
    const {cardList, tenArray, setTenArray, setCardList, contact, setContact, previousPath,
       setPreviousPath, imageLock
    } = useContext(MenuContext)
    const [buttonSelectLock, setButtonSelectLock] = useState(true)
    const [buttonImageLock, setButtonImageLock] = useState(true)
    const [formData, setFormData] = useState(null)
    const tempArray = [0,1,2,3,4,5,6,7,8,9]
    const redirect = useNavigate()

    console.log(contact)
    
    useEffect(()=>{
        // if(!localStorage.getItem("contact")){
        //     redirect("/")
        // }
        if(localStorage.getItem("contact")){
            setContact(JSON.parse(localStorage.getItem("contact")))
            if(JSON.parse(localStorage.getItem("contact")).hasOwnProperty("assessmentImage")){
                setPreviousPath(JSON.parse(localStorage.getItem("contact")).assessmentImage)
            }
        }
        if(localStorage.getItem("list")){
            setTenArray(JSON.parse(localStorage.getItem("list")).array)
            setCardList(JSON.parse(localStorage.getItem("list")).cards)
        }
        
    }, [])

    console.log(buttonSelectLock)

    
    useEffect(()=>{
        let BreakException ={}
        try {
            tenArray.forEach(entry => {
              setButtonSelectLock(false)
              if (entry.value === 0){
                setButtonSelectLock(true)
                throw BreakException;
              }
            });
          } catch (e) {
            if (e !== BreakException) throw e;
          }
        return ()=>{
            return null
        }
    },[tenArray])

    const submitResults = () =>{
        
        localStorage.setItem( "list", JSON.stringify({array: tenArray, cards: cardList}))
        localStorage.setItem("contact", JSON.stringify({...contact, assessmentImage: previousPath}))
        localStorage.setItem("complete", true)
        redirect("/confirm")
         
        
    }
    
    return(
        
        <AssessmentContainer>
            
            <AssessmentHeader>
                <Logo />
                <h1>Competency Assessment</h1>
            </AssessmentHeader>
            
            <Instructions>Please select your 2 Game Changers, as well as the remaining 8 Competency Cards in your Top 10 from the Dropdowns below, including your stage of development for each.</Instructions>
            {
                 cardList? 
                 
                <SelectContainer>
                    {
                        tempArray.length > 0 &&
                        tempArray.map(item =>{
                            
                            return(
                                <RowPair key={tempArray.indexOf(item)}>
                                    <p>
                                        {
                                            tempArray.indexOf(item) <= 1?
                                            `gamechanger ${tempArray.indexOf(item)+1}`
                                            :
                                            `competency`
                                        }
                                    </p>
                                    <ConfirmOptions 
                                    key={tempArray.indexOf(item)}
                                    _list={cardList} 
                                    indx={tempArray.indexOf(item)} 
                                    />
                                </RowPair>

                                
                            )
                        })
                    }

                    {/* <ImgInput sFormData_={setFormData} sButtonImageLock_={setButtonImageLock}/> */}
                    
                    <ImageConComponent   sFormData_={setFormData} sButtonImageLock_={setButtonImageLock}/>

                    <SubmitButton 
                        disabled={buttonSelectLock }
                        onClick={()=>{
                            submitResults()
                        }}
                    >
                    submit
                    </SubmitButton>
                </SelectContainer>
                 
                
                :

                <LoadingMessage><h2>loading...</h2></LoadingMessage>
            }
            
        </AssessmentContainer>
    )
}

export default AssessmentPage