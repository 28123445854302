import styled, {css} from 'styled-components'

export const PersonnelContainer = styled.div`
    background: white;
    max-height: 70%;
    width: 90%;
    margin: 4rem auto 2rem auto;
    border-radius: 10px;
    padding: 1rem;
    overflow-Y: scroll;

    h2{
        text-align: center;
    }
    
    @media (min-width: 1000px){
        width: 35%
    }
`

export const ModularContainer = styled.div`
    border-bottom: 0.5px solid black;
    padding-bottom: 1rem;
    display:flex; 
    justify-content: space-between;
    margin-bottom: 1rem;
`
const incrementButtonStyles = css`
    border: 0.5px solid black;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const AdditionalManagerButton = styled.button`
    background: transparent;
    cursor: pointer;
    ${incrementButtonStyles};
`
export const RemoveManagerButton = styled.button`
    background: transparent;
    cursor: pointer;
    ${incrementButtonStyles};
    display: ${props => props.vis? "flex" : "none"};

`

export const ModularInput = styled.input`
    padding-left: 0.5rem;
    border: 0.5px solid black;
    border-radius: 5px;
    width: 45%;
    @media (min-width: 1000px){
        width: 55%;
    }   
`

const submitButtonStyles = css`
    display: ${props => props.vis? "inline-block" : `none`};
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    
`

export const SubmitSingleButton = styled.button`
    ${submitButtonStyles};

`


export const SubmitAllButton = styled.button`
    display: ${props => props.vis? "inline-block" : `none`};
    padding: 0.25rem 0.5rem;  
    width: 200px;
    margin: 0 auto;
    margin-bottom: 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`

export const IncrementTray = styled.div`

    button{
        margin-right: 1rem;
    }    
    display: flex;
    @media (min-width: 1000px){
        button{
            font-size: 2rem;
        }
    }
`

const listHolderStyles = css`
    border: 0.5px solid black;

    h2{
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.5px solid black;
    }
  
    overflow-y: scroll;

    div:last-of-type{
    border: none;
    }

    p{
    font-weight: 500;
    }
`

export const PersonnelItem = styled.div`
    padding: 0.5rem;
    border-bottom: 0.5px solid black;
    display: flex;
    justify-content: space-between;
`

export const PersonnelHolderAdvanced = styled.div`
    ${listHolderStyles};
    display: ${props => props.vis? "flex" : "none"};
    flex-direction: column;
    overflow: initial;
    justify-content: center;
    align-items: space-between;
    h2{
        border-bottom: none;
    }
    i{
        font-size: 1.25rem;
        cursor: pointer;
        padding: 0.5rem;
        border: 0.5px solid black;
        border-radius: 5px;
        background: #f2f2f2;
    }
    *{
        margin: 0 
        padding: 0;
    }
    padding: 1rem;
    
    margin: 0 auto;


    border-radius: 10px;
    background: white;
    box-shadow: -5px 5px 5px #4f4f4f;
    margin-bottom: 2rem;

    border: none;

    @media (min-width: 1200px){
        padding: 2rem;
    }
`

export const PersonnelHolderGlobal = styled.div`
    ${listHolderStyles};
    display: ${props => props.vis? "block" : "none"};
`

const transferRowStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;    
`
const searchStyles = css`
    margin-bottom: 1rem;
    border-bottom: 0.5px solid black;
    padding-bottom: 1rem;
    i{
        color: black;
        
        
    }
    input{
        padding: 0.25rem;
        border: 0.5px solid black;
        border-radius: 5px;
        
    }

    @media (min-width: 1000px){
        i{
            display: block;
        }
    }
`
export const ManagerSearch = styled.div`
    ${searchStyles};
`


export const SearchTopRow = styled.div`
    ${transferRowStyles};
    margin-bottom: 1rem;

    @media (max-width: 1000px){
        i{
            font-size: 1.2rem;
        }
    }
`

export const TopRowLeftColumn = styled.div`
    
        width: 40%;
    
`

export const TopRowRightColumn = styled.div`
    
        width: 40%;
        display: flex;
        justify-content: flex-end;
`




export const SearchBottomRow = styled.div`
    ${transferRowStyles};
    *{
        font-size: 0.8rem;
    }
`

export const ManagerInput = styled.input`
    display: ${props => props.vis? "block" : "none"};
    max-width: 100%;
    @media (max-width: 1000px){
            font-size: 0.8rem;
    }
`

const holderStyles = css`
    border: 0.5px solid black;
    border-radius: 5px;
    padding: 0.25rem;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 125px;
`

export const FromAdminHolder = styled.div`
    ${holderStyles};
    width: 49%;
    
`


export const ToAdminHolder = styled.div`
${holderStyles};
width: 49%;

`
export const SingleAdmin = styled.div`
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    p{
        margin-bottom: 0.5rem;
        text-align: center;
    }
    i{
        cursor: pointer;
    }
    @media (max-width: 1000px){
        i{
            font-size: 1rem;
        }
    }

    @media (min-width: 1000px){
        p{
            font-size: 1.2rem;
        }
    }
`

export const TargetManager = styled.div`
    ${transferRowStyles};
    
    select{
        padding-left: 0.25rem;
    }
    button{
        padding: 0.25rem;
        border-radius: 5px;
        border: 0.5px solid black;  
    }
`

const searchRowStyles = css`
    padding: 0.5rem 0.25rem;
    border-bottom: 0.5px solid black;
    cursor: pointer;
`

export const ManagerRow = styled.div`
    ${searchRowStyles};
`

export const EmployeeSearch = styled.div`
    ${searchStyles};
    display: ${props => props.vis? "initial" : "none"};
    
`

export const EmployeeSearchContainer  = styled.div`
    width: 80%;
    margin: 0 auto;
    border: 0.5px solid black;
    border-radius: 5px;
    padding: 0.5rem;
    overflow-x: scroll;
`

export const EmployeeRow = styled.div`
    ${searchRowStyles};
    display: flex;

    input{
        margin-right: 0.5rem;
    }
`

export const SelectedManager = styled.div`

`

export const TransferButton = styled.button`
    padding: 0.5rem;
    border-radius: 5px;
    border: 0.5px solid black;
    width: 150px;
    margin: 0 auto 1rem auto;
    display: ${props => props.vis? "initial":"none"};
    cursor: pointer;
`

export const StatHolder = styled.div`
    
    text-align: center;
    border-bottom: 0.5px solid black;
    margin-bottom: 1rem;
`

export const StatWindow = styled.div`
    margin-bottom: 1rem;
`

export const SelectionWindow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;
`

export const SelectionColumn = styled.div`
    p{
        margin-bottom: 1rem;
        
    }

    @media (max-width: 750px){
        p{
            font-size: 0.85rem;
            max-width: 75px;
        }
    }
`

export const StatRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid black;
    padding: 0.5rem 0;
`

export const TransferHolder =styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media (min-width: 768px){
        justify-content: space-evenly;
    }
`

export const SkillRow =styled.div`
    border-bottom: 0.5px solid black;
    padding: 0.5rem 0rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;    
 
`

export const SkillMonth = styled.div`
    p{
        font-size: 1.1rem;
        font-weight: 500;
    }
    width: 25%;

    @media (min-width: 1000px){
        width: 30%;
        p{
            font-size: 1.5rem;
        }
    }
`
export const SkillTitleGroup = styled.div`
@media (min-width: 1000px){
    width: 30%;
}
`

export const SkillNumberGroup = styled.div`
@media (min-width: 1000px){
    width: 30%;
}

`
const pSizing = css`
    p{
        font-size: 0.7rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    @media (min-width: 768px) {
        p{
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
      }
`

export const SkillNumber = styled.div`
    ${pSizing};
`

export const SkillTitle = styled.div`
    ${pSizing};

`