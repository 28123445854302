import styled, {css} from 'styled-components'



export const LoadingMessage = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    h2{
        margin: 0;
        padding: 0;
    }

    @media(min-width: 650px){
        h2{
            font-size: 2.5rem;
        }
    }
`

export const AssessmentContainer = styled.div`
    width: 95%;
    margin: 0 auto;
    padding-top: 1rem;
    @media (min-width: 530px){
        width: 80%;
    }

    @media (min-width: 700px){
        width: 70%;
    }

    @media (min-width: 1200px){
        width: 60%;
    }
`

const instructionStyles = css`
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
    @media (min-width: 540px){
        margin-bottom: 2rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 800px){
        font-size: 1.2rem;
    }

`

export const Greeting = styled.h1`
    color: #11898A;
    font-size: 4rem;
    text-align: right;
`

export const Instructions = styled.p`
    ${instructionStyles};
`



export const AssessmentHeader = styled.div`
    margin-right: 1rem;
    /* background-color: #11898A; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    border: 2px solid #11898A;
    margin-bottom: 2rem;
    
    h1{
        @media (max-width: 300px){
            font-size: 1.5rem;
        }
        padding: 0;
        margin: 0;
        font-size: 1.9rem;
        color: #11898A;
        text-align: right;
        font-weight: 700;
        width: 70%;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;

        @media (min-width: 460px){
            font-size: 2.2rem;
        }

        @media (min-width: 600px){
            font-size: 2.45rem;
        }

        @media (min-width: 800px){
            font-size: 3.25rem;
        }

        @media (min-width: 800px){
            font-size: 3.5rem;
        }

        @media (min-width: 1100px){
            font-size: 3.7rem;
        }

        @media (min-width: 1600px){
            font-size: 4rem;
        }
    }

    @media (min-width: 800px){
        margin-bottom: 3rem;
    }


`

export const Logo = styled.div`
    background-image: url('./images/tero-logo.png');
    background-position: center;
    background-size: cover;
    height:35px;
    width: 35px;
    border-radius: 100%;
    @media (min-width: 800px){
        width:150px;
        height: 150px;
    }
`



export const RowPair = styled.div`
    
    p{
        @media (max-width: 300px){
            font-size: 0.6rem;
        }
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-size: 0.75rem;
        text-transform: uppercase;
     
    }
    
    @media (min-width: 540px){
        display: flex;
  
        p{
            min-width: 110px;
            font-weight: 700;
        }
    }

    @media (min-width: 600px){
        width: 100%;
        justify-content: space-between;
        p{
            font-size: 0.85rem;
        }
    }
        

    @media (min-width: 1200px){
        p{
            font-size: 1.3rem;
            font-weight: 400;
        }
    }
`

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`



export const ConfirmationForm = styled.div`
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
 

    @media (min-width: 600px){
        width: 72%;
        margin-right: 0;
        justify-content: space-between;
    }

    @media (min-width: 800px){
        width: 75%;
    }

    @media (min-width: 1200px){
        margin-bottom: 2rem;
        width: 77%;
    }
   
`


const selectStyles = css`
    cursor: pointer;
    @media (max-width: 300px){
        font-size: 0.6rem;
        padding: 0;
    }
    padding: 0.25rem 0.5rem;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 0.7rem;
    font-weight: 700;
    background: transparent;
    margin: 0;

    @media(min-width: 650px){
        font-size: 0.85rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        padding: 0.5rem 1rem;
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.3rem;

    }
`
export const SkillSelect = styled.select`
    
    @media (max-width: 300px){
        margin-right: 0.5rem;
        max-width: 125px;
    }
    ${selectStyles};
    margin-right: 1rem;
    
    @media (min-width: 1200px){
        width: 50%;
    }
`

export const DevSelect = styled.select`
    @media (max-width: 300px){
    
         max-width: 100px;
    }
    ${selectStyles};
    padding: ${props => props.init? "0.25rem 0.5rem" : "0"};
    border: ${props => props.init? "1px solid black" : "none"};
    border-radius: ${props => props.init? "3px" : "none"};
    width: ${props => props.init? "120px" : "0px"};
    transition: width 0.5s ease;
    @media (min-width: 800px){
        padding: ${props => props.init? "0.5rem 1rem" : "0"};
    }
    option{
        font-size: 0.7rem;
        background: rgba(129,192,194,0.6);
        font-weight: 700;
        @media (min-width: 800px){
          
            font-size: 0.7rem;
            font-weight: 400;
            
        }

        @media (min-width: 1200px){
            font-size: 1.1rem;
        
        }
    }

    @media (min-width: 600px){
        width: ${props => props.init? "40%" : "0px"};
    }

    @media (min-width: 800px){
        width: ${props => props.init? "40%" : "0px"};
    }

`

export const ConcealableOption = styled.option`
    display: ${props => props.vis? "none" : "block"}; 
    font-size: 0.7rem;
    background: rgba(129,192,194,0.6);
    font-weight: 700;

    @media (min-width: 800px){
        font-size: 0.7rem;
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.1rem;
     }
`

export const SubmitButton = styled.button`
    background: none;
    display: block;
    border: ${props => props.disabled? "1px solid darkslategrey" : "1px solid black"};
    border-radius: 3px;
    color: ${props => props.disabled? "darkslategrey": "black"};
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1rem;
    cursor: pointer;

    &:disabled{
        cursor: not-allowed;
        background-color: rgba(0,0,0,0.5);

    }

    @media (min-width: 540px){
        margin-top: 1rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        padding: 0.5rem 1rem;

    }

    @media (min-width: 1200px){
        font-size: 1.3rem
    }
`



const cardSelectStyles = css`
    cursor: pointer;
    display: block;
    @media (max-width: 300px){
        font-size: 0.6rem;
        padding: 0;
    }
    padding: 0.25rem 0.5rem;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 0.7rem;
    font-weight: 700;
    background: transparent;
    margin: 0;

    @media(min-width: 650px){
        font-size: 0.85rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        padding: 0.5rem 1rem;
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.3rem;

    }
`
export const CardSkillSelect = styled.select`
    
    @media (max-width: 300px){

        max-width: 125px;
    }
    ${cardSelectStyles};

    
    @media (min-width: 1200px){
        width: 50%;
    }
`

export const CardDevSelect = styled.select`
    @media (max-width: 300px){
    
         max-width: 100px;
    }
    ${cardSelectStyles};
    @media (min-width: 800px){
   
    }
    option{
        font-size: 0.7rem;
        background: rgba(129,192,194,0.6);
        font-weight: 700;
        @media (min-width: 800px){
          
            font-size: 0.7rem;
            font-weight: 400;
            
        }

        @media (min-width: 1200px){
            font-size: 1.1rem;
        
        }
    }

    @media (min-width: 600px){
        width: ${props => props.init? "40%" : "0px"};
    }

    @media (min-width: 800px){
        width: ${props => props.init? "40%" : "0px"};
    }

`

export const CardConcealableOption = styled.option`
    display: ${props => props.vis? "none" : "block"}; 
    font-size: 0.7rem;
    background: rgba(129,192,194,0.6);
    font-weight: 700;

    @media (min-width: 800px){
        font-size: 0.7rem;
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.1rem;
     }
`


