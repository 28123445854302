import styled, {css} from 'styled-components'

const colorSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "blue": val = `dodgerblue`
        break
        case "red": val = `crimson`
        break
        case "orange": val = `tomato`
        break
        case "green": val = `forestgreen`
        break
        default: val = "white"
    }
    return val
}

export const CardOverContainer= styled.div`
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
`

export const CardContainer = styled.div`
    height: 30vh;
    border-radius: 10px;
    border: 0.5px solid black;
    width: 100%;
    /* box-shadow: 0px 5px 5px grey; */
    margin-bottom: 0.5rem;
`

const sectionStyle= css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const CardTop = styled.div`
    ${sectionStyle};
    background: ${props => props.companyCardColor};
    *{color: ${props => props.companyCardFontColor};};
    height: 42%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    
    
`

export const CardTitle = styled.div`
     text-align: center;
     font-weight: 700;
     display: ${props => props.vis? "block" : "none"};
     max-width: 80%;
`

export const CardSelect = styled.select`
    display: ${props => props.vis? "block" : "none"};
    font-size: 0.85rem;
    transition: all ${props => `${props.aTime}s`} ease;
    padding-bottom: 0.25rem;
    background: none;
    border: none;
    max-width: 75%;

    option{
        color: ${props => props.bottomColor};
        font-size: 0.7rem;
        background: ${props => props.accentColor};
    }
`

export const CardDivider = styled.div`
    background: white;
    height: 2%;
`

export const CardBottom = styled.div`
    ${sectionStyle};
    background: ${props =>  colorSwapper(props.pathColor)};
    color: white;
    height: 56%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    p:first-of-type{
        font-size: ${props => props.boldness? "1rem" : "1.2rem"};
        font-weight: ${props => props.boldness? "400" : "700"};
    }
`

export const CardEdit = styled.button`
    opacity: ${props => props.vis? "1" : "0"};
    display: ${props => props.vis? "block" : "none"};
    padding: 0.2rem 0.3rem;
    background: none;
    border: ${props => `1px solid ${props.accentColor}`};
    border-radius: 5px;
    transition: all 0.75s ease;
    font-weight: 300;
`

export const CardDevNavigation = styled.div`
        display: flex;
        align-items: baseline;
        p{
            margin: 0.5rem;
            font-weight: 400;
        }
        * {
            color: white;
            font-size: 0.9rem;
        }

`

