import styled, {css} from "styled-components";



export const AssessSelectPairContainer = styled.div`
    max-width: 100vw;
    margin: 0 auto;
 
    *{
        color: ${props=> props.accentColor};
    }
    
    /* select{
        display: block;
        font-size: 0.85rem;
        transition: all ${props => `${props.aTime}s`} ease;
        padding-bottom: 0.25rem;
        background: none;
        border: none;
        border-bottom: ${props => props.accentColor?  `1px solid ${props.accentColor}` : `1px solid black`};
        width: 40%;
    } */

    /* select:first-of-type{
        width: 50%;
    } */

    /* option{
        color: ${props => props.bottomColor};
        font-size: 0.7rem;
        background: ${props => props.accentColor};
    } */
`

export const AssessPairLabel = styled.p`
    margin-bottom: 1rem;
    color: ${props => props.titleColor};
`

export const AssessSelectRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`

const assessSelectStyles = css`
        display: block;
        font-size: 0.85rem;
        transition: all ${props => `${props.aTime}s`} ease;
        padding-bottom: 0.25rem;
        background: none;
        border: none;
        border-bottom: ${props => props.accentColor?  `1px solid ${props.accentColor}` : `1px solid black`};
        

        option{
            color: ${props => props.bottomColor};
            font-size: 0.7rem;
            background: ${props => props.accentColor};
        }
`

export const AssessCardSelect = styled.select`
    ${assessSelectStyles};
    width: 50%;
`

export const AssessDevSelect = styled.select`
    ${assessSelectStyles};
    width: ${props => props.vis? "40%" : "0%"};
    transition: width 0.3s linear;
`

export const CardTray = styled.div`
        display: flex;
        margin-bottom: 1rem;
        min-height: 145px;
        position: relative;
        justify-content: space-around;
`

export const cardSlotStyle = css`
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid black;
    *{color: ${props=> props.accentColor};}
    p{
        writing-mode: vertical-rl;
        text-orientation: upright;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        font-weight: 400;
        text-shadow: 0px 0px 2px lightgrey;
    }
    i{
        cursor: pointer;
    }
`

export const CardSlot1 = styled.div`
    ${cardSlotStyle};    
    p{
        font-size: 0.6rem;
        font-weight: 500;
    }

    i{
      position: absolute;
      left: 0.5rem;
      color: white;
      text-shadow: 0px 0px 2px black;
      font-size: 1.1rem;  
    }
`

export const CardSlot2 = styled.div`
    ${cardSlotStyle};
    p{
        font-size: 0.55rem;
        font-weight: 500;
    }
`

export const CardSlot3 = styled.div`
    ${cardSlotStyle};
    p{
        font-size: 0.7rem;
    }
`

export const CardSlot4 = styled.div`
    ${cardSlotStyle};
    
    i{
      position: absolute;
      right: 0.5rem;
      color: white;
      text-shadow: 0px 0px 3px grey;
      font-size: 1.1rem;  
    }
`