import { useState, useEffect, useContext } from "react";
import { MenuContext } from "../../utils/menuContext";
import { 
    SubmitChoicesContainer, 
    ChoiceContainer,
    ChoiceLabel,
    ChoiceCheck
 } from "./submitChoicesStyles";


const Choice = ({bottomColor, accentColor, displayMessage, value, bg, fc, fw}) =>{
    const {finalResults, learningPath, setLearningPath, choiceSelection, setChoiceSelection} = useContext(MenuContext)

    const determineSelection = (val) =>{
        switch(val){
            case "1st" : setLearningPath(finalResults[0].firstColor)
            break
            case "2nd" : setLearningPath(finalResults[1].secondColor)
            break
            default: return null

        }
    }

    return(
        <ChoiceContainer 
            bottomColor={bottomColor}
        >
                <ChoiceLabel>
                    <p>{displayMessage}</p>
                </ChoiceLabel>
                <ChoiceCheck
                    accentColor={accentColor}
                    bottomColor={bottomColor}
                    onClick={()=>{determineSelection(value); setChoiceSelection(value)}}
                    fc={fc}
                    fw={fw}
                    bg={bg}
                >
                   {
                    choiceSelection === value
                    ?
                    `selected`
                    :
                    `select`
                   }
                </ChoiceCheck>
            </ChoiceContainer>
    )
}

const SubmitChoices = ({move, bottomColor, accentColor}) =>{

    const {choiceSelection, setChoiceSelection} = useContext(MenuContext)

    return(
        <SubmitChoicesContainer move={move} vis={move}>
            <Choice  displayMessage={`1st Suggested Path`}  accentColor={accentColor} bottomColor={bottomColor} bg={choiceSelection === "1st" && bottomColor}  fc={choiceSelection === "1st" ? accentColor : bottomColor} fw={choiceSelection === "1st" ? `700` : `400`} value={"1st"}/>
            <Choice  displayMessage={`2nd Suggested Path`}  accentColor={accentColor} bottomColor={bottomColor} bg={choiceSelection === "2nd" && bottomColor}  fc={choiceSelection === "2nd" ? accentColor : bottomColor} fw={choiceSelection === "2nd" ? `700` : `400`} value={"2nd"}/>
            <Choice  displayMessage={`Manual Selection`}    accentColor={accentColor} bottomColor={bottomColor} bg={choiceSelection === "manual" && bottomColor}  fc={choiceSelection === "manual" ? accentColor : bottomColor} fw={choiceSelection === "manual" ? `700` : `400`} value={"manual"}/>
        </SubmitChoicesContainer>
    )
}


export default SubmitChoices
