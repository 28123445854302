import styled, {css} from "styled-components";

export const CafeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const CafeOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 85%;
    position: relative;
`

export const CafeOpt = styled.div`
    height: 50vh;
    overflow: hidden;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid black;
    margin-bottom: 0.5rem;
    opacity: ${({selected})=>selected? "1" : "0"};
    transition: opacity 1s ease;
    top: 1vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: ${({selected})=>selected? "0" : "100vw"};
    right: 0;
    transition: left 0.75s ease;
    z-index: ${({month})=> month.toString()};
    overflow: hidden;
    p{
        text-align: right;
    }
    
    *{
        // display: ${({selected})=>selected? "initial" : "none"};
        opacity: ${({selected})=>selected? "1" : "0"};
        transition: opacity 1s ease;
     
    } 

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

`

export const CafeOptTopRow = styled.div`
    h2{
        color: ${({titleColor})=> titleColor};
        font-size: 1.5rem;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const CafeOptKeyContainer = styled.div`
    h2{
        color: ${({titleColor})=> titleColor};
        font-size: 1.5rem;
    }

    align-items: center;
    p{
        font-size: 0.85rem;
    }

`

export const KeyPair = styled.div`
    display: flex;
    justify-content: flex-end;
`
const keyStyle = css`
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
`

export const SuggestedKey = styled.div`
    ${keyStyle};
    background-color: #c9a1ff;
`
export const MandatoryKey = styled.div`
    ${keyStyle};
    background-color: #ff9354;
`

const displayNav = (month, orientation) =>{
    if(month < 2 && orientation === "left"){
        return false
    }
    if(month > 4 && orientation === "right"){
        return false
    }
    else{
        return true
    }
}

export const CafeOptNavPair = styled.div`
    
    display: ${({selected})=>selected? "flex" : "none"};
    justify-content: ${({month})=>{
        if(month < 2){
            return "flex-end"
        }else{
            return "space-between"
        }
    }};
`

export const CafeOptNav = styled.i`
    display: ${({month, orientation})=> displayNav(month, orientation)? "initial" : "none"};
    font-weight: bold;
    font-size: 1.5rem;
`

export const GroupingHolder = styled.div`
    a
`


// export const SingleOpt = styled.div`
//     border: ${({highlight, mandatory})=>highlight.suggested || (mandatory && highlight.isLeader)? "none" : "1px solid black"};
//     border-radius: 5px;
//     box-shadow: ${({highlight, mandatory}) =>{
//         if(highlight.isLeader && mandatory ){
//             return "0px 0px 0px 4px #ff9354"
//         } if(highlight.suggested){
//             return "0px 0px 0px 4px #c9a1ff"
//         } else{
//             return "none"
//         }
//     }};
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0.5rem 1rem;
//     font-size: 0.8rem;
//     margin-bottom: 1rem;
//     p{
//         max-width: 80%;
//         text-align: left;
//     }

// `

export const SingleOpt = styled.div`
    border: ${({highlight})=>highlight.suggested ? "none" : "1px solid black"};
    border-radius: 5px;
    box-shadow: ${({highlight}) =>{
        if(highlight.suggested){
            return "0px 0px 0px 4px #c9a1ff"
        } else{
            return "none"
        }
    }};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    p{
        max-width: 80%;
        text-align: left;
    }

`

export const OptCheckBox = styled.div`
    width: 20px;
    height: 20px;
    border: 0.5px solid grey;
    background-color: ${({vis})=>vis?"limegreen" : "none"};
`


export const SubmitButton = styled.button`
    display: ${({vis})=>vis? "initial" : "none"};
    background-color: transparent;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-top: 55vh;

`


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































