import styled from 'styled-components'


export const LastModalContainer = styled.div`
    position: relative;
    height: 55vh;
   
`

export const ModalWindow = styled.div`
    position: absolute;
    min-height: 90%;
    background: ${props => props.titleColor};
    border-radius: 10px;
    min-width: 80%;
    top: 50%; 
    left: 50%;
    transform: ${props => props.move? `translate(-50%, -55%)` : `translate(75%, -55%)`};
    transition: transform 1s ease;
    padding: 1rem;
    *{
        color: ${props => props.bottomColor};
    }
`

export const ModalTitleContent = styled.h2`
    font-size: 0.9rem;
    text-align: center;
`

export const SubmitButton = styled.div`
    display: ${props => props.vis? "flex" : "none"};
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    button{
        padding: 0.2rem 0.5rem;
        background: none;
        border-radius: 5px;
        border: 2px solid ${props => props.accentColor};
        color: ${props => props.accentColor};
    }

`