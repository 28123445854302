import {useState, createContext, useEffect} from 'react'
import
{
    serverActivate,
    getCompany, 
    getCards,
    getImage,
    getTerritories,
    searchEmployee,
    sendInitial, 
    checkEmployeeInfo, 
    getDate,
    getLogo,
    getMainList,
    getPastAssessments,
    getSkillsChallenges,
    getLeaderStatus
    
} from "../services/dataServices"
import{
    emailActivate
}
from "../services/mailServices"
import Frame from '../components/Frame/Frame'
import frameData from './framedata'
import Scrollable from '../components/Scrollable/Scrollable'
import InfoEditScreen from "../components/InfoEditScreen/InfoEditScreen"
import {EditConfirmPageContainer} from "../pages/EditConfirmPage/editConfirmPageStyles"

const MenuContext = createContext()
function MenuContextProvider({children}){
    const [pastAssessments, setPastAssessments] = useState([])
    const [isPastAssessmentVisible,setIsPastAssessmentVisible] = useState(false)
    const [cartPanelActive, setCartPanelActive] = useState(false)
    const [current, setCurrent] = useState("")
    const [orderList, setOrderList] = useState([])
    const [popUpList, setPopUpList] = useState([])
    const [storageCheck, setStorageCheck] = useState(false)
    const [resultsObj, setResultsObj] = useState({
        client: "",
        finalList : []
    })


    ////////// VARIABLES

    const [tenArray, setTenArray] = useState([
        {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
        {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4}
    ])
    const [cardList, setCardList] = useState([])
    const [cafeList, setCafeList] = useState([])
    const [finalCafes, setFinalCafes] = useState([])
    const [admin, setAdmin] = useState("")
    const [finalResults, setFinalResults] = useState(undefined)
    const [pseudoCardList, setPseudoCardList] = useState(undefined)
    const [resultsActive, setResultsActive] = useState(false)
    const [colorArray, setColorArray] = useState([])
    const [contact, setContact] = useState(null)
    const [foundContact, setFoundContact] = useState(false)
    const [initialEmployeeId, setInitialEmployeeId] = useState(undefined)
    const [firstName, setFirstName] = useState("")
    const [employeeId, setEmployeeId] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [managerEmail, setManagerEmail] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [employeeTerritory, setEmployeeTerritory] = useState(undefined)
    const [territories, setTerritories] = useState(undefined)
    const [industryYears, setIndustryYears] = useState("")
    const [latestCompletion, setLatestCompletion] = useState("")
    const [company, setCompany] = useState("")
    const [dateRange, setDateRange] = useState("")
    const [adminList, setAdminList] = useState([undefined])
    const [firstTransferManager , setFirstTransferManager] = useState("")
    const [secondTransferManager , setSecondTransferManager] = useState("")
    const [contactStringArray, setContactStringArray] = useState([
        firstName,      
        lastName,     
        email,     
        managerEmail,     
        jobTitle, 
        employeeTerritory,   
        industryYears
    ])
    const [hubId, setHubId] = useState({})
    const [previousPath, setPreviousPath] = useState("")
    const [pathDateRange, setPathDateRange] = useState(undefined)
    const [clientInfoCheck, setClientInfoCheck] = useState(false)
    const [editOnlySwitch, setEditOnlySwitch] = useState(false)
    const [previousClientInfo, setPreviousClientInfo] = useState(undefined)
    const [imageLock, setImageLock] = useState(true)
    const [companies, setCompanies] = useState(undefined)
    const [currentImage, setCurrentImage] = useState(undefined)
    const [imageLoading, setImageLoading] = useState(false)
    const [startLock, setStartLock] = useState(false)
    const [assessmentLock, setAssessmentLock] = useState(false)
    const [gameBoardLock, setGameBoardLock] = useState(false)
    const [currentFrame, setCurrentFrame] = useState(0)
    const [currentCardFrame, setCurrentCardFrame] = useState(0)
    const [forwardLock, setForwardLock] = useState(false)
    const [returnLock, setReturnLock] = useState(undefined)
    const [welcomeForward ,setWelcomeForward] = useState(false)
    const [welcomeStart, setWelcomeStart] = useState(false)
    const [welcomeLoading, setWelcomeLoading] = useState(false)
    const [aboutForward, setAboutForward] = useState(false)
    const [assessmentForward, setAssessmentForward] = useState(false)
    const [gameBoardForward, setGameBoardForward] = useState(false)
    const [helloReturn, setHelloReturn] = useState(false)
    const [clientLogo, setClientLogo] = useState(undefined)
    const [imageEndpoint, setImageEndpoint] = useState(undefined)
    const [formData, setFormData] = useState(undefined)
    const [gameBoardReturnLock, setGameBoardReturnLock] = useState(false)
    const [assessmentReturnLock, setAssessmentReturnLock] = useState(false)
    const [skillPopUp, setSkillPopUp] = useState(false)
    const [imagePopUp, setImagePopUp] = useState(false)
    const [assessmentPopUp, setAssessmentPopUp] = useState(false)
    const [warningPopUp, setWarningPopUp] = useState(false)
    const [warning, setWarning] = useState("")
    const [personnelPopUp, setPersonnelPopUp] = useState(false) 
    const [currentPopUp, setCurrentPopUp] = useState(0)
    const [transferPopUp, setTransferPopUp] = useState(0)
    const [cardOptionsList, setCardOptionsList] = useState([])
    const [modalActive, setModalActive] = useState(false)
    const [choiceSelection, setChoiceSelection] = useState(undefined)
    const [learningPath, setLearningPath] = useState(undefined)
    const [inspectImage, setInspectImage] = useState(false)
    const [fieldComplete, setFieldsComplete] = useState(false)
    const [navLoading, setNavLoading] = useState(false)
    const [promptShow, setPromptShow] = useState(false)
    const [addManagersList, setAddManagersList] = useState([])
    const [firstTransferList, setFirstTransferList] = useState([])
    const [secondTransferList, setSecondTransferList] = useState([])
    const [pseudoTransferList, setPseudoTransferList] = useState([])
    const [phoneNumber, setPhoneNumber] = useState("")
    const [listPhone, setListPhone] = useState(true)
    const [globalEmployeeList, setGlobalEmployeeList] = useState(undefined)
    const [prevPair, setPrevPair] = useState(undefined)
    const [currentAssessment, setCurrentAssessment] = useState(undefined)
    const [currentCafe, setCurrentCafe] = useState(undefined)
    const [expanded, setExpanded] = useState(false)
    const [cafePosition, setCafePosition] = useState(1)
    const [topColor, setTopColor] = useState(undefined)
    const [isLeader, setIsLeader] = useState(false)
    const [cafeSubmitLock, setCafeSubmitLock] = useState(false)
    const [verified, setVerified] = useState(false)
    
    ///this both checks to see if the user has entered an email,
    //handles denial logic (incomplete email / wrong domain based on Company DB object)

    const fullStringFormatter = (str) =>{
        if(typeof str === "string"){
            let modified = str?.trim().toLowerCase().replace(/\s/g, '')
        return modified
        }
    }
    const emailChecker = () =>{
        if(email.includes(" ")){
            alert("no space allowed in email")
            return
        }
        if(!email.includes("@")){
            alert("email must have @")
            return
        }

        
        if(email.slice(0, email.indexOf("@")) === ""){
            alert("address name must not be blank")
            return
        }

        if(!email.slice(email.indexOf("@")).includes(".")){
            alert("email must have . after @")
            return
        }
    }


    const firstEmailAccept = async() =>{
        setWelcomeForward(false)
        setWelcomeLoading(true)

        let companyCheck = true
        
        await getCompany(email)
        .then(async(res) =>{

                if(typeof res.data === "string"){
                    setWelcomeLoading(false)
                    setWelcomeForward(true)
                    setStartLock(false)
                    alert(res.data)
                    companyCheck = false
                    return
                }             
                
            
                if(typeof res.data === "boolean"){
                    setWelcomeLoading(false)
                    setWelcomeForward(true)
                    setStartLock(false)
                    alert("invalid company domain")
                    companyCheck = false
                }

                if(typeof res.data === "object"){
                    getTerritories(res.data._id)
                    .then(res => setTerritories(res.data))
                    .catch(err => alert(err))
                    getLogo(res.data._id)
                    .then(res => setClientLogo(res.data.url))
                    .catch(err => alert(err))
                    getSkillsChallenges(res.data._id)
                    .then(res => setCafeList(res.data))
                    .catch(err => alert(err))
                    setCompany(res.data)   
                }
                     
        })
        .catch(err => {if(err) alert(err)})


        //after company retrieval

        if(companyCheck){
            await searchEmployee(fullStringFormatter(email))
            .then(async(res) => {
    
                if(typeof res.data === "string"){
                    setWelcomeLoading(false)
                    setWelcomeForward(true)
                    alert(res.data)
                }
                //this (might) be the case in which the employee is new, thus no record
                if(res.data === false){
                    setPrevPair({email: email, employee_id: res.data.insertedId})
                    await sendInitial({email: email})
                    .then(res =>{
                        setEmployeeId(res.data.insertedId)
                        setWelcomeLoading(false)
                        getPastAssessments(res.data.insertedId)
                        .then(res => {
                            
                            setPastAssessments(res.data)
                        })
                        .catch(err => alert(err))

                        getLeaderStatus({email: email})
                        .then(res =>{
                            if(res.data === null){
                                return null
                            } else{
                                setIsLeader(true)
                            }
                        })
                        .catch(err => console.log(err))

                        setTimeout(()=>{
                            setCurrentFrame(prev => prev + 1)
                        }, 2000)
                    })
                    }
                else{
                    const placeholder = res.data
                    setInitialEmployeeId(placeholder._id)
                    const result = await checkEmployeeInfo(placeholder._id)
                    if(result.data){

                        let placeholder = result.data    
                        
                        setContact({
                            employee_id: placeholder._id,
                            company_id: placeholder.company_id,
                            email: placeholder.email,
                            firstName: placeholder.firstName,
                            lastName: placeholder.lastName,
                            industryYears: placeholder.industryYears,
                            jobTitle: placeholder.jobTitle,
                            latestCompletion: placeholder.latestCompletion,
                            managerEmail: placeholder.managerEmail,
                            employeeTerritory: placeholder.employeeTerritory,
                            phoneNumber: placeholder.phoneNumber,
                            assessmentImage: placeholder.assessmentImage,
                            verified: placeholder.verified
                        })
                        setPrevPair({email: placeholder.email, employee_id: placeholder._id})
                        setEmail(placeholder.email === null? "" : placeholder.email)
                        setFirstName(placeholder.firstName === null? "" : placeholder.firstName )
                        setLastName(placeholder.firstName === null? "" : placeholder.lastName )
                        setIndustryYears(placeholder.industryYears === null? "" : placeholder.industryYears)
                        setJobTitle(placeholder.jobTitle === null? "" : placeholder.jobTitle)
                        // setLatestCompletion(placeholder.latestCompletion === null? "" : placeholder.latestCompletion)
                        setManagerEmail(placeholder.managerEmail === null? "" : placeholder.managerEmail)
                        setEmployeeTerritory(placeholder.employeeTerritory === null? undefined : placeholder.employeeTerritory)
                        setPhoneNumber(placeholder.phoneNumber === ""? "" : placeholder.phoneNumber)
                        setCurrentImage(placeholder.assessmentImage === null? undefined : placeholder.assessmentImage)
                        setEmployeeId(placeholder._id === null? "" : placeholder._id)
                        placeholder.assessmentImage !== null && setPreviousPath(placeholder.assessmentImage)
                        setVerified(placeholder.verified === null? "" : placeholder.verified)

                        // await getImage(placeholder._id)
                        //     .then(res => {

                        //         if(res.data !== undefined){
                        //             const {url, dateRange} = res.data
                        //             setImageEndpoint(url)                                  
                        //         }

                        //     })
                        //     .catch(err => alert(err))  

                            setWelcomeLoading(false)
                            getPastAssessments(placeholder._id)
                            .then(res => {
                                
                                setPastAssessments(res.data)
                            })
                            .catch(err => alert(err))
                            // setClientLogo(true)

                            //no endpoint exists for getLeaderStatus (due to a git error), but it's no longer required.
                            //if needed, make an endpoint
                            // getLeaderStatus({email: placeholder.email})
                            // .then(res =>{
                            //     if(res.data === null){
                            //         return null
                            //     } else{
                            //         setIsLeader(true)
                            //     }
                            // })
                            // .catch(err => console.log(err))

                            setTimeout(()=>{
                                setCurrentFrame(prev => prev + 1)
                            }, 2000)
                        }
                    }
            })
            .catch(err => alert(err))
        }
        
    }
    const tallyUp = () =>{
        let placeholder = []
        let bp = 0
        let rp = 0
        let gp = 0
        let yp = 0
        let op = 0

        tenArray.forEach(entry =>{
            // let modifier = 1
            if(tenArray.indexOf(entry) < 2){
                // modifier = 3
            }
            switch(entry.learningPath){
                case "blue" :  bp = bp + (entry.value)
                break
                case "red" : rp = rp + (entry.value)
                break
                case "green" : gp = gp + (entry.value) 
                break
                case "yellow" : yp = yp + (entry.value)
                break
                case "orange" : op = op + (entry.value)
                break
                default: return null
            }

        })
        
        // placeholder = 
        // [
        //     {color: "blue", value: bp}, 
        //     {color: "red", value: rp}, 
        //     {color: "green", value: gp}, 
        //     {color: "yellow", value: yp},
        //     {color: "orange", value: op}
        // ]
        placeholder = [bp, rp, gp, yp, op]
        setColorArray(placeholder)
    }



useEffect(()=>{
    if(colorArray.length > 0){
        setFinalResults(determinePathResults(colorArray))
    }
},[colorArray])

const determinePathResults = (pathArray) =>{
    const determineColor = (val) =>{
        let colorString
        switch(pathArray.indexOf(val)){
            case 0 : colorString = "blue"
            break
            case 1 : colorString = "red"
            break
            case 2 : colorString = "green"
            break
            case 3 : colorString = "yellow"
            break
            case 4 : colorString = "orange"
            break
            default: colorString = "blue"
        }
        return colorString
    }

    let optionSet  = {}
    const firstPlace = Math.max(...pathArray)
    const firstColor = determineColor(firstPlace)
    const withoutFirst = pathArray.filter(entry => {
        if(entry !== firstPlace){
            return entry
        }
    })
    const secondPlace = Math.max(...withoutFirst)

    const secondColor = determineColor(secondPlace)
    optionSet = [
        {firstPlace: firstPlace, firstColor: firstColor},
        {secondPlace: secondPlace, secondColor: secondColor},
        {manual: true, colors: ["blue", "red", "green", "yellow", "orange"]}
    ]
    return optionSet
}



    const frameExtras = {
        email: email,
        setEmail: setEmail,
        initialEmployeeId: initialEmployeeId,
        contact: contact,
        setContact : setContact,
        company: company,
        setCompany: setCompany,
        companies: companies,
        cardList: setCardList,
        setCardList: setCardList,
        setCurrentFrame : setCurrentFrame,
        welcomeForward: welcomeForward,
        setWelcomeForward : setWelcomeForward,
        welcomeLoading : welcomeLoading,
        setWelcomeLoading : setWelcomeLoading,
        welcomeStart : welcomeStart,
        setWelcomeStart : setWelcomeStart,
        forwardLock : forwardLock,
        setForwardLock : setForwardLock,
        setReturnLock : setReturnLock,
        aboutForward: aboutForward,
        setAboutForward: setAboutForward,
        assessmentForward: assessmentForward,
        setAssessmentForward: setAssessmentForward,
        previousClientInfo : previousClientInfo, 
        setPreviousClientInfo : setPreviousClientInfo,
        dateRange : dateRange,
        setDateRange : setDateRange,
        helloReturn : helloReturn,
        editOnlySwitch : editOnlySwitch,
        setEditOnlySwitch : setEditOnlySwitch,
        clientInfoCheck : clientInfoCheck,
        setClientInfoCheck : setClientInfoCheck,
        setLatestCompletion: setLatestCompletion,
        firstEmailAccept: firstEmailAccept,
        clientLogo : clientLogo,
        currentImage: currentImage,
        setCurrentImage : setCurrentImage,
        foundContact: foundContact,
        setFoundContact: setFoundContact,
        imageEndpoint: imageEndpoint,
        setImageEndpoint: setImageEndpoint,
        gameBoardForward: gameBoardForward,
        currentCardFrame: currentCardFrame,
        setCurrentCardFrame: setCurrentCardFrame,
        managerEmail : managerEmail,
        fullStringFormatter: fullStringFormatter,
        startLock: startLock,
        setStartLock: setStartLock,
        assessmentLock: assessmentLock,
        setAssessmentLock: setAssessmentLock,
        gameBoardLock: gameBoardLock,
        setGameBoardLock: setGameBoardLock,
        gameBoardReturnLock: gameBoardReturnLock,
        setGameBoardReturnLock: setGameBoardReturnLock,
        assessmentReturnLock: assessmentReturnLock,
        setAssessmentReturnLock: setAssessmentReturnLock,
        modalActive: modalActive,
        setModalActive: setModalActive,
        tallyUp: tallyUp,
        determinePathResults: determinePathResults,
        colorArray: colorArray,
        finalResults: finalResults,
        setFinalResults: setFinalResults,
        navLoading: navLoading,
        setNavLoading: setNavLoading,
        personnelPopUp: personnelPopUp,
        setPersonnelPopUp: setPersonnelPopUp,
        phoneNumber: phoneNumber,
        setPhoneNumber: setPhoneNumber,
        prevPair: prevPair,
        setPrevPair: setPrevPair,
        warningPopUp: warningPopUp,
        setWarningPopUp: setWarningPopUp,
        warning: warning,
        setWarning : setWarning,
        assessmentPopUp: assessmentPopUp,
        setAssessmentPopUp: setAssessmentPopUp,
    }



    const frameElements = frameData(frameExtras)
    const alteredFrames = frameData(frameExtras, "alteredFrames")
    const editOnlyFrames = frameData(frameExtras, "editOnlyFrames")
  

    ////////// USE EFFECT

    //#1stUseEffect:
    //loads cards & company data from Mongo, also set's welcome frame animations
    useEffect(()=>{
        getMainList()
        .then(res => setGlobalEmployeeList(res.data))
        .catch(err => console.log(err))
        getDate()
        .then(res => {
            const result = res.data
            setPathDateRange({startDate: new Date(result.startDate).toLocaleString("en-US").slice(0, 10), endDate: new Date(result.endDate).toLocaleString("en-US").slice(0, 10)})
        })
        .catch(err => alert(err))
        setWelcomeStart(true)
      
    },[])
    
    //#useEffect1.5
    //checks to see if there are any previous assessments via the array's length
    useEffect(()=>{
        if(pastAssessments.length > 0){
            setIsPastAssessmentVisible(true)
            return{}
        }else{
            setIsPastAssessmentVisible(false)
            return{}
        }
    },[pastAssessments])
    
    //#2ndUseEffect:
    //This makes turns off and on welcomeForward the welcomeFrame's Move component 
    //to appear and dissapear if the user leaves the email input blank.
    useEffect(()=>{
        email === "" ? setWelcomeForward(false)
        : setWelcomeForward(true)

        return null
    }, [email])


    //#3rdUseEffect:
    //retrieves cards after the company useState has been properly set by firstEmail
    useEffect(()=>{
    
        company !== "" &&
        getCards(setCardList, {company: company._id})

        // imageEndpoint !== undefined ? 
        //     setGameBoardForward(true) :
        //     setGameBoardForward(false)

    },[company])

    //4thUseEffect:
    // Logic for determining if the "about you" fields have all been filled out 
    //and unlock the move component to advance into "assessment" frame
    useEffect(()=>{
        if(!listPhone){
            setAboutForward(false)
            setHelloReturn(false)
            const checkArray = [
                firstName, 
                lastName, 
                email, 
                managerEmail, 
                // jobTitle, 
                employeeTerritory, 
                industryYears,
                verified
            ]


            const results = checkArray.filter(entry => {
                if(entry !== ""){
                    return entry
                }
            })

            if(results.length>5){
                setContact({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    managerEmail: managerEmail, 
                    // jobTitle: jobTitle,
                    employeeTerritory: employeeTerritory,
                    industryYears: industryYears,
                    company_id: company._id,
                    phoneNumber: phoneNumber,
                    verified: verified
                })
                setAboutForward(true)
                setHelloReturn(true)
            }
        
        }
        if(listPhone){
            setAboutForward(false)
            setHelloReturn(false)
            const checkArray = [
                firstName, 
                lastName, 
                email, 
                managerEmail, 
                // jobTitle, 
                employeeTerritory, 
                industryYears,
                phoneNumber,
                verified
            ]


            const results = checkArray.filter(entry => {
                if(checkArray.indexOf(entry) === 6){
         
                        return entry
                    
                }
                else{
                    if(entry !== ""){
                        return entry
                    }
                }
            })

            if(results.length>6){
                setContact({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    managerEmail: managerEmail, 
                    // jobTitle: jobTitle,
                    employeeTerritory: employeeTerritory,
                    industryYears: industryYears,
                    company_id: company._id,
                    phoneNumber: phoneNumber,
                    verified: verified
                })
                setAboutForward(true)
                setHelloReturn(true)
            }
        
        }
    }, [firstName, lastName, email, managerEmail, jobTitle, employeeTerritory, industryYears, latestCompletion, foundContact,
        phoneNumber, listPhone, company._id, verified// jobTitle,
    ])


    //#5thUseEffect
    useEffect(()=>{
        if(!listPhone){
            setPhoneNumber("")
        }
    },[listPhone])
   
    
    //#6thUseEffect
    useEffect(()=>{
        let BreakException = {}
        try{
            tenArray.forEach(entry =>{
                if(entry.skill==="click here!"){
                    setAssessmentForward(false)
                    throw BreakException
                }
                setAssessmentForward(true)
            })
        }
        catch(e){
            if(e !== BreakException) throw e;
        }
    
        return () =>{
            return null
        }
    },[cardList])



    //The assessment select elements (In Assessmment -> AssessSelectPair (components))
    //will target cardList first to generate options, once the first selection is
    // made, they should target the pseudo list to display options, and it will
    //always be in this useEffect listening for when the option containing a particular
    //skill is selected (which the card's selected value will turn true in the real card list)
    //if it's true, the pseudoList will filter out and only return cards with false valued
    //"selected" properties

    //7thUseEffect
    useEffect(()=>{
        setPseudoCardList(
           cardList.length> 0 && cardList.filter(entry =>{
                    if(!entry.selected){
                        return entry
                    }
                }             
            )
        )
    },[cardList])

    //#8thUseEffect
    // useEffect(()=>{
    //     setGameBoardForward(false)
    //     if(imageEndpoint !== undefined){
    //         setGameBoardForward(true)
    //     }
    // },[imageEndpoint])


    //9thUseEffect
    //watches the cafeList (first populated during intializtion)
    //modified by the CafeSubmission component, specifically the 
    //CafeOptionGroup component found within
    useEffect(()=>{
        if(cafeList.length > 0){
            setFinalCafes(cafeList.filter(entry=>{
                if(entry.selected === true){
                    return entry
                }
                else return null
            }))
        }
    }, [cafeList])

    //10thUseEffect
    //Watches final results, specifically for the first element in the array
    //which is an object that houses the first place score and color
    //and sets the topColor 
    useEffect(()=>{
        if(finalResults !== undefined){
            if(finalResults[0].firstColor !== 'yellow'){
                setTopColor(finalResults[0].firstColor)
            }else{
                setTopColor(finalResults[1].secondColor)
            }
        }
        
    },[finalResults])

    //11thUseEffect
    useEffect(()=>{
        if(isLeader){
            setCafeSubmitLock(true)
            finalCafes.length === 5 && finalCafes.forEach(entry => {
                if(entry.leaderMandatory){
                    setCafeSubmitLock(false)
                }
            })
        
        }
        return null
    }, [finalCafes, isLeader])
    // useEffect(()=>{
    //     if(isLeader){
            
    //         if(finalCafes.length >= 2){
    //             setCafeSubmitLock(true)
    //             finalCafes.forEach(entry => {
    //                 if(entry.leaderMandatory){
    //                     setCafeSubmitLock(false)
    //                 }
    //             })
    //         }
            
        
    //     }
    //     return null
    // }, [finalCafes, isLeader])

    useEffect(()=>{
        if(contact !== null){
            setFieldsComplete(true)
            for (const property in contact) {
                if(property === ""){
                    setFieldsComplete(false)
                }
              }
        }
        
    }, [contact])

    useEffect(()=>{
        setPseudoTransferList(firstTransferList)
    },[firstTransferList])
    
    ////////// FUNCTIONS

    const retrieveDates = async()=>{
        await getDate()
        .then(res => setDateRange( res.data.enrollmentPeriods.filter(entry =>{
            const endDate = new Date(entry.endDate)
            const today = new Date()
            if(endDate > today){
                return entry
            }
        })[0]))
        .catch(err => alert(err))
    }

    
    ////////// RETURN STATEMENT


    return(
        <MenuContext.Provider value={{
            company,
            tenArray, 
            setTenArray, 
            cardList, 
            setCardList,
            resultsObj,
            setResultsObj,
            cartPanelActive, 
            setCartPanelActive, 
            current, 
            setCurrent, 
            orderList, 
            setOrderList,
            storageCheck,
            setStorageCheck,
            resultsActive,
            setResultsActive,
            tallyUp,
            determinePathResults,
            colorArray,
            contact,
            setContact,
            hubId,
            setHubId,
            previousPath,
            setPreviousPath,
            imageLock,
            setImageLock,
            currentFrame,
            setCurrentFrame,
            frameElements,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            email,
            setEmail,
            managerEmail,
            setManagerEmail,
            jobTitle,
            setJobTitle,
            territories,
            setTerritories,
            employeeTerritory,
            setEmployeeTerritory,
            industryYears,
            setIndustryYears,
            clientInfoCheck,
            setPreviousClientInfo,
            setClientInfoCheck,
            alteredFrames,
            dateRange,
            setDateRange,
            editOnlyFrames,
            editOnlySwitch,
            setEditOnlySwitch,
            pseudoCardList,
            setPseudoCardList,
            currentImage,
            setCurrentImage,
            foundContact,
            setFoundContact,
            formData,
            setFormData,
            imageEndpoint,
            setImageEndpoint,
            assessmentForward,
            currentCardFrame,
            setCurrentCardFrame,
            imageLoading,
            setImageLoading,
            fullStringFormatter,
            emailChecker,
            skillPopUp,
            setSkillPopUp,
            imagePopUp,
            setImagePopUp,
            popUpList,
            currentPopUp,
            setCurrentPopUp,
            cardOptionsList,
            setCardOptionsList,
            modalActive,
            setModalActive,
            finalResults,
            setFinalResults,
            choiceSelection,
            setChoiceSelection,
            learningPath,
            setLearningPath,
            employeeId,
            inspectImage,
            setInspectImage,
            pathDateRange,
            setPathDateRange,
            clientLogo,
            setClientLogo,
            navLoading,
            setNavLoading,
            promptShow,
            setPromptShow,
            admin,
            setAdmin,
            personnelPopUp,
            setPersonnelPopUp,
            addManagersList,
            setAddManagersList,
            transferPopUp,
            setTransferPopUp,
            adminList,
            setAdminList,
            firstTransferManager,
            setFirstTransferManager,
            secondTransferManager,
            setSecondTransferManager,
            firstTransferList,
            setFirstTransferList,
            secondTransferList,
            setSecondTransferList,
            pseudoTransferList,
            setPseudoTransferList,
            listPhone,
            setListPhone,
            phoneNumber,
            setPhoneNumber,
            globalEmployeeList,
            setGlobalEmployeeList,
            pastAssessments,
            setPastAssessments,
            isPastAssessmentVisible,
            setIsPastAssessmentVisible,
            assessmentPopUp,
            setAssessmentPopUp,
            warningPopUp,
            setWarningPopUp,
            currentAssessment,
            setCurrentAssessment,
            expanded,
            setExpanded,
            cafeList,
            setCafeList,
            finalCafes,
            setFinalCafes,
            cafePosition,
            setCafePosition,
            currentCafe,
            setCurrentCafe,
            topColor,
            setTopColor,
            isLeader,
            setIsLeader,
            cafeSubmitLock,
            setCafeSubmitLock,
            warning,
            setWarning,
            verified,
            setVerified
            }}
        >
            {children}
        </MenuContext.Provider>
    )
}

export {MenuContext, MenuContextProvider}