import { useContext } from 'react'
import {PopUpContainer} from './popUpStyles'
import { MenuContext } from '../../utils/menuContext'
import CardOptions from '../CardOptions/CardOptions'
import SkillModal from '../SkillModal/SkillModal'
import ImageModal from '../ImageModal/ImageModal'
import PersonnelModal from '../PersonnelModal/PersonnelModal'
import TransferModal from '../PersonnelModal/TransferModal'
import PreviousAssessmentModal from '../PreviousAssessmentModal/PreviousAssessmentModal'
import WarningModal from '../WarningModal/WarningModal'

const PopUp = () =>{

    return(
        <PopUpContainer>
            <SkillModal />
            <ImageModal />
            <PersonnelModal />
            <TransferModal />
            <PreviousAssessmentModal/>
            <WarningModal />
        </PopUpContainer>
    )
}


export default PopUp