import { useState, useContext } from "react"
import { 
    InfoEditBody, 
    InfoEditCard, 
    InfoEditScreenContainer, 
    ContactInfoTitle, 
    PreviousContact, 
    CurrentInfoTitle,
    InfoChangeMesage,
    InfoEditButton,
    FormHolder,
    PreviousTitle,
    PreviousContactRow,
    FormSection
} from "./infoEditStyles"
import Move from "../Move/Move"
import AboutForm from "../Content/children/AboutContainer/AboutForm"
import { replaceCompletedEmployeeInfo } from "../../services/dataServices"
import { MenuContext } from "../../utils/menuContext"
import { useNavigate } from "react-router-dom"

const InfoEditScreen = (
    {accentColor, 
     bottomColor, 
     previousClientInfo, 
     helloReturn,
     standard
    }) =>{
    const [previousExpand, setPreviousExpand] = useState(true)
    const [currentExpand, setCurrentExpand] = useState(false)
    const {contact, setEditOnlySwitch, setCurrentFrame, setClientInfoCheck} = useContext(MenuContext)
    const redirect = useNavigate()
    let testArray
    const obj1={
        name: "Bart",
        age: 10,
        hobby: "skateboarding"
    }
    
    


    const submitAndClose = async() =>{
        const result = await replaceCompletedEmployeeInfo({...contact, oldEmail: previousClientInfo.email})

        if(result){
            setClientInfoCheck(false)
            setEditOnlySwitch(true)
            setCurrentFrame(prev => prev + 1)
        }
    }

    const determinePrefix = (index) =>{
        switch(index){
            case(index === 1):
                return "last name"
            break
            case(index === 2):
                return "email"
            break
            case(index === 3):
                return "manager's"
            break
            case(index === 4):
                return "territory code"
            break
            case(index === 5):
                return "years in industry"
            break
            default: return "first name"
        }
    }

    return(
        <InfoEditScreenContainer accentColor={accentColor} >
            {/* <InfoChangeMesage>want to check or change your contact information?</InfoChangeMesage>
            <InfoChangeMesage >just expand the card below</InfoChangeMesage> */}
            {/* <ContactInfoTitle accentColor={accentColor}>
                <h3>contact information</h3>
            </ContactInfoTitle> */}
            <InfoEditCard accentColor={accentColor} standard={standard}>
                        
                
                <InfoEditBody>
                        <PreviousTitle 
                            accentColor={accentColor}
                            bottomColor = {bottomColor}
                            standard = {standard}
                        >
                            <p>{standard? `previous session` : `contact updated successfully!`}</p>
                        </PreviousTitle>
                        <PreviousContact accentColor={accentColor} expanded={previousExpand}>
                            {
                                standard?

                                <>
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>first name: </p>
                                    <p>{previousClientInfo && previousClientInfo.firstName}</p>
                                </PreviousContactRow>                
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>last name: </p>
                                    <p>{previousClientInfo && previousClientInfo.lastName}</p>
                                </PreviousContactRow>
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>email:</p>
                                    <p>{previousClientInfo && previousClientInfo.email}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>manager:</p>
                                    <p> {previousClientInfo && previousClientInfo.managerEmail}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>territory code:</p><p> {previousClientInfo && previousClientInfo.territoryCode}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>job title:</p><p> {previousClientInfo && previousClientInfo.jobTitle}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>years in industry:</p><p> {previousClientInfo && previousClientInfo.industryYears}</p>
                                </PreviousContactRow>
                                </>

                                :

                                <>
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>first name: </p>
                                    <p>{contact && contact.firstName}</p>
                                </PreviousContactRow>                
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>last name: </p>
                                    <p>{contact && contact.lastName}</p>
                                </PreviousContactRow>
                                <PreviousContactRow accentColor={accentColor}>
                                    <p>email:</p>
                                    <p>{contact && contact.email}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>manager:</p>
                                    <p> {contact && contact.managerEmail}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>territory code:</p><p> {contact && contact.territoryCode}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>job title:</p><p> {contact && contact.jobTitle}</p>
                                </PreviousContactRow>
                                <PreviousContactRow>
                                    <p>years in industry:</p><p> {contact && contact.industryYears}</p>
                                </PreviousContactRow>
                            </>

                            }
                            
                        </PreviousContact>
                        <FormSection standard={standard}>
                            <CurrentInfoTitle 
                                expanded={currentExpand} 
                                accentColor={accentColor}
                                bottomColor = {bottomColor}
                            >
                                <p>current session</p>
                                {/* <i className="fa-solid fa-angle-down" onClick={()=>{setCurrentExpand(prev => !prev)}}></i> */}
                        </CurrentInfoTitle>
                            <FormHolder>
                                <AboutForm standard={true}/>
                            </FormHolder>
                        </FormSection>
                </InfoEditBody>
            </InfoEditCard >
            {/* <InfoEditButton 
                vis={helloReturn} 
                accentColor={accentColor}
                bottomColor={bottomColor}
                onClick={()=>{submitAndClose()}}
            >{`submit current entries`}</InfoEditButton> */}
            {
                standard && 

                <Move utils={
                    {
                     vis: helloReturn,
                     ai: "baseline",
                     mt: 0,
                     iFs: 0.8,
                     mr: 0.25,
                     aTime: 1,
                     prompt: "submit current contact information",
                     action: submitAndClose,
                     symbol: <i className="fa-solid fa-chevron-right" onClick={()=>{submitAndClose()}}></i>,
                     color: "white",
                     order: "row"
                    }
                 } />
            }
        </InfoEditScreenContainer>
    )
}


export default InfoEditScreen