import { useState, useContext} from "react"
import { useNavigate } from "react-router-dom"
import { MenuContext } from "../../utils/menuContext"
import { MainContainerMobile } from "./mainStyles"
import Carousel from '../../components/Carousel/Carousel'
import PopUp from "../../components/PopUp/PopUp"

import MobileAway from "../MobileAway/MobileAway"
import DesktopAway from "../DesktopAway/DesktopAway"
import WelcomePage from "../WelcomePage/WelcomePage"

export const MainPage = () =>{

    return(
        <>
            <MainContainerMobile > 
                <Carousel />
                <PopUp />
            </MainContainerMobile>
            {/* <WelcomePage /> */}
            {/* <MobileAway/>
            <DesktopAway/> */}
        </>
        
    )
}

export default MainPage