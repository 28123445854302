import styled from 'styled-components'

export const MobileAwayContainer = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    // this is for the desktop disclaimer to be centered;
    @media (min-width: 1000px){
       display:none;
    }
   
`

export const MobileAwayDisclaimer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 80%;
    min-height: 45vh;
    padding: 1rem;
    *{
        text-align: center;
    }
    h2{
        font-size:  1.1rem;
        margin-bottom: 1rem;
    }
    p{
        font-size: 0.9rem;
        
    }

    @media (min-width: 500px){
        h2{
            font-size: 1.5rem;
        }

        p{
            font-size: 1.2rem;
        }
     }
`

