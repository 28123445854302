import {useState, useContext, useEffect} from 'react'
import { MenuContext } from '../../utils/menuContext'
import { EmployeeRow, EmployeeSearchContainer } from './personnelModalStyles'


const EmployeeSearchList = ({value, list, preset}) =>{
    const {
        setPseudoTransferList
    } = useContext(MenuContext)
    
        const modifiedList = list.map(entry=>{
            return(
                {...entry, selected: false}
            )
        })
  
        const filteredList = list.filter(entry => entry.email?.toString().toLowerCase().startsWith(value.toLowerCase()))
    
        if(modifiedList.length){

                return(
  
                    (
                        <EmployeeSearchContainer
                        >
                            {
                                modifiedList.map(entry =>{
                  
                                        return <EmployeeRow 
                                                    key={entry._id} 
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        onChange={(e)=>{
                                                            setPseudoTransferList(prev => prev.map(entry =>{
                                                                if(entry.email === e.target.value){
                                                                    return {...entry, selected: !entry.selected}
                                                                }
                                                                else{
                                                                    return entry
                                                                }
                                                            }))
                                                        }}
                                                        value={entry.email}
                                                    />
                                                    <p>{entry.email}</p>
                                                    
                                            </EmployeeRow>
                                    }
                                )
                            }
                        </EmployeeSearchContainer>
                    )   
                )
        
        }

        return(
            <div>
                <p>
                    Not found
                </p>
            </div>
        )
    }

    

    



export default EmployeeSearchList