import styled, {css} from 'styled-components'


export const ScrollableContainer = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 85vw;
    margin: 0 auto;
    height: 35vh;
    padding: 1rem;
    /* border: 0.5px solid ${props => props.accentColor? props.accentColor : "black"}; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

