import {Logo,Instructions} from '../styles/assessStyles'
import {ContactHeader} from '../styles/contactInfoStyles'
import {
    CompetencyContainer,
    OverContainer
} from '../styles/cardPageStyles'
import {useContext, useEffect, useState} from 'react'
import {MenuContext} from '../utils/menuContext'
import {Link, useNavigate} from 'react-router-dom'
import CardSet from '../components/CardSet'
import ResultModal from '../components/ResultModal'
import CompetencyBoard from '../components/CompetencyBoard'
import ImageConComponent from '../components/ImageConComponent'
import {
    // getDBContact, 
    removeImage} from '../services/dataServices'
// import CardOptions from '../components/CardOptions'
import ImgInput from '../components/ImgInput'

function CardPage(){
    const {
        contact,
        setPreviousPath, 
        setCardList, 
        tenArray, 
        setTenArray, 
        resultsActive, 
        setResultsActive, 
        setContact
    } = useContext(MenuContext)
    const [cardCheck, setCardCheck] = useState(false)
    const [loadCard, setLoadCard] = useState(false)
    const [animateCard, setAnimateCard] = useState(false)
    const [prevPicture, setPrevPicture] = useState(null)
    const [buttonImageLock, setButtonImageLock] = useState(true)
    const [formData, setFormData] = useState(null)
    const [modalLock, setModalLock] = useState(true)
    const redirect = useNavigate()
    
    useEffect(()=>{
        if(!localStorage.getItem("contact")){
            redirect('/')

        }
        
        if(localStorage.getItem("contact")){
            setContact(JSON.parse(localStorage.getItem("contact")))
            if(JSON.parse(localStorage.getItem("contact")).hasOwnProperty("assessmentImage")){
                setPreviousPath(JSON.parse(localStorage.getItem("contact")).assessmentImage)
            }
        }
        
        if(localStorage.getItem("list")){
            setTenArray(JSON.parse(localStorage.getItem("list")).array)
            setCardList(JSON.parse(localStorage.getItem("list")).cards)
        }

        setResultsActive(false)
        localStorage.setItem("fade", true)
        return()=>{
            return null
        }
    },[])

    // useEffect(()=>{
    //     if(contact !== null){
    //          getDBContact(contact)
    //         .then(res => {
    //             setPrevPicture(res.assessmentImage)
    //             setContact({...contact, assessmentImage: res.assessmentImage})
    //         })
    //         .catch(err => alert(err))
    //     }
    // },[])

    


    //The fade useState variable isn't necessary for the scroll-fade effect, what's needed is just 
    //the setFade function, as it makes state track the real-time scroll. Without the setState, the
    //ScrollY number would just be the static 0 from when the component mounts and won't actually change.

    // useEffect(()=>{
      
    //     if(fader===true){
         
    //             document.addEventListener("scroll", ()=>{
    //                 setFade(document.querySelector("button.button-class").getBoundingClientRect().bottom)
    //             })
                
            
    
    //         return()=>{
    //             document.removeEventListener("scroll", ()=>{
    //                 setFade(document.querySelector("button.button-class").getBoundingClientRect().bottom)
    //             })
    //         }
    //     }
    // }, [fade])

    useEffect(()=>{
        let BreakException = {}
        try{
            tenArray.forEach(entry =>{
                if(entry.skill===""){
                    setCardCheck(false)
                    throw BreakException
                }
                setCardCheck(true)
            })
        }
        catch(e){
            if(e !== BreakException) throw e;
        }

        return () =>{
            return null
        }
    },[tenArray])

    return (
        <OverContainer >
            
            
            <CompetencyContainer >
                
                {/* <ContactHeader>
                    <Logo />
                    <h1>Competency Cards</h1>
                </ContactHeader>

                <Instructions style={{fontWeight: "400", textAlign: "center"}}>Verify all information before submitting</Instructions>
            
                <ImageConComponent  sFormData_={setFormData}  sButtonImageLock_={setButtonImageLock}
                    btn_lock={buttonImageLock}
                />

                <Instructions>Review card placement for final confirmation. Tap on the card skill title to change selection.
                    Use side arrows to adjust development. Scroll down and "confirm" to generate your learning path.
                </Instructions> */}
                <CompetencyBoard setLCard={setLoadCard} setACard={setAnimateCard} setLock={setModalLock}/>
                <ResultModal 
                    res={resultsActive} 
                    l_card={loadCard} 
                    anim={animateCard}
                    set_res={setResultsActive}
                    btn_lock={modalLock}
                />
            </CompetencyContainer>
        </OverContainer>

    )
}

export default CardPage