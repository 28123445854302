import { BodyContainer } from "./bodyStyles"




const Body = ({children, utils}) =>{
    const {
        display,
        fDirection,
        jc,
        mHeight,
        bg
    } = utils || {}
    return(
        <BodyContainer
            display={display}
            fDirection = {fDirection}
            jc={jc}
            mHeight={mHeight}
            bg={bg}
        >
            {children}
        </BodyContainer>
    )
}

export default Body